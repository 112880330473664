import React, { PropTypes } from 'react';
import { Link } from 'react-router';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';


const styles = {
  container: {
    textAlign: 'center',
    paddingTop: 200,
  },
  button: {
    margin: 12
  },
  errorMessage: {
    color:'red'
  }
};


export default class RegisterConfirmation extends React.Component {

  constructor(props) {
    super(props);
    
    // Bind methods to this class so "this" works as expected
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleOrgNameChange = this.handleOrgNameChange.bind(this);
  }
  
  /**
   * Event Handlers
   */
  handleEmailChange(e) {
    this.setState({email: e.target.value});  
  }
  
  handlePasswordChange(e) {
    this.setState({password: e.target.value});
  }

  handleOrgNameChange(e) {      
    this.setState({orgName: e.target.value});
  }  
  
  render() {
    let reference = (this.props.newOrgReference && this.props.newOrgReference.hasOwnProperty('organization')) ? this.props.newOrgReference : null; 
    let orgName = (reference) ? reference.organization.profile.name : 'Unknown Organization'; 
    let email = (reference) ? reference.email : 'Unknown Email';
    return (
        <section>
            <div className="container">

                <div className="row">
                    <div className="col-sm-12">                        
                        <div className="wrapper-page">
                            <div className="m-t-40">                        
                                <div className="col-xs-12">
                                        <h4 className="header-title">Sign Up</h4>
                                        <div className="col-sm-12">                        
                                            <div className="alert alert-success alert-dismissible fade in" role="alert">
                                                <strong>Nice work!</strong> <br />
                                                Your Organization, <strong>{orgName}</strong>, has been created. <br /><br />                                                
                                                <Link to="login"><strong>Login with your email ({email}) to get Started</strong></Link> 
                                            </div>    
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
    );
  }
}

RegisterConfirmation.propTypes = {
    newOrgReference: PropTypes.object
}