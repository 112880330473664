import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

/**
 * A modal dialog can only be closed by selecting one of the actions.
 */

const style = {
    width:'90%'
}

export default class AddUserModal extends React.Component {
 
  constructor() {
      super();
      this.state = {
          email: '', 
          password: '',
          userRole: 1,
          emailError: null, 
          passwordError: null
      }
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handlePasswordChange = this.handlePasswordChange.bind(this);
      this.handleRoleChange = this.handleRoleChange.bind(this);
      this.handleAddUser = this.handleAddUser.bind(this);
      this.handleOkClick = this.handleOkClick.bind(this);
  }


  handleEmailChange(e) {
      this.setState({email: e.target.value});      
  }

  handlePasswordChange(e) {
     this.setState({password: e.target.value});
  }

  handleRoleChange(e,i,v) {
      this.setState({userRole: v});
  }

  handleOkClick() {
      this.setState({
          email: '', 
          password: '',
          userRole: 1,
          emailError: null, 
          passwordError: null          
      });
      this.props.handleCancel();
  }

  handleAddUser() {
      //Validate Fields
      var email = this.state.email;
      var password = this.state.password;
      var error = false;
      if(email.length == 0) {
          this.setState({emailError:'Email address required'});
          error = true;
      }
      if(password.length == 0) {
          this.setState({passwordError:'Password must be at least 1 character'});
          error = true;
      }
      if(!error) {
          //Send Request
          this.props.handleAddUser(email, password, this.state.userRole);
      }
  }

  get serverError() {
      if(this.props.addingUserError) {
        return(
            <div className="row">
                <div className="col-sm-12">
                    <div className="alert alert-danger alert-white">
                        {this.props.addingUserError}
                    </div>
                </div>
            </div>
        );  
      }
      return null;
  }

  get serverResult() {
      if(this.props.addingUserResult) {
        var result = this.props.addingUserResult;         
        var message = result.message;
        return(
            <div className="row">
                <div className="col-sm-12">
                    <div className="alert alert-success alert-white">
                        {message}
                    </div>
                </div>
            </div>
        );  
      }
      return null;
  }

  render() {
    var actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onTouchTap={this.props.handleCancel}
        disabled={this.props.disabled}
      />,
      <FlatButton
        label="Add User"
        primary={true}
        disabled={false}
        onTouchTap={this.handleAddUser}
        disabled={this.props.disabled}
      />,
    ];

    // Unless we have added a user successfully
    if(this.props.addingUserResult) {
        actions = [
        <FlatButton
            label="Ok"
            primary={true}
            onTouchTap={this.handleOkClick}
            disabled={false}
        />,            
        ]
    }

    return (      
        <Dialog
          title="Add User"
          actions={actions}
          modal={true}
          open={this.props.open}
        >
        
          <div className="row">
            <div className="col-sm-4">    
                <TextField 
                    style={style}
                    label="Email"
                    value={this.state.email}
                    hintText="Email Address"           
                    floatingLabelText="Email"
                    disabled={this.props.disabled}
                    errorText={this.state.emailError}
                    onChange={this.handleEmailChange}
                />
            </div>
            <div className="col-sm-4">
                <TextField
                    style={style}
                    label="Password"
                    value={this.state.password}
                    disabled={this.props.disabled}
                    hintText="Password Field"
                    floatingLabelText="Password"
                    type="password"
                    errorText={this.state.passwordError}
                    onChange={this.handlePasswordChange}
                />
            </div>
            <div className="col-sm-4">
                <SelectField
                    style={style}
                    floatingLabelText="User Type"
                    value={this.state.userRole}
                    disabled={this.props.disabled}
                    onChange={this.handleRoleChange}
                >
                    <MenuItem value={1} primaryText="Organization Admin" />
                    <MenuItem value={2} primaryText="Basic User" />
                </SelectField>                
            </div>            
        </div>
            {this.serverError}
            {this.serverResult}
        </Dialog>
    );
  }
}
AddUserModal.propTypes = {
    handleAddUser: PropTypes.func, 
    handleCancel: PropTypes.func,
    addingUserError: PropTypes.string,
    handleCancel: PropTypes.func,
    open:PropTypes.bool,
    disabled:PropTypes.bool,
    addingUserResult: PropTypes.object
}