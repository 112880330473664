import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import { hashHistory } from 'react-router';

import EventConfigList from '../components/eventAdmin/eventConfigList';
import CreateEventContainer from './CreateEventContainer';

export default class EventTemplateListContainer extends React.Component {
  constructor() {
    super();
    this.handleSelectItemClick = this.handleSelectItemClick.bind(this);
    this.handleCreateItem = this.handleCreateItem.bind(this);
  }
  
  componentDidMount() {
    var orgId = this.props.organizationId;
    //this.props.actions.refreshEventTemplates(orgId);
  }

  handleSelectItemClick(eventConfigId) {
    var orgId = this.props.organizationId; 
    hashHistory.push(`/template/${eventConfigId}`);
    this.props.actions.selectEventTemplate(this.props.organizationId,eventConfigId);
  }

  handleCreateItem() {
    hashHistory.push(`/template`);
    this.props.actions.createEventTemplate(this.props.organizationId);
  }
  
  get eventConfigList() {
    return (
       <EventConfigList 
            list={this.props.templates} 
            onItemClick={this.handleSelectItemClick}
            onAddEventClick={this.handleCreateItem}
        />
    );
  }

  render() {
    var renderNode = this.eventConfigList;
      return renderNode;
  }
}

function mapStateToProps(state,ownProps) {
  return {
    templates: state.eventtemplate.templates,
    selectedTemplate: state.eventtemplate.selectedTemplate,
    organizationId: state.auth.currentOrganization,
    params: ownProps.params
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(EventTemplateListContainer));