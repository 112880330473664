import React, { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import Chip from 'material-ui/Chip';
import {grey200, green100} from 'material-ui/styles/colors';

import moment from 'moment';

const tableStyle = {
  width: '100%',
  cellPadding:'4px'
};

/**
 * ActiveEventUsers 
 * Shows all users who have connected to an event in the IOS app
 */
export default class ActiveEventUsers extends React.Component {
  constructor(props) {
    super(props);
  }


  get userList() {
    
    // Sort this list
    var dataItems = [];
    for(var userKey in this.props.userList) {         
        dataItems.push(this.props.userList[userKey]);
    }

    dataItems.sort(function(a, b){
        return moment(a.connectionTime) < moment(b.connectionTime);
    });


    var rowItems = [];    
    for(var data of dataItems) {         
        var uniqueKey = `${data.deviceId}-${data.userId}`;
        var formatDate = moment(data.connectionTime).format("MMM Do h:mm a");        
        var lastSeen = (data.hasOwnProperty('lastOnline')) ? '(' + moment(data.lastOnline).fromNow() + ')' : '';                
        var formatLastSeenDate = moment(data.lastOnline).format("MMM Do h:mm a");
        var displayLastSeen = (data.isOnline === true) ? '' :  formatLastSeenDate + ' ' + lastSeen;
        var bgColor = (data.isOnline === true) ? green100 : null ;
        var rowItem = <TableRow key={uniqueKey} style={{backgroundColor: bgColor}} >
              <TableRowColumn>{data.email}</TableRowColumn>              
              <TableRowColumn style={{textAlign:'right',width:'150px'}}> {data.deviceName} </TableRowColumn>                            
              <TableRowColumn style={{textAlign:'right',width:'150px'}}> {formatDate} </TableRowColumn>              
              <TableRowColumn> {displayLastSeen} </TableRowColumn>
            </TableRow>;
        rowItems.push(rowItem);            
    }


    var userList =            
              <div className="row">
              <Table 
                style={tableStyle}
                fixedHeader={true}
                multiSelectable={false}  
                selectable={false}
              >
                  <TableHeader
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}                      
                  >
                    <TableRow>                      
                      <TableHeaderColumn>Email</TableHeaderColumn>
                      <TableHeaderColumn style={{textAlign:'right',width:'150px'}}>Device</TableHeaderColumn>                      
                      <TableHeaderColumn style={{textAlign:'right',width:'150px'}}>Connection Time</TableHeaderColumn>                                    
                      <TableHeaderColumn>Last Online</TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody 
                      displayRowCheckbox={false}
                      selectable={false}
                      deselectOnClickaway={false}
                  >
                    {rowItems}
                  </TableBody>
              </Table>
              </div>;
    return userList;
  }


  renderChip(data) {
    var color = (data.isOnline === true) ? green100 : grey200;
    var uniqueKey = `${data.deviceId}-${data.userId}`;
    return (
      <Chip
        backgroundColor={color}
        key={uniqueKey}
        style={{margin:4}}
      >
        {data.email}
      </Chip>
    );
  }

  render() {
      
      return this.userList;         
      /*
      var chips = [];
      for(var userKey in this.props.userList) {
          chips.push(this.props.userList[userKey]);
      }
      var message = (chips.length == 0) ? <div>No users have logged in to this event</div> : null;
      return(
        <div className="col-sm-12">
            {message}
            <div style={{display:'flex', flexWrap:'wrap'}}>
                {chips.map(this.renderChip, this)}
            </div>            
        </div>
      );
      */
  } 
}

ActiveEventUsers.propTypes = {
    userList: PropTypes.object
}