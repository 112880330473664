import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import RegisterForm from '../components/RegisterForm';
import RegisterConfirmation from '../components/RegisterConfirmation';

class RegisterContainer extends React.Component {

  constructor() {
    super();
    // Bind methods to this class so "this" works as expected
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentWillReceiveProps(nextProps)  {
    console.log('Register Props: ', nextProps);
    if(nextProps.hasOwnProperty('userManagement')) {
      // Should check a state on user auth      
      if(nextProps.userManagement.newOrgReference !== null) {
        // Jump to Login 
        console.log('We have a new Org');
        //this.props.router.push('/login');
      }
    }
  }

  handleSubmit(e) {  
      var email = this.refs.registerForm.state.email;
      var pass = this.refs.registerForm.state.password;
      var orgName = this.refs.registerForm.state.orgName;
      this.props.actions.signUpUser(email,pass,orgName);
  };

  render() {
    //Render form OR Success Page
    if(this.props.userManagement.newOrgReference) {
      return <RegisterConfirmation newOrgReference={this.props.userManagement.newOrgReference}/> 
    } else {
      return (
        <RegisterForm ref="registerForm" 
          onSubmit = {this.handleSubmit} 
          isDisabled = {this.props.userManagement.addingOrg} 
          errorMessage = {this.props.userManagement.addingOrgError}
        />
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    userManagement: state.userManagement
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterContainer));