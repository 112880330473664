import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import Button from './createEvent/button';

/**
 * Conform Doalog Class
 */
export default class ConfirmDialog extends React.Component {

    get dialogActions() {
        const actions = [
        <Button
            label="Cancel"
            primary={true}
            onClick={this.props.onClose}
        />,
        <Button
            label={this.props.confirmText}
            primary={true}
            onClick={this.props.onConfirm}
        />,
        ];  
        return actions;
    }
  render() {    
      return ( 
        <Dialog
            title={this.props.title}
            actions={this.dialogActions}
            modal={true}
            open={this.props.open}
        >
            {this.props.messageText}
        </Dialog>     
      )}
}
ConfirmDialog.propTypes = {
    title: PropTypes.string,
    confirmText: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    messageText: PropTypes.string,
    open: PropTypes.bool
}

