/**
 * Event Template related state reducers / handlers
 */

import { 
    SELECT_EVENT_TEMPLATE, 
    REFRESH_EVENT_TEMPLATES, 
    CREATE_EVENT_TEMPLATE, 
    LOAD_EVENT_TEMPLATE,
    LOADING_EVENT_TEMPLATE,
    SAVE_EVENT_TEMPLATE, 
    SAVE_EVENT_ERROR ,
    UPDATE_EVENT_TEMPLATE,
    UPDATE_EVENT_TEMPLATE_ATTRIBUTES,
    EVENT_COLUMN_MAPPING_ADDED,
    EVENT_COLUMN_MAPPING_REMOVED,
    EVENT_COLUMN_MAPPING_ERROR,
    EVENT_COLUMN_MAPPING_ERROR_CONFIMED,
    SAVE_EVENT_REQUEST_SENT,
    SAVE_EVENT_VALIDATION_ERROR,
    PUBLISH_EVENT_REQUEST,
    PUBLISH_EVENT_COMPLETE,
    PUBLISH_EVENT_LOG_UPDATED,
    PUBLISH_EVENT_ERROR,
    OPEN_PUBLISH_DIALOG,
    CLOSE_PUBLISH_DIALOG,
    RESET_PUBLISH_DIALOG,
    FILE_ADDED_TO_EVENT_TEMPLATE,
    OPEN_FILE_DETAILS_DRAWER,
    CLOSE_FILE_DETAILS_DRAWER,
    OPEN_FILE_SELECTION_DIALOG,
    CLOSE_FILE_SELECTION_DIALOG,
    TEMPLATE_RETRIEVAL_ERROR,
    FILE_REMOVED_FROM_EVENT_TEMPLATE
} from '../constants/actionTypes';

/**
 * Redux seems to suck when you have deeply nested objects
 * It only does shallow diffs to determine what needs to render
 * We need to transform our template object to a form that the UI can use
 * but not clone its sections or we lose the helper methods
 */
const initialState = {
    templates: {},
    selectedTemplate: null,
    templateRetrievalError: null,
    remoteError: null,
    eventName: null, 
    eventAttributeList: {},
    personAttributeList: {},
    mappingErrorMessage: null,
    savingEvent: false,
    loadingEvent: false,
    mappedFileId: null,
    canPublish: false,

    validationError: null,

    publishDialogOpen: false,
    isPublishing: false,
    publishLog: [],
    publishEventResponse: null, 
    publishMessage: null,
    publishError: null,

    fileSelectDialogOpen: false,
    selectedFile: null,
    fileDetailsOpen: false

};

export default function eventTemplateReducer(state = initialState, action) {
  switch (action.type) {

    // Publish Actions
    case OPEN_PUBLISH_DIALOG:
        return mergeState(state,{
           publishDialogOpen: true,
           publishMessage: null
        });     
    case CLOSE_PUBLISH_DIALOG:
        return mergeState(state,{
           publishDialogOpen: false,
           publishMessage: null,
           publishLog: [],
           publishEventResponse: null
        }); 

    case PUBLISH_EVENT_REQUEST:
        return mergeState(state,{
           isPublishing: true,
           publishMessage: null,
           publishError:null,
           publishEventResponse: null
        });    
    case PUBLISH_EVENT_COMPLETE:
        return mergeState(state,{
           isPublishing: false,
           publishError:null,
           publishEventResponse: action.payload,
           publishMessage: 'Event Created Successfully'           
        });    
    case PUBLISH_EVENT_LOG_UPDATED:
        var currLog = (action.payload) ? Object.keys(action.payload).map(key => action.payload[key]) : [];
        return mergeState(state,{
            publishLog: currLog
        });     
    case PUBLISH_EVENT_ERROR:
        return mergeState(state,{
           isPublishing: false,
           publishMessage: null,
           publishError: action.payload
        });   
    case SELECT_EVENT_TEMPLATE:
        var event = action.payload;
        return mergeState(state,{
           selectedTemplate: event,
           newTemplate: false,
           templateRetrievalError: null
        });

    case LOADING_EVENT_TEMPLATE:
        return mergeState(state,{
           loadingEvent: true,
           templateRetrievalError: null
        });
    case TEMPLATE_RETRIEVAL_ERROR:
        return mergeState(state,{
           templateRetrievalError: action.payload 
        });

    case REFRESH_EVENT_TEMPLATES:
        return mergeState(state,{
           templates: action.payload ,
           selectedTemplate: null,
           templateRetrievalError: null
        });

    case CREATE_EVENT_TEMPLATE:
        var normalizedState = mapEventTemplateToState(action.payload);
        normalizedState['remoteError'] = null;
        normalizedState['canPublish'] = false;
        normalizedState['selectedFile'] = null;
        normalizedState['validationError'] = null;
        normalizedState['templateRetrievalError'] = null;
        return mergeState(state,normalizedState); 

    case LOAD_EVENT_TEMPLATE:
        var normalizedState = mapEventTemplateToState(action.payload);
        normalizedState['remoteError'] = null;
        normalizedState['canPublish'] = true;
        normalizedState['validationError'] = null;
        normalizedState['templateRetrievalError'] = null;
        return mergeState(state,normalizedState); 

    case SAVE_EVENT_TEMPLATE:
        return mergeState(state,{
           selectedTemplate: action.payload,
           remoteError: null,
           savingEvent: false,
           canPublish: true,
           publishDialogOpen: true,
           validationError: null
        }); 

    case SAVE_EVENT_VALIDATION_ERROR:
        return mergeState(state,{
           validationError: action.payload
        });     

    case SAVE_EVENT_REQUEST_SENT:
        return mergeState(state,{
            savingEvent: true,
            validationError: null
        }); 

    case SAVE_EVENT_ERROR:
        return mergeState(state,{
           savingEvent: false,
           remoteError: action.payload 
        });       

    case UPDATE_EVENT_TEMPLATE:
        var normalizedState = mapEventTemplateToState(action.payload);
        return mergeState(state,normalizedState);
         
    case UPDATE_EVENT_TEMPLATE_ATTRIBUTES:
        return mergeState(state,{
            eventAttributeList: action.payload
        });  

    case EVENT_COLUMN_MAPPING_ERROR:
        return mergeState(state,{
            mappingErrorMessage: action.payload
        }); 

    case EVENT_COLUMN_MAPPING_ERROR_CONFIMED:
        return mergeState(state,{
            mappingErrorMessage: null
        });

    case EVENT_COLUMN_MAPPING_REMOVED:

    case FILE_ADDED_TO_EVENT_TEMPLATE:
        var payload = action.payload;
        var template = payload['template'];
        var file = payload['file'];
        var normalizedState = mapEventTemplateToState(template);
        normalizedState['selectedFile'] = file;        
        normalizedState['fileSelectDialogOpen'] = false;
        normalizedState['fileDetailsOpen'] = true;
        return mergeState(state,normalizedState);    

    case FILE_REMOVED_FROM_EVENT_TEMPLATE:
        var template = action.payload['template'];
        console.log(template);
        var normalizedState = mapEventTemplateToState(template);
        normalizedState['selectedFile'] = null;        
        normalizedState['fileSelectDialogOpen'] = false;
        normalizedState['fileDetailsOpen'] = false;
        return mergeState(state,normalizedState);        

    case CLOSE_FILE_DETAILS_DRAWER:
        return mergeState(state,{fileDetailsOpen:false});
    case OPEN_FILE_DETAILS_DRAWER:
        return mergeState(state,{fileDetailsOpen:true});    
    case OPEN_FILE_SELECTION_DIALOG:
        return mergeState(state,{fileSelectDialogOpen:true});    
    case CLOSE_FILE_SELECTION_DIALOG:        
        return mergeState(state,{fileSelectDialogOpen:false});        
    default:
      return state;
  }
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}

/**
 * Normalize our Event Template Data for app state
 */
function mapEventTemplateToState(template) {
    var state = {
        selectedTemplate: template,
        eventName: template.eventName,
        eventAttributeList: mapAttributeListToState(template.eventAttributes),
        personAttributeList: mapAttributeListToState(template.personAttributes),
        mappedFileId: template.dataSource
    };
    return state;
}

function mapAttributeListToState(attrList) {
    var state = {
    };
    for(var attr of attrList) {
        state[attr.attributeId] = attr;
    }
    return state;
}