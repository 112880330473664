/**
 * User File Services 
 * Upload Excel Files
 * Retrive a list of files
 * ...
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import request from 'superagent';
import config from '../../../config';

//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();

export function openUserDialog(open) {
    if(open) {
        return {
            type: actionTypes.ADD_USER_OPEN_DIALOG
        }
    } else {
        return {
            type: actionTypes.ADD_USER_CLOSE_DIALOG
        }        
    } 
}

export function openDeleteUserDialog(open) {
    if(open) {
        return {
            type: actionTypes.DELETE_USER_OPEN_DIALOG
        }
    } else {
        return {
            type: actionTypes.DELETE_USER_CLOSE_DIALOG
        }        
    }     
}

/**
 * requestUserList
 * Returns the full list of users for an organizationId 
 */
export function requestUserList(organizationId) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.USER_LIST_REQUEST
    });
    db.userToken()
    .then(function(token) {
        sendUserListRequest(token, organizationId)
        .then(function(result) {        
            console.log('dispatching response');         
            dispatch({
                type: actionTypes.USER_LIST_RESPONSE,
                payload: result
            });                      
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.USER_LIST_ERROR,
                payload: err.toString()
            });   
        });
    })
    .catch(function(err) {
        console.log('Token Error',err);
        dispatch({
            type: actionTypes.USER_LIST_ERROR,
            payload: err.toString()
        });
    });            
  };
}


export function signUpUser(email, password, orgName) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.ADD_ORG_REQUEST
    });
    sendAddNewOrgRequest(orgName, email, password)
    .then(function(result) {        
        dispatch({
            type: actionTypes.ADD_ORG_SUCCESS,
            payload: result
        });
        // Refresh Org List
        // Shold return a valid session : for now
        // dispatch(requestUserList(orgId));
    })
    .catch(function(err) {
        dispatch({
            type: actionTypes.ADD_ORG_ERROR,
            payload: err.toString()
        });   
    });            
  };
}

export function addUserToOrg(email,password, userRoleId, orgId) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.ADD_USER_REQUEST
    });
    db.userToken()
    .then(function(token) {
        sendAddUserRequest(token, orgId, email, password, userRoleId)
        .then(function(result) {        
            dispatch({
                type: actionTypes.ADD_USER_SUCCESS,
                payload: result
            });                      
            // Refresh Org List
            dispatch(requestUserList(orgId));
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.ADD_USER_ERROR,
                payload: err.toString()
            });   
        });
    })
    .catch(function(err) {
        console.log('Token Error',err);
        dispatch({
            type: actionTypes.ADD_USER_ERROR,
            payload: err.toString()
        });
    });            
  };
}

export function removeUserFromOrg(userId, orgId) {
 return function(dispatch) {
    dispatch({
      type: actionTypes.DELETE_USER_REQUEST
    });
    db.userToken()
    .then(function(token) {
        sendRemoveUserRequest(token, userId, orgId)
        .then(function(result) {        
            dispatch({
                type: actionTypes.DELETE_USER_SUCCESS,
                payload: result
            });                      
            // Refresh Org List
            dispatch(requestUserList(orgId));
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.DELETE_USER_ERROR,
                payload: err.toString()
            });   
        });
    })
    .catch(function(err) {
        console.log('Token Error',err);
        dispatch({
            type: actionTypes.DELETE_USER_ERROR,
            payload: err.toString()
        });
    });            
  };    
}


export function resetUserPassword(email) {
 return function(dispatch) {    
    dispatch({
      type: actionTypes.RESET_PASSWORD_REQUEST
    });    
    sendResetPasswordRequest(email)
    .then(function(result) {        
        dispatch({
            type: actionTypes.RESET_PASSWORD_REQUEST_SENT,
            payload: result
        });                      
    })
    .catch(function(err) {
        dispatch({
            type: actionTypes.RESET_PASSWORD_REQUEST_ERROR,
            payload: err.toString()
        });   
    });  
 }
}

export function performPasswordReset(userId, token, password) {
    return function(dispatch) {       
        dispatch({
        type: actionTypes.RESET_PASSWORD_REQUEST
        });    
        sendResetPassword(userId, token, password)
        .then(function(result) {        
            dispatch({
                type: actionTypes.USER_PASSWORD_UPDATED,
                payload: result
            });                      
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.RESET_PASSWORD_REQUEST_ERROR,
                payload: err.toString()
            });   
        });    
    }
}

// Internal Functions to interact with API

function sendResetPassword(userId, token, password) {    
    return new Promise(function(resolve,reject) {
        var params = {"userId": userId, "token": token, "password": password};
        let endpoint = `admin/actions/resetpassword`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_endpoint)
        .send(params)
        .end(function(err, res){            
            if(err) {
                if(res && res.hasOwnProperty('body')) {
                    reject(res.body);
                } else {
                    reject(err);
                }
            } else {
                resolve(res.body);
            }
        });
    });
}

function sendResetPasswordRequest(email) {    
    return new Promise(function(resolve,reject) {
        var params = {"email": email};
        let endpoint = `admin/actions/requestpasswordreset`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_endpoint)
        .send(params)
        .end(function(err, res){            
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}


function sendUserListRequest(token, organizationId) {
    var params = {"organizationId": organizationId };
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${organizationId}/users`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .get(request_endpoint)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}

function sendAddUserRequest(token, organizationId, email, password, userRoleId) {
    var params = {"orgId": organizationId, "email": email, "password": password, "userRoleId": userRoleId };
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${organizationId}/users`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_endpoint)
        .send(params)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                if(res && res.hasOwnProperty('body') && res.body.message) {
                    reject(res.body.message);
                } else {
                    reject(err);
                }                
            } else {
                resolve(res.body);
            }
        });
    });
}

function sendRemoveUserRequest(token, userId, organizationId) {    
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${organizationId}/users/${userId}`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .delete(request_endpoint)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                if(res && res.body && res.body.message) {
                    reject(res.body.message);
                } else {
                    reject(err);
                }                
            } else {
                resolve(res.body);
            }
        });
    });
}

function sendAddNewOrgRequest(organizationName, email, password) {   
    var params = {"orgName": organizationName, "email": email, "password": password}; 
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_endpoint)
        .send(params)
        .end(function(err, res){            
            if(err) {
                if(res && res.hasOwnProperty('body') && res.body.message) {
                    reject(res.body.message);
                } else {
                    reject(err);
                }                
            } else {
                resolve(res.body);
            }
        });
    });
}


