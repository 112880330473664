import React from 'react';
import ReactDOM from 'react-dom';
import injectTapEventPlugin from 'react-tap-event-plugin';
import { browserHistory } from 'react-router';

//Redux
import { Provider } from 'react-redux';


import configureStore from './store/configureStore';
const store = configureStore();

import { requireAuth } from './utils/requireAuth';
const secure = requireAuth(store);

import App from './containers/App';
import makeRoutes from './routes';
const routes = makeRoutes(secure);


const history = browserHistory;

injectTapEventPlugin();



const mountNode = document.querySelector('#page-wrapper');

ReactDOM.render(
    <Provider store={store}>
        <App history={history} routes={routes} />
    </Provider>
     , mountNode);


 
      

