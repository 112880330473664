import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';



import SalesforceAuthForm from '../components/salesforce/salesforceAuthForm';



export default class SalesforceContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleAuthToSalesforce = this.handleAuthToSalesforce.bind(this);
    this.handleSaveSelection = this.handleSaveSelection.bind(this);
  }

  componentDidMount() {
    console.log('Subscribing to SFDC Cred Node');
    var userId = this.props.userId;        
    this.props.actions.subscribeToSalesforceCredentials(userId);
    this.props.actions.getSalesforceCampaignList();
  }

  componentWillUnmount() {
    console.log('Unsubscribing from SFDC Cred Node');
    var userId = this.props.userId;        
    this.props.actions.unsubscribeFromSalesforceCredentials(userId);
  }

  componentWillReceiveProps() {
      
  }

  handleAuthToSalesforce() {
      this.props.actions.authWithSalesforce();
  }

  handleSaveSelection(objectName, campaign) {
      var organizationId = this.props.organizationId;
      var template = this.props.eventTemplate;            
      this.props.actions.sfdcFileUpload(organizationId, objectName, template, campaign);
  }

  render() {
        var hasSalesforceAuth = (this.props.salesforceAuth) ? true : false;
        var displayToken = '';
        var currentInstanceUrl = '';
        if(this.props.salesforceAuth) {
            currentInstanceUrl = this.props.salesforceAuth.instanceUrl;
            var currentRefreshToken = this.props.salesforceAuth.refreshToken;
            displayToken = `${currentRefreshToken.substring(0,5)}********` ; 
        }

        return <SalesforceAuthForm 
                handleAuthToSalesforce={this.handleAuthToSalesforce}
                hasSalesforceAuth={hasSalesforceAuth}
                currentInstanceUrl={currentInstanceUrl}
                currentRefreshToken={displayToken}
                fetchingCampaigns={this.props.fetchingCampaigns}
                activeCampaigns={this.props.activeCampaigns}
                handleSalesforceSelection={this.handleSaveSelection}
                fetchingSfdcDetails={this.props.fetchingSfdcDetails}
                sfdcObjectDetailsError={this.props.sfdcObjectDetailsError}
        />
  }
}

function mapStateToProps(state) {
    return {
        userId: state.auth.userId,
        organizationId: state.auth.currentOrganization,
        salesforceAuth: state.auth.salesforceAuth,
        fetchingCampaigns: state.userFiles.fetchingSfdcActiveCampaigns, 
        activeCampaigns: state.userFiles.sfdcActiveCampaigns,
        eventTemplate: state.eventtemplate,
        fetchingSfdcDetails: state.userFiles.fetchingSfdcDetails,
        sfdcObjectDetailsError: state.userFiles.sfdcObjectDetailsError
    };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesforceContainer));

