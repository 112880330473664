/**
 * RequireAuth is a higher order componenet 
 * You can wrap any component with this container to secure it
 */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import FirebaseDbService from '../services/firebaseDbService';

export default function(WrappedComponent) {
  class Auth extends React.Component {
    componentWillMount() {
      if (!this.props.authenticated) {
        // Try to auth w/ a valid session we have
        //try this action validateUser
        var db = new FirebaseDbService();
        var user = db.currentUser();
        if(user) {
          // Set user action
          console.log('Validaton User');
          this.props.actions.validateUser();
        } else {
          console.log('Redirecting to Login : Page requires auth');
          browserHistory.replace('/login');
        }
      }
    }
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(Actions, dispatch)
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Auth);
}

