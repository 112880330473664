import React, { PropTypes } from 'react';
import TextField from 'material-ui/TextField';


export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    // Bind methods to this class so "this" works as expected
    //this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  /**
   * Event Handlers
   */
  handleInputChange(e) {
    this.setState({value: e.target.value});  
  }
   
  render() {
    var val = (this.props.value && this.props.value.length == 0) ? null : this.props.value;
    var hintText = (this.props.hintText && this.props.hintText.length > 0) ? this.props.hintText : this.props.labelText; 
    
    var props = {
        onChange: this.props.onChange,
        hintText: hintText,
        value: val,
        floatingLabelText: null,
        floatingLabelFixed: false,
        disabled: (this.props.disabled) ? true : false,
        underlineShow: (this.props.underlineShow === false) ? false : true,
        fullWidth: (this.props.fullWidth === false) ? false : true,
        inputStyle: (this.props.inputStyle) ? this.props.inputStyle : null,
        style: (this.props.style) ? this.props.style : null
    };

    return (
        <TextField {...props }/>
    );
  }
}


TextInput.propTypes = {
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    underlineShow: PropTypes.bool,
    inputStyle: PropTypes.object,
    style: PropTypes.object,
    fullWidth: PropTypes.bool
}