import React, { PropTypes } from 'react';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';


/**
 * AttributeTable 
 * Show the current count of tickets and status in a card/container
 */
export default class AttributeFilter extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
      var self = this;
      if(self.props.attributes) {
        return(
        <SelectField value={self.props.value} onChange={self.props.handleChange} hintText="Select Attribute">
            {
                Object.keys(self.props.attributes).map(function(value, index) {
                    return <MenuItem key={self.props.attributes[value].attributeName} value={self.props.attributes[value].attributeName} primaryText={self.props.attributes[value].attributeName}/>
                })
            }
            </SelectField>
        );
      }
      return null;
  } 
}

AttributeFilter.propTypes = {
    attributes: PropTypes.object,
    value: PropTypes.string,
    handleChange: PropTypes.func
}
