import React, { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';


const columnHeaders = [
    "Pending",
    "CheckedIn",
    "Cancelled"
];

/**
 * AttributeTable 
 * Show the current count of tickets and status in a card/container
 */
export default class AttributeTable extends React.Component {
  constructor(props) {
    super(props);
  }

  get columnHeaders() {
      var columns = [];
      columns.push(<TableRowColumn key="headerValue">Attribute Value</TableRowColumn>);
      for(var column of columnHeaders) {
          columns.push(<TableRowColumn key={column}>{column}</TableRowColumn>);
      }     
      return columns;
  }

  createColumns(data) {
      var columns = [];
      var i = 1;
      for(var column of columnHeaders) {
          var value = (data.hasOwnProperty(column)) ? data[column] : 0;
          columns.push(<TableRowColumn key={i}>{value}</TableRowColumn>);
          i++;
      }
      return columns;    
  }

  get tableRows() {
      var rows = [];
      if(this.props.attributeFilter !== null) {
          if(this.props.attributeCounts.hasOwnProperty(this.props.attributeFilter)) {
              var data = this.props.attributeCounts[this.props.attributeFilter];
              var i = 0;
              for(var value in data) {
                var displayValue = (value === null || value == 'null') ? '[Empty]' : value;
                rows.push(
                    <TableRow key={i}>
                        <TableRowColumn key={1}>{displayValue}</TableRowColumn>
                        {this.createColumns(data[value])}
                    </TableRow>
                );
                i++;
              }
          }
      }
      return rows;
  }
  render() {
      return(
        <Table
            height="550px"
            fixedHeader={true}
            selectable={false}
            multiSelectable={false}
        >
            <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
            >
            <TableRow>
                {this.columnHeaders}
            </TableRow>
            </TableHeader>
            <TableBody
                displayRowCheckbox={false}
                deselectOnClickaway={false}
            >
                {this.tableRows}
            </TableBody>
        </Table>
      );
  } 
}

AttributeTable.propTypes = {
    attributeCounts: PropTypes.object,
    attributeFilter: PropTypes.string
}