import React, { PropTypes } from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import Attribute from './attribute';

const styles = {
    button: { margin: 12 }
};



export default class AttributeList extends React.Component {
  constructor(props) {
    super(props);
       
    // Bind methods to this class so "this" works as expected
    //this.handleInputChange = this.handleInputChange.bind(this);
  }

  render() {
      var l = this.props.attributeList.length;
      var self = this;
      
      let addAttrButton = <div><RaisedButton
                label="Add Attribute"
                primary={true}
                style={styles.button}
                onClick={this.props.onAddItem}
            /></div> ; 
      
    return (
      <div>
        <h3>{this.props.title}</h3>
        <div>
            {
                Object.keys(this.props.attributeList).map(function (key) {
                    return <Attribute 
                      key={this.props.attributeList[key].attributeId} 
                      attribute={this.props.attributeList[key]} 
                      onRemove={self.props.onRemoveItem} 
                      onAddMapping={self.props.onAddMapping}
                      onValueChange={self.props.onValueChange}
                      onAttributeTypeChange={self.props.onAttributeTypeChange}
                    />;
                },this)
            }
        </div>
        { (this.props.onAddItem) ? addAttrButton : null }
      </div>
    );
  }
}

AttributeList.propTypes = {
    title: PropTypes.string,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddMapping: PropTypes.func,
    onValueChange: PropTypes.func,
    onAttributeTypeChange: PropTypes.func,
    attributeList: PropTypes.object
    
}