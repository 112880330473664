/**
 * Authentication related state reducers / handlers
 */

import { 
    FILE_LIST_RESPONSE, 
    FILE_LIST_REQUEST, 
    FILE_UPLOAD_SUCCESS, 
    FILE_UPLOAD_ERROR, 
    USER_FILE_SELECTED, 
    USER_FILE_CLOSED,
    USER_FILE_UPLOAD_SERVICE_DOWN,
    USER_FILE_UPLOAD_SERVICE_AVAILABLE,
    FILE_UPLOAD_INPROGRESS,

    FILE_DELETE_INPROGRESS,
    FILE_DELETE_SUCCESS, 
    FILE_DELETE_ERROR,

    SFDC_CAMPAIGN_LIST_REQUEST,
    SFDC_CAMPAIGN_LIST_RESPONSE,
    SFDC_CAMPAIGN_LIST_ERROR,
    SFDC_FILE_REQUEST,
    SFDC_FILE_ERROR

} from '../constants/actionTypes';

const initialState = {
    fetchingFiles: false, 
    fileList: null,
    selectedFile: null,
    fileUploadError: null,
    uploadServerAvailable: true,
    uploadServiceChecked: false,
    isUploadingFile: false,
    fileDeleteError: null, 
    isDeletingFile: null,
    fetchingSfdcActiveCampaigns: false,
    sfdcActiveCampaigns: null,
    fetchingSfdcDetails: false, 
    sfdcObjectDetailsError: null
};

export default function userFileReducer(state = initialState, action) {
  switch (action.type) {
    case USER_FILE_UPLOAD_SERVICE_DOWN:
      return mergeState(state, {
          uploadServerAvailable: false,
          uploadServiceChecked: true
    });    

    case USER_FILE_UPLOAD_SERVICE_AVAILABLE:
      return mergeState(state, {
          uploadServerAvailable: true,
          uploadServiceChecked: true
    });   

    case USER_FILE_SELECTED:
      return mergeState(state, {
          selectedFile: action.payload
    });

    case USER_FILE_CLOSED:
      return mergeState(state, {
          selectedFile: null
    });    

    case FILE_LIST_REQUEST:
     return mergeState(state, {
        fetchingFiles: true
    });
    case FILE_LIST_RESPONSE:
     return mergeState(state, {
        fetchingFiles: false,
        fileList: action.payload.files,
        selectedFile: action.payload.selectedFile,
        fileDeleteError: null,
        isDeletingFile:false,
        fetchingSfdcDetails: false,
        sfdcObjectDetailsError: null                
    });
    case FILE_UPLOAD_INPROGRESS:
     return mergeState(state, {
        isUploadingFile: true
    });         
    case FILE_UPLOAD_SUCCESS:
     return mergeState(state, {
        fetchingFiles: false,
        fileUploadError: null,
        isUploadingFile:false,
        fetchingSfdcDetails: false,
        sfdcObjectDetailsError: null        
    }); 
    case FILE_UPLOAD_ERROR:    
     return mergeState(state, {
        fetchingFiles: false,
        fileUploadError: action.payload,
        isUploadingFile:false,
        fetchingSfdcDetails: false,
        sfdcObjectDetailsError: null        
    });    

    case FILE_DELETE_INPROGRESS:
     return mergeState(state, {
        isDeletingFile: true
    });              
    case FILE_DELETE_ERROR:
     return mergeState(state, {
        fileDeleteError: action.payload,
        isDeletingFile:false
    });  
    case FILE_DELETE_SUCCESS: 
     return mergeState(state, {
        fileUploadError: null,
        isDeletingFile:false
    });     

    // SFDC Reducers
    case SFDC_CAMPAIGN_LIST_REQUEST:
     return mergeState(state, {
        fetchingSfdcActiveCampaigns: true,
        sfdcActiveCampaigns: null
    });         
    case SFDC_CAMPAIGN_LIST_RESPONSE:
     return mergeState(state, {
        fetchingSfdcActiveCampaigns: false,
        sfdcActiveCampaigns: action.payload
    });         
    case SFDC_CAMPAIGN_LIST_ERROR:
     return mergeState(state, {
        fetchingSfdcActiveCampaigns: false,
        sfdcActiveCampaigns: null
    });
    case SFDC_FILE_ERROR:
     return mergeState(state, {
        fetchingSfdcDetails: false,
        sfdcObjectDetailsError: action.payload
    });   
    case SFDC_FILE_REQUEST:
     return mergeState(state, {
        fetchingSfdcDetails: true,
        sfdcObjectDetailsError: null
    });
    default:
      return state;
  }
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}
