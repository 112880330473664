/**
 * Event Publishing Actions
 *   PUBLISH_EVENT_REQUEST,
 *   PUBLISH_EVENT_COMPLETE,
 *   PUBLISH_EVENT_LOG_UPDATED,
 *   PUBLISH_EVENT_ERROR
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import request from 'superagent';

import config from '../../../config';


//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();

/**
 * Publish an Event 
 * We need to send a request to our API to create the event and people/tickets
 * Subscribe to the publish log and fire events when we get them
 * Return a Success or Error message event
 */


export function publishEvent(organizationId, eventConfigId, action, oldEventId) {
  var request_action = (action == 'create') ? 'create' : 'update';
  return function(dispatch) {
    dispatch({
      type: actionTypes.PUBLISH_EVENT_REQUEST
    });
    db.userToken()
    .then(function(token) {
        sendCreateRequest(token, organizationId, eventConfigId, request_action, oldEventId)
        .then(function(res) {
            // Payload is type IEventCreationResponse
            dispatch({
                type: actionTypes.PUBLISH_EVENT_COMPLETE,
                payload: res
            });
            detatchPublishLogListener(organizationId,eventConfigId);
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.PUBLISH_EVENT_ERROR,
                payload: err.toString()
            });
            detatchPublishLogListener(organizationId,eventConfigId);
        });
        dispatch(subscribeToPublishLog(organizationId,eventConfigId));
    })
    .catch(function(err) {
        dispatch({
            type: actionTypes.PUBLISH_EVENT_ERROR,
            payload: err.toString()
        });
    });
  };    
}


export function openPublishDialog() {
    return {
        type: actionTypes.OPEN_PUBLISH_DIALOG
    };
}

export function closePublishDialog() {
    return {
        type: actionTypes.CLOSE_PUBLISH_DIALOG
    };
}

/**
 * Action Helpers
 */
function sendCreateRequest(token, organizationId, eventConfigId, action, oldEventId) {
    var endpoint = (action == 'create') ? 'create-event' : 'update-event';
    var params = {
        "eventConfigId": eventConfigId,
        "organizationId": organizationId 
    };
    if(oldEventId) {
        params['eventId'] = oldEventId;
    }
    return new Promise(function(resolve,reject) {
        let request_uri = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_uri)
        .send(params)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}

// tappedin/{orgId}/admin/eventcreate_log/{eventConfigId}/
function subscribeToPublishLog(organizationId,eventConfigId) {
    var node = `/tappedin/${organizationId}/admin/eventcreate_log/${eventConfigId}`;
    return function(dispatch) {
        db.subscriberToValue(node,function(err,snapshot) {
            if(err) {
                console.log('Error in fetching log',err);              
            } else {
                dispatch({
                    type: actionTypes.PUBLISH_EVENT_LOG_UPDATED,
                    payload: snapshot
                });
            }
        });
    };    
}

function detatchPublishLogListener(organizationId,eventConfigId) {
    console.log('Detaching from Log Events');
    var node = `/tappedin/${organizationId}/admin/publishlog/${eventConfigId}`;
    db.detatchListeners(node);
}