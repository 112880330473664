import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import ResetPasswordForm from '../components/ResetPasswordForm';

class ResetPasswordContainer extends React.Component {

  constructor() {
    super();
    // Bind methods to this class so "this" works as expected
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUrlParams = this.getUrlParams.bind(this);

    this.resetParams = null;

  }
  
  componentWillMount() {
    //console.log('Context Path',this.context.router.getCurrentPathname());
  }

  componentDidMount() {
      var params = this.getUrlParams();
      console.log(params);
  }

  componentWillReceiveProps(nextProps)  {
      var params = this.getUrlParams();
  }

  getUrlParams() {
    if(this.props.hasOwnProperty('params')) {        
        let userId = this.props.params['userId'] || null;
        let token = this.props.params['token'] || null;
        if(userId && token) {
            return {
                userId: userId, 
                token: token
            };
        }
    }     
    return null; 
  }

  handleSubmit(e) {  
      // What action do we submit ? 
      var resetParams = this.getUrlParams();

        console.log('Waht Actions?', resetParams);

      if(resetParams) {
        var password = this.refs.resetPasswordForm.state.password;
        this.props.actions.performPasswordReset(resetParams['userId'], resetParams['token'], password);  
      } else {
        var email = this.refs.resetPasswordForm.state.email;      
        this.props.actions.resetUserPassword(email);
      }

  };

  get resetForm() {
    return (
      <ResetPasswordForm ref="resetPasswordForm" 
        onSubmit = {this.handleSubmit} 
        isDisabled = {this.props.sendingPasswordResetRequest} 
        errorMessage = {this.props.resetPasswordRequestError}
        requestSent = {this.props.resetPasswordRequestSent}
        userId = {this.props['params']['userId']}
        token = {this.props['params']['token']}
        passwordUpdated = {this.props.userPasswordUpdated}
      />
    );
  }

  render() {
    return this.resetForm;
  }
}
function mapStateToProps(state) {
  return {
    sendingPasswordResetRequest: state.userManagement.sendingPasswordResetRequest, 
    resetPasswordRequestError: state.userManagement.resetPasswordRequestError,
    resetPasswordRequestSent: state.userManagement.resetPasswordRequestSent,
    userPasswordUpdated: state.userManagement.userPasswordUpdated    
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPasswordContainer));