import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import RaisedButton from 'material-ui/RaisedButton';
import ActionDone from 'material-ui/svg-icons/action/done';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';
import {blue500, red500, green500} from 'material-ui/styles/colors';

const tblStyle = {
    'fontSize': '1.2em',
    'display': 'inline-block',
    'height': '20px'
};

const iconStyle = {
    'width':'20px',
    'height':'20px',
    'marginBottom':'-5px',
    'marginRight':'5px'
};

const successMsg = {
    'padding':'5px',
    'fontSize':'1.3em',
    'fontWeight':'bold'
};


/**
 * Confirm Event Publishing Dialog
 */
export default class ArchiveEventDialog extends React.Component {

    get actionLabel() {
        if(this.props.archiveMessage) {
            return "Close Dialog";
        } else {
            return "Cancel";
        }
    }

    get dialogActions() {
        var isDisabled = (this.props.isArchiving && this.props.archiveMessage == null) ? true : false;
        const actions = [
        <RaisedButton
            label={this.actionLabel}
            primary={false}
            onClick={this.props.onCancel}  
            disabled={isDisabled}          
        />
        ];  
        return actions;
    }


  get archiveMessage() {
      if(this.props.archiveError) {
        return(
            <div className="alert alert-danger alert-white">
                {this.props.archiveError}
            </div>
        );       
      } else if(this.props.archiveMessage) {
        return(
            <div className="alert alert-success alert-white">
                 <strong>{this.props.archiveMessage}</strong> <br />
                 Your event is now archived. It will no longer appear on your active events list.
            </div>
        );          
      } 
      return(
        <div className="alert alert-warning">            
            <strong>Take Note</strong> Archiving an event will remove it from you event list and set it to read-only. Any reports you have created will still be available.
        </div>
      );   
  }

  get archiveLabel() {
      if(this.props.archiveMessage) {
          return "Event Archived";
      }
      return "Archive Event";
  }

  render() {   
      var title = `Archive ${this.props.eventName}`;
      return ( 
        <Dialog
            title={title}
            actions={this.dialogActions}
            modal={true}
            open={this.props.open}
        >
           

            {this.archiveMessage}
            
        
            <div className="dialog-inline-action" style={{'textAlign':'center',padding:'10px'}}>
                <RaisedButton
                    label={this.archiveLabel}
                    primary={true}
                    onClick={this.props.onConfirm}
                    disabled={this.props.isArchiving}
                    icon={ (this.props.archiveMessage) ? <NavigationCheck /> : null }
                />
            </div>

            

        </Dialog>     
      )}
}

ArchiveEventDialog.propTypes = {
    eventName: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    isArchiving: PropTypes.bool,
    archiveError: PropTypes.string, 
    archiveMessage: PropTypes.string
}

