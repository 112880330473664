import React, { PropTypes } from 'react';
import { Link } from 'react-router';
import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from 'material-ui/Toolbar';

import NavigationMenu from 'material-ui/svg-icons/navigation/menu';

export default class PageNavBar extends React.Component {

  // If User is Authenticated return the Profile link. If not, return the Sign-In Link

  get profileAction() {
    return (this.props.authenticated === false) ? this.loginLink : this.logoutLink;
  }

  get loginLink() {
    var loginLink = <ul className="nav navbar-nav top-navbar-items">
        <li><Link to={`/login`}>Log In</Link></li>
    </ul>
    ;
    return loginLink;
  }

  get logoutLink() {
    var logoutLink = <ul className="nav navbar-nav top-navbar-items">
        <li> <Link onClick={this.props.handleSignOut} style={{cursor: 'pointer'}}>Sign Out</Link> </li>
    </ul>
    ;
    return logoutLink;
  }

  get profileLink() {
    var profileLink = <li className="dropdown top-menu-item-xs">
        <a href="" className="dropdown-toggle menu-right-item profile" data-toggle="dropdown" aria-expanded="true"><img src="assets/images/users/avatar-1.jpg" alt="user-img" className="img-circle" /> </a>
        <ul className="dropdown-menu">
            <li><a href="javascript:void(0)"><i className="ti-user m-r-10"></i> Profile</a></li>
            <li><a href="javascript:void(0)"><i className="ti-settings m-r-10"></i> Settings</a></li>
            <li><a href="javascript:void(0)"><i className="ti-lock m-r-10"></i> Lock screen</a></li>
            <li className="divider"></li>
            <li><a href="javascript:void(0)"><i className="ti-power-off m-r-10"></i> Logout</a></li>
        </ul>
    </li>   
    ; 
    return profileLink;
  }

render() {
  return (
            <div className="topbar">                
                <div className="topbar-left" style={{width:'190px'}}>
                    <div className="">                        
                        <a href="/" className="logo">
                            <span style={{fontSize:'18px', color:'black', fontFamily: 'Roboto', fontWeight:200}}>Tappedin Events</span>
                        </a>
                    </div>
                </div>
                <div className="navbar navbar-default" role="navigation">
                    <div className="container">
                        <div className="">
                            <div className="pull-left">
                                <button type="button" className="button-menu-mobile visible-xs visible-sm" style={{padding:'5px'}}>                                    
                                    <NavigationMenu />
                                </button>
                                <span className="clearfix"></span>
                            </div>




                            <ul className="nav navbar-nav navbar-right top-navbar-items-right pull-right">

                              {this.profileAction}


                            </ul>
                        </div>
                    </div> 
                </div> 
            </div>            
  );
}


}
PageNavBar.propTypes = {
    authenticated: PropTypes.bool,
    displayName: PropTypes.string,
    email: PropTypes.string,
    handleSignOut: PropTypes.func,
    handleNavClick: PropTypes.func
}