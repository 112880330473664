import React, { PropTypes } from 'react';
import Face from 'material-ui/svg-icons/action/face';


export default class UserProfileBlock extends React.Component {
    render() {
        return (    
            <div className="user-details">
                <div className="pull-left">
                    <Face style={{width:'50px',height:'50px'}} />
                    
                </div>
                <div className="user-info">
                    <a href="#" style={{width:'100%',overflow:'hidden'}}>
                        {this.props.userName}
                    </a>
                    <p className="text-muted m-0">{this.props.organizationName}</p>
                    <p className="text-muted m-0">{this.props.userRole}</p>
                </div>
            </div>
        );
    }
}

UserProfileBlock.propTypes = {
    organizationName: PropTypes.string,
    userName: PropTypes.string,
    userRole: PropTypes.string
}
