import React from 'react';

import Drawer from 'material-ui/Drawer';
import IconButton from 'material-ui/IconButton';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import {List} from 'material-ui/List';
import Divider from 'material-ui/Divider';


import ColumnRow from '../userfiles/columnRow';

export default class FileDetailsDrawer extends React.Component {

  constructor(props) {
    super(props);
  }
 

  get columnList() {
    if(this.props.file == null) {
        return null;
    }

    var listItems = [], currItem, sampleData;
    let columns = this.props.file.columns;
    for(var columnId in columns) {
        currItem = columns[columnId];
        var listItem = <ColumnRow
            key={currItem.columnId}
            column={currItem}
            fileId={this.props.file.fileId}
        />;
        listItems.push(listItem);
    } 
    const colList = 
            <List>
                <Divider />
                {listItems}
            </List>;  
    return colList;
  }


  render() {  

    const fileDrawer =
           <Drawer open={this.props.open} width={305} >        
                <div className="container">                
                    <div className="row">
                        <div className="col-sm-12">
                            <h5 style={{display:'inline-block',marginTop:'17px'}}>File Details</h5>
                            <div className="pull-right" > 
                                <IconButton>                   
                                    <NavigationClose 
                                        onClick={this.props.handleClose}
                                    />              
                                </IconButton>                   
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {this.columnList}
                        </div>
                    </div>
                </div>                        
           </Drawer>; 
    return fileDrawer;
  }
}
  FileDetailsDrawer.propTypes = {
    file: React.PropTypes.object,
    open: React.PropTypes.bool,
    handleClose: React.PropTypes.func
  }