/**
 * User File Services 
 * Upload Excel Files
 * Retrive a list of files
 * ...
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import request from 'superagent';
import config from '../../../config';

//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();




export function setDeleteReportDialog(open) {
    var type = (open) ? actionTypes.OPEN_DELETE_REPORT_DIALOG : actionTypes.CLOSE_DELETE_REPORT_DIALOG;
    return {
        type: type
    };
}

export function deleteReport(organizationId, reportId) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.REPORT_DELETE_REQUEST
    });
    
    db.userToken()
    .then(function(token) {   
        sendDeleteReportRequest(token, organizationId, reportId)
        .then(function(result) {
            dispatch({
                type: actionTypes.REPORT_DELETE_RESPONSE,
                payload: result
            });   
            // Refresh report list
            dispatch(requestReportList(organizationId));
        })
        .catch(function(err) {
            console.log('Error:', err);
            dispatch({
                type: actionTypes.REPORT_DELETE_ERROR,
                payload: err
            });
            dispatch(requestReportList(organizationId));
        });
    })
    .catch(function(err) {
        console.log('Error:', err);
        dispatch({
            type: actionTypes.REPORT_DELETE_ERROR,
            payload: err
        });        
    });
  };    
}

export function downloadReportFile(organizationId, reportId) {
  return function(dispatch) {
    dispatch({type: actionTypes.REPORT_DOWNLOAD_REQUEST});    
    let endpoint = `admin/organizations/${organizationId}/reports/${reportId}`;
    let requestEndPoint = `${config.tappedinApi.host}/${endpoint}`;         
    db.userToken()
    .then(function(token) {
        var payload = {
            token: token, 
            downloadLink: requestEndPoint
        };        
        dispatch({
            type: actionTypes.REPORT_DOWNLOAD_DETAILS,
            payload: payload
        });
    })
    .catch(function(err) {
        dispatch({
            type: actionTypes.REPORT_DOWNLOAD_REQUEST_ERROR,
            payload: err
        });        
    });    
  };    
}

export function requestReportList(organizationId) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.REPORT_LIST_REQUEST
    });
    var node = `/tappedin/${organizationId}/admin/reports`;
    db.getValue(node)
    .then(function(reportSnapshot) {            
        addUserToReportSnapshot(reportSnapshot, organizationId)
        .then(function(resportSnapshot) {
            dispatch({
                type: actionTypes.REPORT_LIST_RESPONSE,
                payload: resportSnapshot
            });     
        })
        .catch(function(err) {
            console.log('Error:', err);
            dispatch({
                type: actionTypes.REPORT_LIST_ERROR,
                payload: err
            });
        });
    })
    .catch(function(err) {
        console.log('Error:', err);
        dispatch({
            type: actionTypes.REPORT_LIST_ERROR,
            payload: err
        });        
    });
  };
}

// Enrich the report List with User Names 
// This is when i miss sql
function addUserToReportSnapshot(reportSnapshot, organizationId) {    
    return new Promise(function(resolve, reject) {
        var baseNode = `/organizations/${organizationId}/users`;
        var seenUsers = {};
        var userRequest = [];
        for(let reportId in reportSnapshot) {
            let report = reportSnapshot[reportId];
            if(report['createdBy']) { 
                let createdBy = report['createdBy'];
                if(seenUsers[createdBy]) {
                    continue;
                }
                let node = `${baseNode}/${report['createdBy']}`;
                userRequest.push(db.getValue(node));
                seenUsers[createdBy] = true;
            }
        }
        Promise.all(userRequest)
        .then(function(userArray) {                       
            var userMap = {};
            for(let user of userArray) {
                userMap[user.id] = user;
            }
            for(let reportId in reportSnapshot) {
                var report = reportSnapshot[reportId];                
                if(report['createdBy'] && userMap[report.createdBy]) {
                    let currUser = userMap[report.createdBy];
                    report['createdByName'] = getDisplayNameFromUser(currUser);
                }
            }
            console.log('enriched snap: ', reportSnapshot);
            resolve(reportSnapshot);
        })
        .catch(function(err) {
            reject(err);
        });        
    });
}

function getDisplayNameFromUser(user) {
    if(user['firstName'] || user['lastName']) {
        return `${user['firstName'] || ''} ${user['lastName'] || ''}`;
    }
    return (user['email']) ? user['email'] : 'Unknown User';
}


export function createGuestListReport(organizationId, eventId) {
    return function(dispatch) {
        dispatch({type: actionTypes.REPORT_REQUEST});

        db.userToken()
        .then(function(token) {
            sendCreateReportRequest(token, organizationId, eventId)
            .then(function(result) {
                dispatch({
                    type: actionTypes.REPORT_COMPLETE,
                    payload: result
                });  
            })
            .catch(function(err) {
                console.log(err);
                dispatch({
                    type: actionTypes.REPORT_ERROR,
                    payload: err
                });            
            });
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.REPORT_ERROR,
                payload: err
            });            
        });
    };    
}

export function clearReportErrors() {
    return { type: actionTypes.REPORT_CLEAR_ERRORS }
}


// FileIds is an array of Ids
function sendCreateReportRequest(token, organizationId, eventId) {      
    var params = {"organizationId": organizationId, "eventId": eventId };
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${organizationId}/reports`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;        
        request
        .post(request_endpoint)
        .send(params)
        .set('Authorization','Bearer ' + token)   
        .end(function(err, res){            
            if (err || !res.ok) { 
                var msg = `Failed to Send Report. ${res.text}`;               
                reject(msg);
            } else {
                resolve(res.body);
            }
        });
    });    
}

function sendDeleteReportRequest(token, organizationId, reportId) {      
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${organizationId}/reports/${reportId}`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;        
        request
        .delete(request_endpoint)        
        .set('Authorization','Bearer ' + token)   
        .end(function(err, res){            
            if (err || !res.ok) { 
                var msg = `Failed to Send Report. ${res.text}`;               
                reject(msg);
            } else {
                resolve(res.body);
            }
        });
    });    
}
