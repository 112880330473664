import { combineReducers } from 'redux';
import AuthReducer from './authReducer';
import EventTemplateReducer from './eventTemplateReducer';
import UserFileReducer from './userFilesReducer';
import EventReducer from './eventReducer';
import ReportReducer from './reportReducer';
import UserManagementReducer from './userManagementReducer';

/**
 * Root Reducer : The keys are the differnt keys to the state and the reducers
 * return the state for that key
 */
const rootReducer = combineReducers({
  auth: AuthReducer,
  eventtemplate: EventTemplateReducer,
  userFiles: UserFileReducer,
  events: EventReducer,
  reports: ReportReducer,
  userManagement: UserManagementReducer
});

export default rootReducer;
