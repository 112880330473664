import React, {PropTypes} from 'react';
import { Router } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

import FileUploadDialog from '../components/fileUpload/fileUploadDialog';
import UploadMenu from '../components/fileUpload/uploadMenu';
import DropTarget from '../components/fileUpload/dropTarget';

import SalesforceContainer from './Salesforce';

import FileListContainer from './FileListContainer';

const customContentStyle = {
  width: '80%',
  maxWidth:'none'
};


/**
 * FileSelectionContainer
 * Allows a User to Select a File or Upload a New File
 * Main Container : with option to Upload File / Show File List
 */



class FileSelectionContainer extends React.Component {

  constructor() {
    super();
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleFileSelection = this.handleFileSelection.bind(this);
    this.getContainerContent = this.getContainerContent.bind(this);

    this.state = { menuSelectedItem: "upload" };
  }


  handleFileSelection(file) {
      this.props.actions.addFiletoTemplate(file, this.props.eventTemplate);
  }

  handleFileUpload(files) {
      console.log('Handle File Upload: Add to Template:',this.props.eventTemplate);
    this.props.actions.uploadFile(this.props.organizationId,files, this.props.eventTemplate);
  }

  handleMenuSelect(event, menuItem, index) {  
    let val = menuItem.props.value;
    this.setState({menuSelectedItem: val });
  }

  getContainerContent(item) {
    console.log(item);
    let containerContent = (this.state.menuSelectedItem == 'upload') ? this.uploadFileTarget : this.fileList;
    switch(item) {
        case "upload":
            return this.uploadFileTarget;
        break;
        case "file":
            return this.fileList;
        break;
        case "salesforce":            
            return <SalesforceContainer />
        break;
    }    
  }

  get uploadFileTarget() {
      return(
          <DropTarget 
              handleFileUpload={this.handleFileUpload}
              isUploadingFile={this.props.isUploadingFile}
              fileUploadError={this.props.fileUploadError}
          />
      );
  };

  get fileList() {
      return(
          <FileListContainer
                hideCheckBox={true}
                hideHeader={true}
                customFileSelectHandler={this.handleFileSelection}
          />
      )
  }

  get actions() {
    return [
      <FlatButton
        label="Cancel"
        primary={true}
        onTouchTap={this.props.handleClose}
      />
    ];      
  }

  render() {    
      let containerContent = this.getContainerContent(this.state.menuSelectedItem);    
      return (           
        <Dialog
            title="Select a File"            
            modal={true}
            open={this.props.dialogOpen}
            autoScrollBodyContent={true}
            bodyClassName="dialog-no-padding" 
            contentStyle={customContentStyle}   
            actions={this.actions}        
        >
            <div className="container" style={{paddingLeft:0}}>
                <div className="row">
                    <div className="col-sm-3">
                        <UploadMenu 
                            menuSelectedItem={this.state.menuSelectedItem}
                            handleMenuSelect={this.handleMenuSelect}
                        />
                    </div>
                    <div className="col-sm-9">                                            
                        {containerContent}
                    </div>                    
                </div>                
            </div>
        </Dialog>     
      );
  }
}


function mapStateToProps(state) {
  return {
    organizationId: state.auth.currentOrganization,
    fetchingFiles: state.userFiles.fetchingFiles, 
    fileList: state.userFiles.fileList,
    selectedFile: state.userFiles.selectedFile,
    organizationId: state.auth.currentOrganization,
    fileUploadError: state.userFiles.fileUploadError,
    uploadServerAvailable: state.userFiles.uploadServerAvailable,
    eventTemplate: state.eventtemplate,
    mappedFileId: state.eventtemplate.mappedFileId,
    uploadServiceChecked: state.userFiles.uploadServiceChecked,
    isUploadingFile: state.userFiles.isUploadingFile
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps,mapDispatchToProps)(FileSelectionContainer);

// handleClose
// dialogOpen from Props