import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';


import ActiveEventUsers from '../components/event/activeEventUsers';

export default class UserDeviceContainer extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var orgId = this.props.organizationId;   
    if(orgId) {
      this.props.actions.getOrganizationUsers(orgId);
    }     
  }

  componentWillReceiveProps(newProps) {
    // If the Organization Changes, refresh this list
    if(this.props.userId != newProps.userId) {
      var orgId = newProps.organizationId;
      this.props.actions.getOrganizationUsers(orgId);
    }
  }
 

  get deviceList() {
    return (
        <div>
          <div className="row">
            <div className="col-sm-12">                    
                <h4 style={{display:'inline-block'}}>Devices</h4>                   
              </div>
          </div>        
          
          <div className="row">
            <ActiveEventUsers 
              userList={this.props.activeEventUsers}
            />
          </div>

        </div>
    );
  }

  render() {
      return this.deviceList;
  }
}

function mapStateToProps(state) {
  return {    
    activeEventUsers: state.events.activeEventUsers,
    organizationId: state.auth.currentOrganization,
    userId: state.auth.userId    
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDeviceContainer));

