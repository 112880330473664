import React, { PropTypes } from 'react';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

const style = {
  display: 'inline-block',
  margin: '16px 32px 16px 0',
};


export default class UploadMenu extends React.Component {
  constructor(props) {
    super(props);    
  }

  render() {    
    return(<div>    
      <Menu 
        value={this.props.menuSelectedItem} 
        onItemTouchTap={this.props.handleMenuSelect}
        style={{minHeight:500, maxHeight:500}}
      >
        <MenuItem value={"upload"} primaryText="Upload File" />        
        <MenuItem value={"salesforce"} primaryText="Salesforce"  />
      </Menu>
      </div>);
  }
}

// <MenuItem value={"filelist"} primaryText="File List"  />

UploadMenu.propTypes = {    
    menuSelectedItem: PropTypes.string,
    handleMenuSelect: PropTypes.func
}