import React, { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';

import FlatButton from 'material-ui/FlatButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';

import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';
import DeleteConfirmModal from '../common/deleteConfirmModal';

import config from '../../../../config';

import moment from 'moment';



const tableStyle = {
  width: '100%'
};

const circularProgress = (
  <div>
    <h6>Retrieving Reports</h6>
    <CircularProgress size={40} thickness={3} />
  </div>
);


export default class ReportList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {          
      let displayedComponent = (this.props.fetchingReports) ? circularProgress : this.reportList ;
      return displayedComponent;   
  }


  get reportListError() {
      if(this.props.reportListError) { return null; }
      return(
        <div className="row">
        <div className="col-sm-12">
            <div className="alert alert-danger alert-white">
                {this.reportListError}
            </div>
        </div>
        </div>              
      );
  }

  get reportListEmptyState() {
    
      return(
        <div className="row">
        <div className="col-sm-12">
            <div className="alert alert-info alert-white">
                No Reports have been created. You can create one by selecting an Event from the main Events list and selecting "Create Guest Report"
            </div>
        </div>
        </div>              
      );    
  }

  get reportList() {
      
    var reports = this.props.reportList;
    var rowItems = [];    
    
    for(var reportId in reports) {  
        let report = reports[reportId];             
        var m = moment(report.createdAt);
        var createdFormat = m.format('MMM-D H:mm');  
        var rowItem = <TableRow key={reportId} >       
              <TableRowColumn> {report.reportName} </TableRowColumn>               
              <TableRowColumn style={{width:'170px'}}> {report.createdByName} </TableRowColumn>   
              <TableRowColumn style={{width:'130px'}}> {createdFormat} </TableRowColumn>   
              <TableRowColumn style={{textAlign:'right',width:'50px'}}> 
                <IconMenu
                  iconButtonElement={<IconButton><NavigationMoreVert /> </IconButton>}
                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                  targetOrigin={{horizontal: 'right', vertical: 'top'}}
                  onItemTouchTap={this.props.handleReportAction}
                >
                  <MenuItem primaryText="Download" value={reportId} />
                  <MenuItem primaryText="Delete" value={reportId} />                  
                </IconMenu>                
              </TableRowColumn>
            </TableRow>;
        rowItems.push(rowItem);            
    }

    var reportList =       
            <div className="container">

            <div className="row">
                <div className="col-sm-12">                    
                    <h4 style={{display:'inline-block'}}>Reports</h4>   
                    
                </div>
            </div>    

              <div className="row">
              <Table 
                style={tableStyle}
                fixedHeader={true}
                multiSelectable={false}  
                selectable={false}
              >
                  <TableHeader
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}                      
                  >
                    <TableRow>                      
                      <TableHeaderColumn>Report</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'170px'}}>Created By</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'130px'}}>Created At</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'50px'}}></TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody 
                      displayRowCheckbox={false}
                      selectable={false}
                      deselectOnClickaway={false}
                  >
                    {rowItems}
                  </TableBody>
              </Table>
              </div>     

              <div style={{marginTop:'5px'}}>
                {(rowItems.length == 0) ? this.reportListEmptyState : null}    
              </div>

            </div>;

      return reportList;    
    
  }
}
ReportList.propTypes = {
    fetchingReports: PropTypes.bool, 
    reportList: PropTypes.object,
    reportListError: PropTypes.string,
    handleReportAction: PropTypes.func
}
