import React, { PropTypes } from 'react';
import { browserHistory } from 'react-router';

import IconMenu from 'material-ui/IconMenu';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import MenuItem from 'material-ui/MenuItem';
import DropDownMenu from 'material-ui/DropDownMenu';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';


import {List, ListItem} from 'material-ui/List';

import ContentInbox from 'material-ui/svg-icons/content/inbox';
import ActionGrade from 'material-ui/svg-icons/action/grade';
import ContentSend from 'material-ui/svg-icons/content/send';
import ContentDrafts from 'material-ui/svg-icons/content/drafts';
import Divider from 'material-ui/Divider';
import ActionInfo from 'material-ui/svg-icons/action/info';

import FileFolder from 'material-ui/svg-icons/file/folder';
import SocialPerson from 'material-ui/svg-icons/social/person';
import ActionEvent from 'material-ui/svg-icons/action/event';
import ActionAssessment from 'material-ui/svg-icons/action/assessment';
import Devices from 'material-ui/svg-icons/device/devices';

import { USER_ROLE } from '../../constants/eventTypes';

import NavigationClose from 'material-ui/svg-icons/navigation/close';

import UserProfileBlock from './userProfileBlock';


export default class LeftNavBar extends React.Component {

    handleNavClick(page) {
        console.log('Handle Nav Click from Left');
        var navPage = `/${page}`;
        this.props.history.push(navPage);
    }

    render() {
        var organizationName = '';
        var userRole = 0;
        var userRoleName = '';
        var userName = this.props.email;

        for(var orgId in this.props.organizations) {                        
            if(orgId == this.props.currentOrganization) {
                var org = this.props.organizations[orgId];
                organizationName = org.organizationName;
                userRoleName = USER_ROLE[org.userRole];
                break;
            }
        }

        return (
            <aside className="sidebar-navigation">
                <div className="scrollbar-wrapper">
                    <div>
                        <button type="button" className="button-menu-mobile btn-mobile-view visible-xs visible-sm" style={{height:'25px'}}>
                            <NavigationClose />
                        </button>

                        <UserProfileBlock 
                            organizationName={organizationName}
                            userRole={userRoleName}
                            userName={userName}
                        />

                        <List>
                            <ListItem primaryText="Events" onClick={() => this.handleNavClick('events')} leftIcon={<ActionEvent />} />
                            <ListItem primaryText="Reports" onClick={() => this.handleNavClick('reports')}leftIcon={<ActionAssessment />} />
                            <ListItem primaryText="Files" onClick={() => this.handleNavClick('files')} leftIcon={<FileFolder />} />
                            <ListItem primaryText="Users" onClick={() => this.handleNavClick('users')} leftIcon={<SocialPerson />} />
                            <ListItem primaryText="Devices" onClick={() => this.handleNavClick('devices')} leftIcon={<Devices />} />
                            
                        </List>
                        
                    </div>
                </div>
            </aside>          
        );
    }

}
LeftNavBar.propTypes = {
    displayName: PropTypes.string,
    email: PropTypes.string,
    organizations: PropTypes.object,
    currentOrganization: PropTypes.string
}
