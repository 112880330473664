import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import CircularProgress from 'material-ui/CircularProgress';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import TextField from 'material-ui/TextField';

import TicketStatusSummary from '../components/event/ticketStatusSummary';
import AttributeTable from '../components/event/attributeTable';
import AttributeFilter from '../components/event/attributeFilter';

import FlatButton from 'material-ui/FlatButton';
import SocialPeople from 'material-ui/svg-icons/social/people';
import ActionSettings from 'material-ui/svg-icons/action/settings';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';

import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';


export default class EventContainer extends React.Component {

  constructor(props) {
    super(props);
    var self = this;

    // Bind methods to this class so "this" works as expected
    this.handleAttributeSelection = this.handleAttributeSelection.bind(this);
    this.handleArchiveEvent = this.handleArchiveEvent.bind(this);
    this.handleCancelArchive = this.handleCancelArchive.bind(this);
    this.handleOpenArchive = this.handleOpenArchive.bind(this);
    this.createGuestListReport = this.createGuestListReport.bind(this);
    this.eventSettingsClick = this.eventSettingsClick.bind(this);
    this.sendGuestListReport = this.sendGuestListReport.bind(this);
    this.handleCancelReport = this.handleCancelReport.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.handleEventNameClick = this.handleEventNameClick.bind(this);
    this.handleEventNameBlur = this.handleEventNameBlur.bind(this);
    this.handleEventNameChange = this.handleEventNameChange.bind(this);

    this.state = {
      reportModalOpen: false,
      eventNameIsActive: false
    };
  }

  componentDidMount() {
    if(this.props.hasOwnProperty('params') && this.props.params.hasOwnProperty('eventId')) {
      console.log('Load Event from Event Container. did Mount');
      var eventId = this.props.params.eventId;
      this.props.actions.selectEvent(this.props.organizationId,eventId);
    }
  }

  componentWillReceiveProps(newProps) {
    // If the Organization Changes, refresh this list
    if(this.props.organizationId != newProps.organizationId) {
      var orgId = newProps.organizationId;
      if(this.props.hasOwnProperty('params') && this.props.params.hasOwnProperty('eventId')) {
        var eventId = this.props.params.eventId;        
        this.props.actions.selectEvent(orgId,eventId);
      }
    }
  }   

  /**
   * Event Handlers
   */
  handleAttributeSelection(e,key,payload) {
    this.props.actions.filterAttributeList(payload);
  } 

  handleArchiveEvent() {
    this.props.actions.archiveEvent(this.props.organizationId,this.props.event.id);
  }

  handleOpenArchive() {
    this.props.actions.openArchiveDialog();
  }

  handleCancelArchive() {
    this.props.actions.closeArchiveDialog();
  }

  // Report Methods
  createGuestListReport() {
    this.setState({reportModalOpen:true});    
  }

  eventSettingsClick() {
    var eventConfigId = this.props.event.eventConfigId;
    if(eventConfigId) {
      var path = `/template/${eventConfigId}`;
      this.props.history.push(path);    
    }
  }

  sendGuestListReport() {
    this.props.actions.createGuestListReport(this.props.organizationId, this.props.event.id);    
    this.setState({reportModalOpen:false}); 
  }

  handleCancelReport() {
    this.setState({reportModalOpen:false});        
  }

  handleRequestClose() {
    this.props.actions.clearReportErrors();
  }

  get eventAvailableActions() {
      
    var settingsIcon = <ActionSettings />;
    var settingsLabel = "Settings";

    var guestListIcon = <SocialPeople />;
    var guestListLabel = "Create Guest Report";
    var guestListDisabled = (this.props.fetchingReport) ? true : false;    

    var settingsButton = null;
    // Some old events do not have a configId attached to them
    if (this.props.event.eventConfigId) {
      settingsButton = <FlatButton
                        label={settingsLabel}
                        labelPosition="before"
                        primary={true}   
                        icon={settingsIcon} 
                        onClick={this.eventSettingsClick}                         
                      />; 
    }

      return(
                <div className="pull-right">
                    <div className="pull-right" >                    
                      <FlatButton
                        label={guestListLabel}
                        labelPosition="before"
                        primary={true}   
                        disabled={guestListDisabled}     
                        icon={guestListIcon} 
                        onClick={this.createGuestListReport}                         
                      />    

                      {settingsButton}

                    </div>
                </div>
      );
  }

  get reportDialog() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={false}
        onTouchTap={this.handleCancelReport}
      />,
      <FlatButton
        label="Send"
        primary={true}        
        onTouchTap={this.sendGuestListReport}
      />,
    ];

    return (              
        <Dialog
          title="Send Guest Details Report"
          actions={actions}
          modal={true}
          open={this.state.reportModalOpen}
        >
        Send a guest details report to <strong>{this.props.email}</strong>
        </Dialog>
    );
  }

  get asyncErrorMessage() {
    return(
        <Snackbar
          open={(this.props.reportError) ? true : false}
          message={(this.props.reportError) ? this.props.reportError : ''}
          autoHideDuration={10000}
          onRequestClose={this.handleRequestClose}
        /> 
    );   
  }


/**
 * Render Methods
 */
  handleEventNameClick() {
    this.setState({eventNameIsActive: true});
  }

  handleEventNameBlur(e) {
    this.setState({eventNameIsActive: false});
    var eventName = e.target.value;
    var orgId = this.props.organizationId;
    var eventId = this.props.event.id;
    this.props.actions.saveSelectedEventName(orgId, eventId, eventName);    
  }

  handleEventNameKeyPress(e) {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  }

  handleEventNameChange(e) {
    console.log(e.target.value);
    this.props.actions.updateSelectedEventName(e.target.value);
  }

  get eventNameComponent() {
    var activeStyle = {
      fontSize: '1.2em'
    };
    var inactiveStyle = {
      fontSize: '1.2em',
      cursor:'pointer'
    };          
    var style = (this.state.eventNameIsActive) ? activeStyle : inactiveStyle;
    return (
          <div className="row">
              <div className="col-sm-12">
                  <TextField
                      underlineShow={this.state.eventNameIsActive}
                      hintText="Event Name"
                      value = {this.props.eventName}   
                      fullWidth={true}                   
                      style={style}
                      disabled={false}
                      inputStyle={style}
                      onFocus={this.handleEventNameClick}
                      onBlur={this.handleEventNameBlur}
                      onChange={this.handleEventNameChange}
                      onKeyPress={this.handleEventNameKeyPress}
                  />
              </div>
          </div>    
    );    
  }


  get eventSummary() {
      var dataIsUpdating = (this.props.isDataUpdating) ? 'loading' : 'hide';    
      return(
        <div>

          {this.reportDialog}
          {this.asyncErrorMessage}

          {this.eventNameComponent}

          <div className="row">
            <div className="col-sm-12">                    
                    {this.eventAvailableActions}
              </div>
          </div>

          <div className="row">
            <div className="col-sm-12">                    
              <div className="card-box widget-inline">
                  <div className="row">
  
                    <TicketStatusSummary 
                        statusCounts={this.props.statusCounts}
                        totalTickets={this.props.ticketCount}
                    />        

                  </div>
              </div>
            </div>

          <div className="row">
            <div className="col-sm-12">
              <h5>Event Attributes by Status</h5>
                        
              <AttributeFilter 
                attributes={this.props.event.attributes}
                value={this.props.attributeFilter}
                handleChange={this.handleAttributeSelection}            
              />
              <AttributeTable 
                attributeCounts={this.props.attributeCounts}
                attributeFilter={this.props.attributeFilter}
              />
            </div>
          </div>
        </div>
      </div>
        );
  }
  
  get pageLoadingContent() {
      return <div><CircularProgress /></div>;
  }

 
  render() {
      if(!this.props.event) {
          return this.pageLoadingContent;
      } else {
          return(
            <div>
               { this.eventSummary }  
            </div>
          );
      }     
  }
}

function mapStateToProps(state) {
  return {
    organizationId: state.auth.currentOrganization,
    email: state.auth.email,     
    event: state.events.selectedEvent,    
    eventName: state.events.selectedEventName,
    eventError: state.events.eventError,
    isLoading: state.events.eventIsLoading,
    isDataUpdating: state.events.eventDataIsUpdating, 
    statusCounts: state.events.statusCounts,
    ticketCount : state.events.ticketCount,
    attributeCounts: state.events.attributeCounts,
    attributeFilter: state.events.selectedAttributeFilter,
    archiveDialogOpen: state.events.archiveDialogOpen,
    archiveErrorMsg: state.events.archiveErrorMsg,
    archiveMsg: state.events.archiveMsg,

    fetchingReport: state.reports.fetchingReport, 
    reportError: state.reports.reportError 
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(EventContainer));