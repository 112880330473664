import React, { PropTypes } from 'react';



export default class AutoDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {fireDownload: false}
  }

  componentWillReceiveProps(nextProps) { 
    if(nextProps['downloadLink'] && nextProps['downloadLink'] != this.props.downloadLink && nextProps['downloadToken']) {        
        this.setState({fireDownload: true});    
    } else {
        this.setState({fireDownload: false});            
    }        
  }

  componentDidUpdate(prevProps, prevState) {
      if(prevState['fireDownload'] === false && this.state['fireDownload'] === true) {
        console.log('Fire that Download');
        // Sub that form!
        document.forms['autodownload'].submit();
      }
  }

  render() {          
      let displayedComponent = this.dlForm ;
      return displayedComponent;   
  }

  get dlForm() {
    var dlForm = <div>
        {this.formBody}
    </div>;
    return dlForm;
  }

  get formBody() {
      if(this.state.fireDownload) {
        return(<div><form name="autodownload" action={this.props.downloadLink} method="POST" target="_blank">
            <input type="hidden" name="sessionId" value={this.props.downloadToken} />            
        </form>
        </div>
        );
      }
      return null;
  }

}
AutoDownload.propTypes = {
    downloadLink: PropTypes.string, 
    downloadToken: PropTypes.string
}
