import React, { PropTypes } from 'react';

import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import FlatButton from 'material-ui/FlatButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

import { grey300, grey400 } from 'material-ui/styles/colors';

const styles = {
  upload: {
    width: '100%',
    border: 'none',
    height: '100%'
  }
}

/**
 * Icon Button Element and Menu
 * Display Actions on each event that is displayed
 */
const iconButtonElement = (
  <IconButton
    touch={true}
    tooltip="Actions"
    tooltipPosition="top-left"
  >
    <MoreVertIcon color={grey400} />
  </IconButton>
);

const rightIconMenu = (
  <IconMenu 
    iconButtonElement={iconButtonElement}
    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
    targetOrigin={{horizontal: 'right', vertical: 'top'}}
  >
    <MenuItem>Delete</MenuItem>
  </IconMenu>
);

/**
 * EventConfigList Class
 */
export default class EventConfigList extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
      return this.buildConfigList(this.props.list);
  }
 
  buildConfigList(list) {

    var listItems = [], currItem,countOfAttrs=0,secondaryText;
    
    for(var listId in list) {
        currItem = list[listId];
        countOfAttrs = (currItem.eventAttributes) ? currItem.eventAttributes.length : 0;   
        secondaryText = `${countOfAttrs} event attributes`;    
        var listItem = <ListItem
            key={listId}
            primaryText={currItem.eventName}
            secondaryText={secondaryText}
            onTouchTap={this.props.onItemClick.bind(this,listId)}
            rightIconButton={rightIconMenu}
        />;

        listItems.push(listItem);    
    }
    
    var configList = 
            <div>
              <List>
                <Subheader style={{padding:0, margin:0}}>
                  <FlatButton 
                    onClick={this.props.onAddEventClick}
                    label="Add New Event" 
                    labelPosition="before"
                    backgroundColor={grey300}
                    style={{margin:0, padding:0}}
                    icon={<ContentAdd />}
                   />
                </Subheader>
                <Divider />
                <Subheader>
                Draft Events
                </Subheader>
                
                <Divider />
                  {listItems}
              </List>
            </div>;
    return configList;
  }
  
}

EventConfigList.propTypes = {
    list: PropTypes.object,
    onItemClick: PropTypes.func,
    onAddEventClick: PropTypes.func
}