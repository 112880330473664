import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';


/**
 * A modal dialog can only be closed by selecting one of the actions.
 */
export default class DeleteConfirmModal extends React.Component {

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={false}
        onTouchTap={this.props.handleCancel}
      />,
      <FlatButton
        label="Delete"
        primary={true}        
        onTouchTap={this.props.handleDelete}
      />,
    ];

    return (              
        <Dialog
          title={this.props.title}
          actions={actions}
          modal={true}
          open={this.props.isOpen}
        >
        {this.props.message}
        </Dialog>
    );
  }
}

DeleteConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    handleDelete: PropTypes.func,
    handleCancel: PropTypes.func
}