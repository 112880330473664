import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import ReportList from '../components/reports/reportList';
import AutoDownload from '../components/reports/autoDownload';

import DeleteConfirmModal from '../components/common/deleteConfirmModal';

export default class ReportContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleReportAction = this.handleReportAction.bind(this);
    this.reportIdToDelete = null;
  }

  componentDidMount() {
    var orgId = this.props.organizationId;    
    this.props.actions.requestReportList(orgId);
    this.handleReportDelete = this.handleReportDelete.bind(this);
    this.handleReportDeleteCancel = this.handleReportDeleteCancel.bind(this);
  }


  handleReportDelete() {         
    this.props.actions.deleteReport(this.props.organizationId, this.reportIdToDelete);
    this.props.actions.setDeleteReportDialog(false); 
  }

  handleReportDeleteCancel() {
    this.reportIdToDelete = null;
    this.props.actions.setDeleteReportDialog(false);      
  }

  handleReportAction(event, menuItem, index) {
      switch(menuItem.props.primaryText) {
          case "Download":
            var reportId = menuItem.props.value;
            this.props.actions.downloadReportFile(this.props.organizationId, reportId);
          break;
          case "Delete":
            var reportId = menuItem.props.value;
            this.reportIdToDelete = reportId;
            this.props.actions.setDeleteReportDialog(true);            
          break;
          default:

      }
  }

  render() {
      return (
          <div>
            <ReportList 
                fetchingReports={this.props.fetchingReportList}
                reportList={this.props.reportList}
                reportListError={this.props.reportListError}
                handleReportAction={this.handleReportAction}
            />
            <AutoDownload 
                downloadLink={this.props.downloadLink}
                downloadToken={this.props.downloadToken}
            />

            <DeleteConfirmModal 
                title="Delete Report"
                message="Deleting a report cannot be undone"
                isOpen={this.props.deleteReportDialogOpen}
                handleDelete={this.handleReportDelete}
                handleCancel={this.handleReportDeleteCancel}
            />
          </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    reportList: state.reports.reportList,
    fetchingReportList: state.reports.fetchingReportList,
    reportListError: state.reports.reportListError,
    organizationId: state.auth.currentOrganization ,
    downloadLink: state.reports.downloadLink,
    downloadToken: state.reports.downloadToken,
    downloadLinkError: state.reports.downloadLinkError,
    deleteReportDialogOpen: state.reports.deleteReportDialogOpen, 
    isDeletingReport: state.reports.isDeletingReport, 
    deleteReportError: state.reports.deleteReportError
  };
}


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportContainer));

ReportContainer.propTypes = {
    hideCheckBox: PropTypes.bool,
    hideHeader: PropTypes.bool,
    customFileSelectHandler: PropTypes.func
}