import React, { PropTypes } from 'react';

import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import FlatButton from 'material-ui/FlatButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { grey300 } from 'material-ui/styles/colors';

import StatusCount from './statusCount';

/**
 * TicketStatusSummary 
 * Show the current count of tickets and status
 */
export default class TicketStatusSummary extends React.Component {
  constructor(props) {
    super(props);
  }
 
 get statusList() {
     var list = [];
     for(status in this.props.statusCounts) {
         var count = this.props.statusCounts[status];
         list.push(
            <StatusCount 
                title={status}
                key={status}
                value={count}
                total={this.props.totalTickets}
            />
         );
     }
     return list;
 }


    render() {
        return (
        <ul>
            {this.statusList.map(function(listValue){
            return listValue;
            })}
        </ul>
        );
    }

}

TicketStatusSummary.propTypes = {
    statusCounts: PropTypes.object,
    totalTickets: PropTypes.number
}