/**
 * Salesforce Related Actions
 */

import request from 'superagent';
import OAuth from 'forcejs/oauth';
import Service from "forcejs/data-service";

import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';

import { SalesforceOauthDetails } from '../../../../tappedin-api/dist/es6/schema/users';

import config from '../../../config';

//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();

export function sfdcFileUpload(organizationId, objectName, eventTemplate, campaign) {
    return function(dispatch) {      
        dispatch({
            type: actionTypes.SFDC_FILE_REQUEST
        });
        db.userToken()
        .then(function(token) {
            sendSFDCFileUpload(token, organizationId, objectName, campaign)
            .then(function(fileDescriptor) {    
                dispatch({type: actionTypes.FILE_UPLOAD_SUCCESS});
                if(eventTemplate) {                        
                    eventTemplate.dataSource = fileDescriptor.fileId;
                    // If the template does not yet have a name, pop in the file name 
                    if(!eventTemplate['selectedTemplate'].eventName || eventTemplate['selectedTemplate'].eventName.length == 0) {
                        eventTemplate['selectedTemplate'].eventName = fileDescriptor.originalFileName
                    }
                    var payload = {
                        template: eventTemplate['selectedTemplate'], 
                        file: fileDescriptor
                    };
                    dispatch({
                        type: actionTypes.FILE_ADDED_TO_EVENT_TEMPLATE,
                        payload: payload
                    }); 
                } else {
                    console.log('No Event Template');
                }
            })
            .catch(function(err) {
                dispatch({
                    type: actionTypes.SFDC_FILE_ERROR,
                    payload: err
                }); 
            });
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.SFDC_FILE_ERROR,
                payload: err
            }); 
        });      
    }    
}

export function getSalesforceCampaignList() {    
    return function(dispatch) {      
        dispatch({
            type: actionTypes.SFDC_CAMPAIGN_LIST_REQUEST
        });     
        db.userToken()
        .then(function(token) {
            sendCampaignListRequest(token)
            .then(function(records) {        
                dispatch({
                    type: actionTypes.SFDC_CAMPAIGN_LIST_RESPONSE,
                    payload: records
                }); 
            })
            .catch(function(err) {
                dispatch({
                    type: actionTypes.SFDC_CAMPAIGN_LIST_ERROR,
                    payload: err
                }); 
            });
        })
        .catch(function(err) {
            dispatch({
                type: actionTypes.SFDC_CAMPAIGN_LIST_ERROR,
                payload: err
            }); 
        });      
    }
}


export function watchSalesforceAuth(userId) {
  return function(dispatch) {
      dispatch(subscribeToPublishLog(userId));
  }
}

export function authWithSalesforce() {
    var connectedAppId = config.sfdcOAuth.connectedAppId;
    var loginUrl = config.sfdcOAuth.loginUrl;
    var callback = config.sfdcOAuth.callbackUrl;    
    let oauthUrl = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${connectedAppId}&redirect_uri=${callback}`; 
    window.open(oauthUrl, "_blank", "location=no");
    return { type: 'NOREALACTION' };
}

export function requestTokenFromCode(userId, code) {
    return function(dispatch) {     
        dispatch({
            type: actionTypes.SALESFORCE_AUTH_REQUESTED
        });
        db.userToken()        
        .then(function(token) {
            postAuthCodeForToken(token, code)
            .then(function(response) {
                console.log('Auth Code Token Resposne:: ', response);
                var accessToken = response['access_token'];
                var instanceUrl = response['instance_url'];
                var refreshToken = response['refresh_token'];
                var issuedAt = 0;
                // Save these values 
                saveSfdcUserInfo(userId, accessToken, instanceUrl, refreshToken, issuedAt)
                .then(function(tokenDetails) {
                    dispatch({
                        type: actionTypes.SALESFORCE_AUTH_SAVED,
                        payload: tokenDetails
                    });                
                })
                .catch(function(err) {
                    dispatch({
                        type: actionTypes.SALESFORCE_AUTH_SAVED_ERROR,
                        payload: err
                    });  
                });
            })
            .catch(function(err) {
                dispatch({
                    type: actionTypes.SALESFORCE_AUTH_SAVED_ERROR,
                    payload: err
                });  
            })
        })
        .catch(function(err) {
            console.log('Request DB TokenError:', err);
            dispatch({
                type: actionTypes.SALESFORCE_AUTH_SAVED_ERROR,
                payload: err
            });              
        });
    };    
}


function saveSfdcUserInfo(userId, accessToken, instanceUrl, refreshToken, issuedAt) {
    return new Promise(function(resolve,reject) {
        let sfdcDetails = new SalesforceOauthDetails(accessToken, refreshToken, instanceUrl, issuedAt);
        console.log('SFDC Detials',sfdcDetails);
        var node = `/users/${userId}/salesforceAuth`;
        db.set(node, sfdcDetails)
        .then(function(result) {
            resolve(sfdcDetails);
        })        
        .catch(function(err) {
            reject(err);
        });        
    });  
}


// users/{userId}/salesforceAuth
export function subscribeToSalesforceCredentials(userId) {
    var node = `/users/${userId}/salesforceAuth`;
    return function(dispatch) {
        db.subscriberToValue(node,function(err,snapshot) {
            console.log('SalesforceAuthNode has changed', snapshot);
            if(err) {
                console.log('Error in fetching log',err);              
            } else {
                dispatch({
                    type: actionTypes.SALESFORCE_AUTH_UPDATED,
                    payload: snapshot
                });
                dispatch(getSalesforceCampaignList());
            }
        });
    };    
}

export function unsubscribeFromSalesforceCredentials(userId) {
    console.log('Detaching from Salesforce Node');
    var node = `/users/${userId}/salesforceAuth`;
    db.detatchListeners(node);
    return { type: 'NOREALACTION' };
}

// REST Actions

function sendCampaignListRequest(token) {
    return new Promise(function(resolve,reject) {            
        let endpoint = `integrations/salesforce/campaigns`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .get(request_endpoint)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}

function sendSFDCFileUpload(token, organizationId, objectName, campaign) {
    return new Promise(function(resolve,reject) {            
        let endpoint = `integrations/salesforce/file`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        let params = {
            orgId: organizationId,
            objectName: objectName
        };
        if(campaign) {
            params['campaignId'] = campaign;
        }
        request        
        .post(request_endpoint)
        .send(params)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}

function postAuthCodeForToken(token, code) {    
    return new Promise(function(resolve,reject) {  
        let endpoint = `integrations/salesforce/token`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;                  
        let params = {
            code: code
        };
        request        
        .post(request_endpoint)
        .send(params)
        .set('Authorization','Bearer ' + token)
        .end(function(err, res){            
            if(err) {
                if(res && res.body && res.body.hasOwnProperty('message')) {
                    reject(res.body['message']);
                } else {
                    reject(err);
                }                
            } else {
                resolve(res.body);
            }
        });        
    });
}


