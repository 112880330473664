/**
 * Constant Status Types for Events
 */

//These must match what the Schema validates
export const EVENT_ATTRIBUTE_TYPES = {
    Text: "Text"
    // LongText: "Long Text",
    // Checkbox: "Checkbox",
    // SingleSelect: "Single Select"
};


/**
 * Ticket Status Types
 */

export const EVENT_TICKET_STATUS = {
    Pending: "Pending",
    CheckedIn: "CheckedIn",
    Cancelled: "Cancelled",
    AttendeeNoShow: "AttendeeNoShow",
    Invalid: "Invalid"
};

export const USER_ROLE = [
    'Unknown',
    'Administrator',
    'User',
];
    






