/**
 * Deployment Keys : ETC for Firebase Services 
 */


//  firebase deploy --token "$FIREBASE_TOKEN"

module.exports = {
  firebase: {
      projectId: 'tappedin-schema-v1',
      CIKey: '1/0iIuh9hwXj3zYDaTdmAPf5UbCB7RrxtwnlfvZsNwYxY'
  },
  tappedinApi: {
    host: 'https://api.tappedinevents.com'
    //host: 'http://localhost:3005'
  },
  sfdcOAuth: {
    connectedAppId: '3MVG9CEn_O3jvv0yDnHQGdiZ7J0TtYZbppox_WYz63thfFvgVdrdJX4P2bVhN2M_K_aDNB6vVYQM_oJIZKR8l',
    clientSecret: '917401840248347155',
    loginUrl: 'https://login.salesforce.com',
    //callbackUrl: 'http://localhost:3000/oauth/sfdc'
    callbackUrl: 'https://www.tappedinevents.com/oauth/sfdc'
  }
};
