import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import RaisedButton from 'material-ui/RaisedButton';
import Chip from 'material-ui/Chip';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import CircularProgress from 'material-ui/CircularProgress';
import TextInput from '../components/createEvent/textInput';
import AttributeList from '../components/createEvent/attributeList';
import IconButton from 'material-ui/IconButton'; 

import SocialPeople from 'material-ui/svg-icons/social/people';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';
import ContentSave from 'material-ui/svg-icons/content/save';
import ContentClear from 'material-ui/svg-icons/content/clear';
import EditorPublish from 'material-ui/svg-icons/editor/publish';

import ConfirmDialog from '../components/confirmDialog';
import PublishEventContainer from './PublishEventContainer';
import FileSelectionContainer from './fileSelectionContainer';
import AttributeTableList from '../components/createEvent/attributeTableList';
import FileDetailsDrawer from '../components/createEvent/fileDetailsDrawer';

import DeleteConfirmModal from '../components/common/deleteConfirmModal';

export default class CreateEventContainer extends React.Component {

  constructor(props) {
    super(props);
    var self = this;

    this.state = {
        removeMappingDialogOpen: false
    };

    // Bind methods to this class so "this" works as expected
    this.handleEventNameChange = this.handleEventNameChange.bind(this);
    this.handleAddEventAttribute = this.handleAddEventAttribute.bind(this);
    this.handleRemoveEventAttribute = this.handleRemoveEventAttribute.bind(this);
    this.handleAddColumnMapping = this.handleAddColumnMapping.bind(this);
    this.handleOnAddAttributeWithMapping = this.handleOnAddAttributeWithMapping.bind(this);
    this.handleAttributeValueChange = this.handleAttributeValueChange.bind(this);
    this.handleSaveEvent = this.handleSaveEvent.bind(this);
    this.handleRemoveFieldMappings = this.handleRemoveFieldMappings.bind(this);
    this.handleRemoveFieldMapping = this.handleRemoveFieldMapping.bind(this);
    this.handlePublishClick = this.handlePublishClick.bind(this);
    this.handleAttributeTypeChange = this.handleAttributeTypeChange.bind(this);
    this.handleCloseFileDetails = this.handleCloseFileDetails.bind(this);
    this.handleOpenFileDetails = this.handleOpenFileDetails.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleOpenFileSelection = this.handleOpenFileSelection.bind(this);
    this.handleCloseFileSelection = this.handleCloseFileSelection.bind(this);

    this.handleCloseRemoveFileMappingDialog = this.handleCloseRemoveFileMappingDialog.bind(this);
    this.handleRemoveFileMapping = this.handleRemoveFileMapping.bind(this);
    

  }

  componentDidMount() {
    if(this.props.organizationId) {
        var orgId = this.props.organizationId;
        if(this.props.hasOwnProperty('params') && this.props.params.hasOwnProperty('eventConfigId')) {
            var templateId = this.props.params.eventConfigId;
            this.props.actions.selectEventTemplate(orgId, templateId)
        }   
    }

    this.props.router.setRouteLeaveHook(this.props.route, () => {  
        if(this.props.publishEventResponse === null) {
            return 'Leave this Page? You will lose this event configuration if you do.';
        }        
    });
  }

  componentWillReceiveProps(newProps) {
    // If the Organization Changes, refresh this list
    if(this.props.organizationId != newProps.organizationId) {
      var orgId = newProps.organizationId;
        if(this.props.hasOwnProperty('params') && this.props.params.hasOwnProperty('eventConfigId')) {
            var templateId = this.props.params.eventConfigId;
            this.props.actions.selectEventTemplate(orgId, templateId)
        }
    }
  }  

  //This component can load with a locaiton that reps the ConfigId

  handlePublishClick() {
    // Validate Event 
     // Publish action is actually Save > Publish 
     this.props.actions.saveEventTemplate(this.props.organizationId, this.props.event);               
  }

/**
 * Event Handlers
 */
  handleSaveEvent() {
      //Validate Event Values
      this.props.actions.saveEventTemplate(this.props.organizationId, this.props.event);
  }

  handleEventNameChange(e) {
      this.props.event.eventName = e.target.value;
      this.props.actions.updateEventTemplate(this.props.event);
  }
  
  handleAddEventAttribute(e) {
      this.props.actions.addAttributeToEventTemplate(this.props.event);
  }
  
  handleRemoveEventAttribute(attribute) {
      //Remove Attribute Item
      var attrPosition = this.props.event.eventAttributes.indexOf(attribute);
      if(attrPosition > -1) {
          this.props.event.eventAttributes.splice(attrPosition,1);
          var i = 1;
          this.props.event.eventAttributes.forEach(function(attr,index,arr) { arr[index].order = i; i = i + 1; });
          this.props.actions.updateEventTemplate(this.props.event);
      } 
  }
  
  handleAttributeValueChange(e,attribute) {
      attribute.attributeName = e.target.value;
      this.props.actions.updateEventTemplate(this.props.event);
  }

  handleAttributeTypeChange(attribute,value) {
      attribute.attributeType = value;
      this.props.actions.updateEventTemplate(this.props.event);      
  }
  
  handleAddColumnMapping(column,attribute) {
        this.props.actions.addColumnMapping(column,attribute,this.props.event);
  }

  // When a column is dropped on a generic drop area 
  // Add a new attribute and map to this column
  handleOnAddAttributeWithMapping(column) {
        this.props.actions.addAttributeWithColumnMapping(column,this.props.event);
  }


  handleRemoveFieldMappings() {
      var template = this.props.event;
      var attributes = template.eventAttributes.concat(template.personAttributes);
      for( var attr of attributes) {
          attr.mappedColumn = null;
      }
      template.dataSource = null;
      this.props.actions.clearMappingErrors();
      this.props.actions.updateEventTemplate(this.props.event);
  }

  handleRemoveFieldMapping(attribute) {
      var template = this.props.event;
      var attributes = template.eventAttributes.concat(template.personAttributes);
      for( var attr of attributes) {
          if(attr.attributeId == attribute.attributeId) {
            attr.mappedColumn = null;
            this.props.actions.updateEventTemplate(this.props.event);
            break;
          }          
      }      
  }


  handleOpenFileDetails() {
      this.props.actions.openFileDetailsDrawer();
  }
  handleRemoveFile() {
      // We need to Dialog Confirm this because it will remove any mapped columns
      this.setState({removeMappingDialogOpen: true});
  }

  handleCloseFileDetails() {
      this.props.actions.closeFileDetailsDrawer();
  }
  handleOpenFileSelection() {
      this.props.actions.openFileSelectionDialog();
  }
  handleCloseFileSelection() {
      this.props.actions.closeFileSelectionDialog();
  }

  handleRemoveFileMapping() {
      var template = this.props.event;
      this.props.actions.removeAllColumnMappings(template);
      this.setState({removeMappingDialogOpen: false});
  }

  handleCloseRemoveFileMappingDialog() {
    this.setState({removeMappingDialogOpen: false});
  }


/**
 * Render Methods
 */
  
  get eventAvailableActions() {
      
    var guestListIcon = <SocialPeople />;
    var guestListLabel = "Add Guest List";
    var guestListDisabled = false;    
      if(this.props.selectedFile != null) {
        var guestListIcon = <NavigationCheck />;
        var guestListLabel = "Guest List Added";
        var guestListDisabled = true;
      }

    var publishLabel = "Publish";
    if(this.props.event && this.props.event.status == 'LIVE') {
        publishLabel = 'Re-Publish';
    }
    
      return(
                <div className="pull-right">
                    <div className="pull-right" >                    
                      <FlatButton
                        label={guestListLabel}
                        labelPosition="before"
                        primary={!(guestListDisabled)}   
                        disabled={guestListDisabled}     
                        icon={guestListIcon}  
                        onClick={this.handleOpenFileSelection}                      
                      />                      
                    </div>
                    <div className="pull-right">
                      <FlatButton
                        label={publishLabel}
                        labelPosition="before"
                        primary={false}      
                        onClick={this.handlePublishClick}  
                        icon={<EditorPublish />}                        
                      />
                    </div>
                </div>
      );
  }

  get selectedFileRow() {
      if(this.props.selectedFile == null) {
          return null
      } 
      let row = 
        <div className="row">
            <div className="col-sm-12"> 
                    <Chip
                    onRequestDelete={this.handleRemoveFile}
                    onTouchTap={this.handleOpenFileDetails}
                    >
                        {this.props.selectedFile['originalFileName']}
                    </Chip>
            </div>
        </div>
      ;
      return row;      
  }

  get generalErrorMessage() {
      if(this.props.templateRetrievalError == null) {
          return null;
      }
      let row = 
        <div className="row">
            <div className="col-sm-12"> 
                <div className="alert alert-danger alert-white">
                    {this.props.templateRetrievalError}
                </div>
            </div>
        </div>
      ;
      return row;        
  }

  get generalWarningMessage() {   
      if(this.props.event && this.props.event.status != 'LIVE') {
          return null;
      }
      let row = 
        <div className="row">
            <div className="col-sm-12"> 
                <div className="alert alert-warning alert-white">
                    This Event is Live. Re-Publishing will reset your event to the factory default
                </div>
            </div>
        </div>
      ;
      return row;              
  }

  get validationErrors() {
      if(this.props.validationError == null) {
          return null
      } 
      let row = 
        <div className="row">
            <div className="col-sm-12"> 
                <div className="alert alert-danger alert-white">
                    {this.props.validationError}
                </div>
            </div>
        </div>
      ;
      return row;    
  }

  get eventConfigContent() {
      var title = (this.props.event && this.props.event.status == 'LIVE') ? 'Modify Event' : 'Create an Event';
      return(
        <div>
              <div className="row">
                <div className="col-sm-12">                    
                    <h4 style={{display:'inline-block'}}>{title}</h4>
                        {this.eventAvailableActions}
                  </div>
              </div>

        {this.selectedFileRow}

        {this.validationErrors}

        <div className="row">
            <div className="col-sm-12">
                <TextInput
                    hintText="Event Name"
                    value = {this.props.event.eventName}
                    onChange={this.handleEventNameChange}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-sm-12"> 
                <br />
            </div>
        </div>

        <AttributeTableList 
            title="Attendee Attributes"
            attributeList={this.props.personAttributeList}
            onAddMapping={this.handleAddColumnMapping}
            onValueChange={this.handleAttributeValueChange}
            onRemoveMapping={this.handleRemoveFieldMapping}
            includeDropArea={false}
        />   

        <div className="row">
            <div className="col-sm-12"> 
                <br />
            </div>
        </div>

        <AttributeTableList 
            title="Event Attributes"
            message="The first two attributes are searchable fields"
            attributeList={this.props.eventAttributeList}
            onAddItem={this.handleAddEventAttribute}
            onRemoveItem={this.handleRemoveEventAttribute}
            onAddMapping={this.handleAddColumnMapping}
            onValueChange={this.handleAttributeValueChange}
            onAttributeTypeChange={this.handleAttributeTypeChange}
            onRemoveMapping={this.handleRemoveFieldMapping}
            onAddAttributeWithMapping={this.handleOnAddAttributeWithMapping}
            includeDropArea={( (this.props.selectedFile) ? true : false)}
        />

        <DeleteConfirmModal 
            title="Remove Guest List"
            message="Removing the guest list will remove all attributes that are mapped to this column."
            isOpen={this.state.removeMappingDialogOpen}
            handleDelete={this.handleRemoveFileMapping}
            handleCancel={this.handleCloseRemoveFileMappingDialog}
        />

    </div>);
  }
  
  get pageLoadingContent() {
      return <div>
            {this.generalErrorMessage} 
            <CircularProgress />
        </div>;
  }

  get confirmMappingDialog() {
      return(
        <ConfirmDialog 
            title="Error Mapping Field"
            confirmText="Remove Existing Field Mappings"
            onConfirm={this.handleRemoveFieldMappings}
            onClose={this.props.actions.clearMappingErrors}
            messageText={this.props.mappingErrorMessage}
            open={(this.props.mappingErrorMessage === null) ? false : true}       
        />
      );
  }

  get confirmPublishDialog() {
      return(
        <PublishEventContainer 
            dialogOpen={ this.props.publishDialogOpen }
        />
      );
  }

  get addFileDialog() {      
      return(
          <FileSelectionContainer
                dialogOpen={this.props.fileSelectDialogOpen}
                handleClose={this.handleCloseFileSelection}
          />
      );
  }

  render() {
      if(!this.props.event) {
          return this.pageLoadingContent;
      } else {
          return(
            <div>
                {this.generalWarningMessage}
                {this.eventConfigContent}
                <div>{this.props.saveError}</div>
                {this.confirmMappingDialog}
                {this.confirmPublishDialog}
                {this.addFileDialog}
                <FileDetailsDrawer 
                    open={this.props.fileDetailsOpen}
                    file={this.props.selectedFile}
                    handleClose={this.handleCloseFileDetails}
                />
            </div>  
          );
      }     
  }
}

function mapStateToProps(state) {
  return {
    organizationId: state.auth.currentOrganization,
    event: state.eventtemplate.selectedTemplate,
    saveError: state.eventtemplate.remoteError,
    templateRetrievalError: state.eventtemplate.templateRetrievalError,
    eventAttributeList: state.eventtemplate.eventAttributeList,
    personAttributeList: state.eventtemplate.personAttributeList,
    mappingErrorMessage: state.eventtemplate.mappingErrorMessage,
    savingEvent: state.eventtemplate.savingEvent,
    mappedFileId: state.eventtemplate.mappedFileId,
    canPublish: state.eventtemplate.canPublish,
    publishDialogOpen: state.eventtemplate.publishDialogOpen,
    mapEventDialogOpen: state.eventtemplate.mapEventDialogOpen,
    selectedFile: state.eventtemplate.selectedFile,
    fileDetailsOpen: state.eventtemplate.fileDetailsOpen,
    fileSelectDialogOpen: state.eventtemplate.fileSelectDialogOpen,
    validationError: state.eventtemplate.validationError,
    publishEventResponse: state.eventtemplate.publishEventResponse  
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateEventContainer));