/**
 * User Authentication Actions
 * When a user changes login status we need to route to 
 * either the login page or the home page. This has to happen after the action 
 * has been dispatched. How??
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import { browserHistory } from 'react-router';


//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();

export function signInUser(email,password) {
  return function(dispatch) {    
    dispatch({
      type: actionTypes.SIGN_IN_USER_REQUEST
    });
    db.signIn(email,password)
    .then(function(user) {
      console.log('User Is In', user);
      db.getFullUserRecord(user)
      .then(function(userRecord) {
        var orgs = (userRecord.hasOwnProperty('organizations')) ? userRecord.organizations : null;                
        var sfdcCredentials = (userRecord.hasOwnProperty('salesforceAuth')) ? userRecord.salesforceAuth : null;
        user['organizations'] = orgs;     
        user['salesforceAuth'] = sfdcCredentials;

        dispatch({
          type: actionTypes.SIGN_IN_USER,
          payload: buildUserPayload(user)
        });
      })
      .catch(function(err) {
          console.log('Org Error',err);
          dispatch(authError(err));
      });
    })
    .catch(function(err) {
      dispatch(authError(err));
    });
  };
}

/**
 * if we have a user already authed, validate this user
 */
export function validateUser() {
  var user = db.currentUser();
  if(user === null) {
    console.log('Null User : Return some empty action');
    return {
      type: 'DO_NOTHING'
    };
  }
    return function(dispatch) {
      console.log('dispatch request actions');
      dispatch({
        type: actionTypes.SIGN_IN_USER_REQUEST
      });

      db.getUserOrgList(user)
      .then(function(orgs) {
        console.log('have results');
        user['organizations'] = orgs;     
        //Nav to Home Page 
        dispatch({
          type: actionTypes.SIGN_IN_USER,
          payload: buildUserPayload(user)
        });
      })
      .catch(function(err) {
        console.log('Auth Error from Validate',err);
          dispatch(authError(err));
      });
    };
}


export function signOutUser() {
  var self = this;
  return function(dispatch) {
    db.signOut()
    .then(function() {
      browserHistory.replace('/login');
      dispatch({
        type: actionTypes.SIGN_OUT_USER
      });
    })
    .catch(function(err) {
      console.log('Auth Error from Sign Out');
      dispatch(authError(err));
    });
  };
};

// TODO : Add query for user role
export function changeUserActiveOrganization(orgId) {
  return {
    type: actionTypes.CHANGE_ACTIVE_ORG, 
    payload: {
      organizationId: orgId
    }
  };
}

function authError(error) {
  return {
    type: actionTypes.AUTH_ERROR,
    payload: error
  }
};

/**
 * Payload Helpers
 */
export function buildUserPayload(user) {
  var payload = {
    userId: user.uid,
    organizations: user.organizations,
    currOrganizationId: getFirstOrganizationId(user.organizations),
    displayName: user.displayName,
    email: user.email,
    salesforceAuth: user.salesforceAuth
  };
  return payload;
}

function getFirstOrganizationId(orgs) {
  if(orgs && Object.keys(orgs).length > 0) {
    return Object.keys(orgs)[0];
  } 
  return null;
}

