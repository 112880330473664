
import React, { PropTypes } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';

export default class SalesforceAuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
        sfdcObjectValue: null,
        selectedCampaign: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCampaignChange = this.handleCampaignChange.bind(this);
    this.handleSalesforceSelection = this.handleSalesforceSelection.bind(this);
  }

  handleChange(event, index, value) {
      this.setState({sfdcObjectValue: value});
  }

  handleCampaignChange(event, index, value) {
      this.setState({selectedCampaign: value});
  }

  handleSalesforceSelection() {
      this.props.handleSalesforceSelection(this.state.sfdcObjectValue, this.state.selectedCampaign);
  }

  get currentCredentials() {      
      var info = <div style={{fontSize:'0.8em'}}>          
          <h4>Salesforce Connection Information</h4>    
          <div className="row">  
                <div className="col-sm-6">
                    <div><span>Salesforce Url</span> <span style={{color:'#d0d0d0'}}> {this.props.currentInstanceUrl}</span></div>                       
                    <div><span >Refresh Token</span> <span style={{color:'#d0d0d0'}}> {this.props.currentRefreshToken}</span></div>
                </div>        
                <div className="col-sm-6">
                    {this.authToSalesforce}
                </div>   
            </div>                 
          </div>;

      var noCredentials = <div style={{fontSize:'0.8em'}}>          
          <h4>Salesforce Connection Information</h4>    
          <div className="row">  
                <div className="col-sm-6">
                    Authenticate with Salesforce to create guest lists from Leads, Contacts or Campaing Members                    
                </div>        
                <div className="col-sm-6">
                    {this.authToSalesforce}
                </div>   
            </div>                 
          </div>;          

      return (this.props.hasSalesforceAuth) ? info : noCredentials;
  }

  get selectObjectMenu() {
      return (
        <SelectField
          floatingLabelText="Salesforce Records"
          value={this.state.sfdcObjectValue}
          onChange={this.handleChange}
        >
          <MenuItem value="Lead" primaryText="Leads" />
          <MenuItem value="Contact" primaryText="Contacts" />
          <MenuItem value="CampaignMember" primaryText="Campaign Members" />
        </SelectField>
      );
  }

  get confirmSelection() {      
      var disabled = (this.state.sfdcObjectValue == null || this.props.fetchingSfdcDetails) ? true : false;
      return <RaisedButton 
                label="Confirm Selection"                 
                primary={true} 
                disabled={disabled}
                onTouchTap={this.handleSalesforceSelection}
            />      
  }

  get campaignListMenu() {
      var menuItems = [];
      if(this.props.activeCampaigns) {
          var records = this.props.activeCampaigns.records;
          for(var record of records) {
              menuItems.push(<MenuItem key={record.Id} value={record.Id} primaryText={record.Name} />);              
          }
      }
      return (
        <SelectField
          floatingLabelText="Campaign"
          value={this.state.selectedCampaign}
          onChange={this.handleCampaignChange}
          autoWidth={true}
          disabled={this.props.fetchingCampaigns}
        >
          {menuItems}
        </SelectField>
      );
  }

  get authToSalesforce() {
      let label = (this.props.hasSalesforceAuth) ? "Update Salesforce Credentials" : "Authenticate with Salesforce";
      return <RaisedButton 
                label={label} 
                backgroundColor="#1798c1" 
                labelColor="#ffffff"                
                onTouchTap={this.props.handleAuthToSalesforce}
            />
  }

  get errorMessage() {
    if(this.props.sfdcObjectDetailsError) {
        return(
            <div className="alert alert-danger alert-white">
                {this.props.sfdcObjectDetailsError}
            </div>
        );
     }
     return null;
  }

  get progressIndicator() {
      if(this.props.fetchingSfdcDetails) {
        return (
            <CircularProgress />
        );
      } else {
          return null;
      }
  }
    
  render() {      
      return (
        <div>
            {this.currentCredentials} 
            <hr />            
            <div>
                {this.selectObjectMenu} <br />
                {this.campaignListMenu} <br /><br />
                {this.confirmSelection}  <br /> <br />
                {this.progressIndicator}  <br />
                {this.errorMessage}
                           
            </div>
        </div>
      );
  }
}

SalesforceAuthForm.propTypes = {    
    hasSalesforceAuth: PropTypes.bool,
    handleAuthToSalesforce: PropTypes.func,
    currentInstanceUrl: PropTypes.string,
    currentRefreshToken: PropTypes.string,
    handleSalesforceSelection: PropTypes.func,
    fetchingCampaigns: PropTypes.bool,
    activeCampaigns:PropTypes.object,
    fetchingSfdcDetails:PropTypes.bool,
    sfdcObjectDetailsError:PropTypes.string
}
