/**
 * Authentication related state reducers / handlers
 */

import { 
  SIGN_IN_USER, SIGN_OUT_USER, AUTH_ERROR,
  CHANGE_ACTIVE_ORG, SIGN_IN_USER_REQUEST,
  SALESFORCE_AUTH_UPDATED, SALESFORCE_AUTH_SAVED, SALESFORCE_AUTH_SAVED_ERROR, SALESFORCE_AUTH_REQUESTED, SALESFORCE_TOKEN_RETURNED
 } from '../constants/actionTypes';


const initialState = {
  fetchingLogin: false,
  userId: null,
  displayName: null, 
  email: null,
  organizations: null,  
  currentOrganization: null,
  authenticated: false,
  error: null,
  salesforceAuth: null,
  fetchingSalesforceAuth: false,
  salesforceAuthError: null,
  salesforceAuthToken: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN_USER_REQUEST:
     return mergeState(state, {
        fetchingLogin: true,
        error: null
      });
    case SIGN_IN_USER:
      var newState = handleSignInAction(state,action.payload);
      return newState;
    case SIGN_OUT_USER:
      return mergeState(state, initialState);
    case AUTH_ERROR:
      let message = (action.payload.hasOwnProperty('message')) ? action.payload.message : action.payload;
      return mergeState(state, {
        error: message,
        fetchingLogin: false
      });
    case CHANGE_ACTIVE_ORG:
      return mergeState(state, {
        currentOrganization: payload.organizationId
      });
    // SALESFORCE ACTION REDUCERS
    case SALESFORCE_AUTH_REQUESTED:
      return mergeState(state, {
        salesforceAuthToken: null,
        fetchingSalesforceAuth: true
      });
    case SALESFORCE_AUTH_UPDATED:
      return mergeState(state, {
        fetchingSalesforceAuth: false,
        salesforceAuth: action.payload
      });
    case SALESFORCE_AUTH_SAVED:
      return mergeState(state, {
        fetchingSalesforceAuth: false,
        salesforceAuth: action.payload
      });    
    case SALESFORCE_AUTH_SAVED_ERROR:
      return mergeState(state, {
        salesforceAuthError: action.payload,
        fetchingSalesforceAuth: false
      });
    case SALESFORCE_TOKEN_RETURNED:
      return mergeState(state, {
        salesforceAuthToken: action.payload,
        salesforceAuthError: null,
        fetchingSalesforceAuth: false
      });    
    default:
      return state;
  }
}

function handleSignInAction(state,payload) {

//Set currentOrganization to first org ID returned
  return mergeState(state, {
      fetchingLogin:false,
      userId: payload.userId,
      displayName: payload.displayName,
      email: payload.email,
      organizations: payload.organizations,
      currentOrganization: payload.currOrganizationId,
      salesforceAuth: payload.salesforceAuth,
      fetchingSalesforceAuth: false,
      authenticated: true,
      salesforceAuthToken: null,
      error: null
  });
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}

