import React, { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';

import FlatButton from 'material-ui/FlatButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';

import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';

import DeleteConfirmModal from '../common/deleteConfirmModal';


// should be in constants but import will not work for me today :(
export const USER_ROLE_NAMES = {
    1: 'Organization Admin',
    2: 'Basic User'
};


const tableStyle = {
  width: '100%'
};

const circularProgress = (
  <div>
    <h6>Retrieving Users</h6>
    <CircularProgress size={40} thickness={3} />
  </div>
);


/**
 * FileList Class
 */
export default class UserList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {          
      let displayedComponent = (this.props.fetchingUsers) ? circularProgress : this.userList ;
      return displayedComponent;   
  }

  get userList() {
    var users = this.props.users || [];
    var rowItems = [];    
    for(var user of users) {  
        let roleName = USER_ROLE_NAMES[user.role.userRole];  
        let deleteItem = (this.props.currentUserId != user.id) ? <MenuItem primaryText="Delete Account" value={user.id} /> : null ;      
        var rowItem = <TableRow key={user.id} >       
              <TableRowColumn> {user.email} </TableRowColumn>              
              <TableRowColumn> {roleName} </TableRowColumn>     
              <TableRowColumn style={{textAlign:'right',width:'60px'}}> 
                <IconMenu
                  iconButtonElement={<IconButton><NavigationMoreVert /> </IconButton>}
                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                  targetOrigin={{horizontal: 'right', vertical: 'top'}}
                  onItemTouchTap={this.props.handleMenuSelect}
                >
                  <MenuItem primaryText="Reset Password" value={user.email} />
                  <MenuItem primaryText="View Details" value={user.id} />                                    
                    {deleteItem}
                </IconMenu>                
              </TableRowColumn>
            </TableRow>;
        rowItems.push(rowItem);            
    }

    var userList =            
              <div className="row">
              <Table 
                style={tableStyle}
                fixedHeader={true}
                multiSelectable={false}  
                selectable={false}
              >
                  <TableHeader
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}                      
                  >
                    <TableRow>                      
                      <TableHeaderColumn>Email</TableHeaderColumn>
                      <TableHeaderColumn>Role</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'60px'}}></TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody 
                      displayRowCheckbox={false}
                      selectable={false}
                      deselectOnClickaway={false}
                  >
                    {rowItems}
                  </TableBody>
              </Table>
              </div>;
    return userList;
  }
}
UserList.propTypes = {
    fetchingUsers: PropTypes.bool, 
    users: PropTypes.array,
    userListError: PropTypes.string,
    handleMenuSelect: PropTypes.func
}
