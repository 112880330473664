import React, {PropTypes} from 'react';
import RaisedButton from 'material-ui/RaisedButton';


export default class Button extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    var props = {
        label: this.props.label,
        labelPosition: "before",
        disabled: (this.props.disabled) ? true : false,
        onClick: this.props.onClick
    };

    return (
        <RaisedButton {...props } />
    );
  }
}


Button.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
}