import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import LinearProgress from 'material-ui/LinearProgress';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import HardwarePhoneIPhone from 'material-ui/svg-icons/hardware/phone-iphone';
import {blue500, red500, green500} from 'material-ui/styles/colors';

const tblStyle = {
    'fontSize': '1.2em',
    'display': 'inline-block',
    'height': '20px'
};

const iconStyle = {
    'width':'20px',
    'height':'20px',
    'marginBottom':'-5px',
    'marginRight':'5px'
};

const successMsg = {
    'padding':'5px',
    'fontSize':'1.3em',
    'fontWeight':'bold'
};


/**
 * Confirm Event Publishing Dialog
 */
export default class PublishEventDialog extends React.Component {

    constructor() {
        super();
        this.handleNavToEvent = this.handleNavToEvent.bind(this);
    }    

    handleNavToEvent() {
        if(this.props.publishEventResponse != null) {        
            var resp = this.props.publishEventResponse;
            if(resp.hasOwnProperty('event')) {
                var event = resp['event'];
                var eventId = event['id'];
                if(eventId) {
                    this.props.handleNavigateToEvent(eventId);                    
                }
            }
        }
    }

    get progressIndicator() {
        if(this.props.isPublishing === false) {return null; }
        return(<div key="ind-0" className="dialog-publish-progress"><LinearProgress mode="indeterminate" /></div>);
    }

    get dialogActions() {
        const actions = [
        <FlatButton
            label="Cancel"
            primary={false}
            onClick={this.props.onCancel}
        />
        ];  

        if(this.props.publishEventResponse == null) {
            return actions;
        }
        return null;
        
    }

    get publishSuccessView() {
        // If we have a response, the creation of the event was successfull.
        if(this.props.publishEventResponse != null) {
            // Any Notable Things here:
            var resp = this.props.publishEventResponse;
            if(resp.hasOwnProperty('ticketLoadResult')) {
                var ticketResp = resp['ticketLoadResult'];
            }
            return(
                <div>
                    <h4 style={{marignTop:'50px'}}>Event Published</h4> 
                    <div>Your Event Has Been Published</div>

                    {this.logTable}

                    <div className="dialog-inline-action" style={{'textAlign':'center',padding:'10px'}}>
                        <RaisedButton
                            label="Go To Event"
                            primary={true}
                            onClick={this.handleNavToEvent}
                        />
                    </div>
                </div>
            );
        }
        return null;
    }

    get publishRequestView() {
        var title = (this.props.isRepublish) ? 'Re-Publish Event' : 'Publish Event';
        var label = (this.props.isRepublish) ? 'Re-Publish Event' : 'Publish Event';
        var publish_message_line1 = "Publishing an event will create the guest list (if you have added a file) and will make the event live."
        var publish_message_line2 = "Once you publish an event, you can not longer change the event attribute properties";
        
        var republish_message_line1 = "Re-Publishing an event will reset ALL EVENT DATA"
        var republish_message_line2 = "You will lose all CheckIn data and any other guest data that has been collected.";
        
        var line_1 = (this.props.isRepublish) ? republish_message_line1 : publish_message_line1;
        var line_2 = (this.props.isRepublish) ? republish_message_line2 : publish_message_line2;

        return (
            <div>
                <h4 style={{marignTop:'50px'}}>{title}</h4> 
                <div>{line_1}</div>
                <div>{line_2}</div>

                <div className="dialog-inline-action" style={{'textAlign':'center',padding:'10px'}}>
                    <RaisedButton
                        label={label}
                        primary={true}
                        onClick={this.props.onConfirm}
                        disabled={this.props.isPublishing}
                    />
                </div>

                {this.progressIndicator}
                {this.publishError}
                {this.logTable}    

            </div>
        );
    }

    get publishError() {
        if(this.props.publishError != null) {  
            return(
                <div className="alert alert-danger alert-white">
                    {this.props.publishError}
                </div>
            );
        }
        return null;
    }

  render() {   
        var title = (this.props.isRepublish) ? 'Re-Publish Event' : 'Publish Event';
        var dialogBody = (this.props.publishEventResponse != null) ? this.publishSuccessView : this.publishRequestView;
        return (         
            <Dialog
                title={title}
                actions={this.dialogActions}
                modal={true}
                open={this.props.open}
                autoScrollBodyContent={false}
            >
                {dialogBody}
            </Dialog>     
        );
    }

    get logTable() {
        let error = <AlertErrorOutline style={iconStyle} color={red500} />;
        let success = <ActionCheckCircle style={iconStyle} color={green500} />;

        var rows = [], currItem;
        for(var i = 0; i < this.props.publishLog.length; i++) {
            currItem = this.props.publishLog[i];

            let key = `pub-record-${i}`;
            rows.push(
                <TableRow key={key}>
                    <TableRowColumn>
                        {(currItem.severity == 1) ? error : success }
                        <span style={tblStyle}>{currItem.message}</span>
                    </TableRowColumn>
                </TableRow>
            );
        }
        return (
            <div key="ind-container-1" className="dialog-publish-log" style={{'overflow':'auto','maxHeight':'320px','marginBottom':'10px'}}>
                <Table>
                    <TableBody displayRowCheckbox={false}>
                        {rows}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

PublishEventDialog.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    publishLog: PropTypes.array,
    isPublishing: PropTypes.bool,
    isRepublish:  PropTypes.bool,
    publishError: PropTypes.string, 
    publishMessage: PropTypes.string,
    publishEventResponse: PropTypes.object,
    handleNavigateToEvent: PropTypes.func
}

