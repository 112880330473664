import React, { PropTypes } from 'react';

import LinearProgress from 'material-ui/LinearProgress';


/**
 * StatusCount 
 * Show the current count of tickets and status in a card/container
 */
export default class StatusCount extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
      return(
        <div className="col-lg-3 col-sm-6">
            <div className="widget-inline-box text-center" style={{paddingTop:'2px', paddingBottom:'2px'}}>
            <h3 className="m-t-10"><i className="text-primary"></i> 
            <b data-plugin="counterup">{this.props.value}</b></h3>
            <p className="text-muted">{this.props.title}</p>
            <LinearProgress 
                mode="determinate" 
                max={this.props.total}
                value={this.props.value}                
            />
            </div>
        </div>
      );
  } 
}

StatusCount.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    total: PropTypes.number
}