
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import config from '../../../config';

// Create a new instance to ref the Firebase DB
var db = new FirebaseDbService();

export function getOrganizationUsers(orgId) {  
console.log('Fetching Devices under ', orgId);
  var self = this;   
  return function(dispatch) {
     dispatch({
       type: actionTypes.LOADING_USER_DEVICES
     });
    //Subscriber to all things that I care about        
    dispatch(subscribeToEventUsers(orgId));    
  };      
}

// organizations/{orgId}/connections/{userId}/{deviceId}/
function subscribeToEventUsers(organizationId) {    
    var node = `/organizations/${organizationId}/connections`;
    return function(dispatch) {
        db.subscriberToChildAdded(node,function(err,user) {
            if(err) {
                console.log('Error in fetching event user added',err);              
            } else {
                dispatch({
                    type: actionTypes.EVENT_USER_UPDATED,
                    payload: user
                });
            }
        });
        // Subscribe to Child Changed
        db.subscribeToChildChanged(node,function(err,user) {
            if(err) {
                console.log('Error in fetching event user changed',err);              
            } else {
                dispatch({
                    type: actionTypes.EVENT_USER_UPDATED,
                    payload: user
                });
            }
        });
    };    
}