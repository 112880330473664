


import { 
    REPORT_REQUEST, REPORT_ERROR, REPORT_COMPLETE, REPORT_CLEAR_ERRORS ,
    REPORT_LIST_REQUEST, REPORT_LIST_RESPONSE, REPORT_LIST_ERROR,
    REPORT_DOWNLOAD_REQUEST, REPORT_DOWNLOAD_DETAILS, REPORT_DOWNLOAD_REQUEST_ERROR,
    OPEN_DELETE_REPORT_DIALOG, CLOSE_DELETE_REPORT_DIALOG, REPORT_DELETE_REQUEST, REPORT_DELETE_RESPONSE, REPORT_DELETE_ERROR        
} from '../constants/actionTypes';

const initialState = {
  reportList: {},
  fetchingReportList: false, 
  reportListError: null,
  fetchingReport: false,
  reportError: null,
  downloadLink: null ,
  downloadToken: null, 
  downloadLinkError: null,
  deleteReportDialogOpen: false, 
  isDeletingReport: false, 
  deleteReportError: null
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_REQUEST:
     return mergeState(state, {
        fetchingReport: true,
        reportError: null
      });
    case REPORT_ERROR:
     return mergeState(state, {
        fetchingReport: false,
        reportError: action.payload
      });
    case REPORT_COMPLETE:
     return mergeState(state, {
        fetchingReport: false,
        reportError: null
      });   
    case REPORT_CLEAR_ERRORS:
      return mergeState(state, {
        fetchingReport: false,
        reportError: null
      });  

    case REPORT_LIST_REQUEST:
      return mergeState(state, {
        fetchingReportList: true,
        reportListError: null 
      });  
    case REPORT_LIST_RESPONSE:
      return mergeState(state, {
        fetchingReportList: false,
        reportListError: null, 
        reportList: action.payload
      });  
    case REPORT_LIST_ERROR:
      return mergeState(state, {
        fetchingReportList: false,
        reportListError: action.payload
      });  
    case REPORT_DOWNLOAD_REQUEST:
      return mergeState(state, {
        downloadLink: null,
        downloadToken: null,
        downloadLinkError: null
      });             
    case REPORT_DOWNLOAD_DETAILS:
      return mergeState(state, {
        downloadLink: action.payload.downloadLink,
        downloadToken: action.payload.token,
        downloadLinkError: null
      });                
    case REPORT_DOWNLOAD_REQUEST_ERROR:
      return mergeState(state, {
        downloadLink: null,
        downloadToken: null,
        downloadLinkError: action.payload
      });          

    case OPEN_DELETE_REPORT_DIALOG:
      return mergeState(state, {
          deleteReportDialogOpen: true
      });   
    case CLOSE_DELETE_REPORT_DIALOG:
      return mergeState(state, {
          deleteReportDialogOpen: false
      });  
    case REPORT_DELETE_REQUEST:
      return mergeState(state, {        
        isDeletingReport: true, 
        deleteReportError: null
      });     
    case REPORT_DELETE_RESPONSE:
      return mergeState(state, {
        isDeletingReport: false, 
        deleteReportError: null
      });     
    case REPORT_DELETE_ERROR:
      return mergeState(state, {
        isDeletingReport: false, 
        deleteReportError: action.payload
      });                                
    default:
      return state;
  }
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}

