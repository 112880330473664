/**
 * Bundle All Actions into a common action extry point
 */
import { signInUser, signOutUser, validateUser, changeUserActiveOrganization } from './auth';
import { 
  refreshEventTemplates, createEventTemplate, selectEventTemplate, 
  updateEventTemplate, addAttributeToEventTemplate, updateTemplateEventName, 
  addColumnMapping, saveEventTemplate, clearMappingErrors, 
  addFiletoTemplate, openFileDetailsDrawer, closeFileDetailsDrawer, openFileSelectionDialog, closeFileSelectionDialog,
  removeAllColumnMappings, addAttributeWithColumnMapping
} from './eventTemplate';
import { requestFileList, uploadFile, selectUserFile, closeUserFile, checkUploadServerStatus, deleteFile } from './userFiles';

import { publishEvent, openPublishDialog, closePublishDialog } from './eventPublish';

import { 
  subscribeToTicketStatusChanges, refreshEventList, selectEvent, filterAttributeList, filterEventList, 
  archiveEvent, openArchiveDialog, closeArchiveDialog, updateSelectedEventName, saveSelectedEventName
} from './events';

import { getOrganizationUsers } from './userDeviceActions'

// Salesforce Actions
import {  
  authWithSalesforce,
  watchSalesforceAuth,
  saveSfdcUserInfo,
  subscribeToSalesforceCredentials,
  unsubscribeFromSalesforceCredentials,
  getSalesforceCampaignList,
  sfdcFileUpload,
  requestTokenFromCode
} from './salesforce';

import { createGuestListReport, clearReportErrors, requestReportList, downloadReportFile, setDeleteReportDialog, deleteReport } from './guestReports';

import { requestUserList, addUserToOrg, openUserDialog, removeUserFromOrg, openDeleteUserDialog, resetUserPassword, performPasswordReset, signUpUser } from './userManagement';

export {
  signInUser, 
  signOutUser,
  validateUser,
  changeUserActiveOrganization,
  refreshEventTemplates,
  createEventTemplate,
  saveEventTemplate,
  selectEventTemplate,
  updateEventTemplate,
  addAttributeToEventTemplate,
  updateTemplateEventName,
  addColumnMapping,
  removeAllColumnMappings,
  addAttributeWithColumnMapping,
  clearMappingErrors,
  requestFileList,
  uploadFile,
  deleteFile,

  selectUserFile,
  closeUserFile,
  checkUploadServerStatus,
  publishEvent,
  openPublishDialog,
  closePublishDialog,
  

  subscribeToTicketStatusChanges,
  refreshEventList,
  filterEventList,
  selectEvent,
  filterAttributeList,

  archiveEvent,
  openArchiveDialog,
  closeArchiveDialog,

  addFiletoTemplate,
  openFileDetailsDrawer,
  closeFileDetailsDrawer,
  openFileSelectionDialog,
  closeFileSelectionDialog,

  createGuestListReport,
  clearReportErrors,

  requestUserList,
  addUserToOrg,
  openUserDialog, 

  requestReportList,
  downloadReportFile,
  setDeleteReportDialog,
  deleteReport  ,

  removeUserFromOrg,
  openDeleteUserDialog,
  resetUserPassword,
  performPasswordReset,
  signUpUser,

  authWithSalesforce,
  watchSalesforceAuth,
  saveSfdcUserInfo,
  subscribeToSalesforceCredentials,
  unsubscribeFromSalesforceCredentials,
  getSalesforceCampaignList,
  sfdcFileUpload,
  requestTokenFromCode,
  updateSelectedEventName,
  saveSelectedEventName,

  getOrganizationUsers
};

