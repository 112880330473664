import React, {PropTypes} from 'react';
import { Router } from 'react-router';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import PublishEventDialog from '../components/createEvent/publishEventDialog';

/**
 * Handles the Publish and Re-Publish of an Event
 * If the selectedTemplate is live : this is a Re-Publish action
 */

class PublishEventContainer extends React.Component {

  constructor() {
    super();
    this.handlePublish = this.handlePublish.bind(this);
    this.handleNavigateToEvent = this.handleNavigateToEvent.bind(this);
  }

  handlePublish() {
    // Are we publishing an event or re-publishing an existing event?
    if(this.props.event.status == 'LIVE') {
      if(this.props.event.hasOwnProperty('eventId')) {
        var oldEventId = this.props.event.eventId;
        this.props.actions.publishEvent(this.props.organizationId, this.props.event.id, 'update', oldEventId);
      } else {
        console.log('Cannot Update. No Event Id on the Config File');
      }            
    } else {
      this.props.actions.publishEvent(this.props.organizationId, this.props.event.id, 'create');
    }    
  }

  handleNavigateToEvent(eventId) {
    // Reset the State of the Publish Screen
    this.props.actions.closePublishDialog();
    console.log('Navigation to Event', eventId);
    var eventPath = `event/${eventId}`;
    browserHistory.push(eventPath);    
  }

  render() {
    var isRepublish = (this.props.event.status == 'LIVE') ? true : false;
    return (
        <PublishEventDialog
          onConfirm={this.handlePublish}
          onCancel={this.props.actions.closePublishDialog}
          open={this.props.dialogOpen}
          isPublishing={this.props.isPublishing}
          isRepublish={isRepublish}
          publishLog={this.props.publishLog}
          publishMessage={this.props.publishMessage}
          publishError={this.props.publishError}
          publishEventResponse={this.props.publishEventResponse}
          handleNavigateToEvent={this.handleNavigateToEvent}
        />
    );
  }
}

function mapStateToProps(state) {
  return {
    organizationId: state.auth.currentOrganization,
    event: state.eventtemplate.selectedTemplate,
    isPublishing: state.eventtemplate.isPublishing,
    publishLog: state.eventtemplate.publishLog,
    publishMessage: state.eventtemplate.publishMessage,
    publishError: state.eventtemplate.publishError,
    dialogOpen: state.eventtemplate.publishDialogOpen,
    publishEventResponse: state.eventtemplate.publishEventResponse  
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps,mapDispatchToProps)(PublishEventContainer);
