


import FirebaseDbService from '../services/firebaseDbService';

import * as actionTypes from '../constants/actionTypes';
import { validateUser, buildUserPayload } from '../actions/auth';

import { browserHistory } from 'react-router';

var db = new FirebaseDbService();

export function requireAuth(store) {
	return function (nextState, replace) {        
        console.log('Authing User');
        db.getUserState()
        .then(function(user) {
            console.log('USER:: ', user);
            store.dispatch({
                type: actionTypes.SIGN_IN_USER_REQUEST
            });
            db.getFullUserRecord(user)
            .then(function(userRecord) {
                var orgs = (userRecord.hasOwnProperty('organizations')) ? userRecord.organizations : null;                
                var sfdcCredentials = (userRecord.hasOwnProperty('salesforceAuth')) ? userRecord.salesforceAuth : null;
                user['organizations'] = orgs;     
                user['salesforceAuth'] = sfdcCredentials;
                store.dispatch({
                type: actionTypes.SIGN_IN_USER,
                payload: buildUserPayload(user)
                });
            })
            .catch(function(err) {
                console.log('Auth Error from Validate',err);
                store.dispatch(authError(err));
            });
        })
        .catch(function(err) {
            console.log('User is not logged in');
            browserHistory.replace('login');
        });
	}
}
