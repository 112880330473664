import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import { browserHistory } from 'react-router';

import ArchiveEventDialog from '../components/event/archiveEventDialog';
import EventList from '../components/eventAdmin/eventList';


export default class EventListContainer extends React.Component {
  constructor() {
    super();
    this.handleSelectItemClick = this.handleSelectItemClick.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleListFilter = this.handleListFilter.bind(this);
    this.handleAddEventClick = this.handleAddEventClick.bind(this);
    this.closeArchiveDialog = this.closeArchiveDialog.bind(this);
    this.handleArchiveEvent = this.handleArchiveEvent.bind(this);

    this.eventToArchive = null;
    this.eventToArchiveName = null;

  }
  
  componentDidMount() {
    var orgId = this.props.organizationId;   
    if(orgId) {
      this.props.actions.refreshEventList(orgId,this.props.listFilter);
    }     
  }

  componentWillReceiveProps(newProps) {
    // If the Organization Changes, refresh this list
    if(this.props.userId != newProps.userId) {
      var orgId = newProps.organizationId;
      this.props.actions.refreshEventList(orgId,this.props.listFilter);
    }
  }

  // Navigate to the Create Event Page
  handleAddEventClick() {
    browserHistory.push(`/template`);
    this.props.actions.createEventTemplate(this.props.organizationId);          
  }
/**
 * THis is a shortcut to Viewing the details of an App
 */
  handleSelectItemClick(eventId) {
    var orgId = this.props.organizationId; 
    browserHistory.push(`/event/${eventId}`);
    this.props.actions.selectEvent(this.props.organizationId,eventId);
  }

  /**
   * MenuItems are View
   */
  handleMenuItemClick(selItem,event) {
    var action = selItem.props.children;
    switch(action) {
      case "View":
        this.handleSelectItemClick(event.id);
      break;
      case "Archive":
        this.eventToArchive = event.id;
        this.eventToArchiveName = event.name;
        this.props.actions.openArchiveDialog();
      break;
      default:
        console.log('Unknown Action');
    }
  }

  handleArchiveEvent() {    
    let eventId = this.eventToArchive;    
    this.props.actions.archiveEvent(this.props.organizationId, eventId, this.props.listFilter);
  }

  closeArchiveDialog() {
    this.eventToArchive = null;
    this.props.actions.closeArchiveDialog();
  }

  handleListFilter(e,index,val) {
    this.props.actions.filterEventList(val);
    this.props.actions.refreshEventList(this.props.organizationId,val);
  }
  

  get page() {
    return (this.eventEventList);
  }

  get eventEventList() {
    return (
      <div>
        <EventList 
              list={this.props.events} 
              onItemClick={this.handleSelectItemClick}
              onMenuItemClick={this.handleMenuItemClick}
              onEventListFilter={this.handleListFilter}
              filterStatusValue={this.props.listFilter}
              onAddEventClick={this.handleAddEventClick}
          />
          <ArchiveEventDialog 
            open={this.props.archiveDialogOpen}
            isArchiving={this.props.isArchiving}
            archiveError={this.props.archiveErrorMsg}
            archiveMessage={this.props.archiveMsg}
            eventName={this.eventToArchiveName}
            onConfirm={this.handleArchiveEvent}
            onCancel={this.closeArchiveDialog}                     
          />
        </div>

    );
  }

  render() {
    var renderNode = this.page;
      return renderNode;
  }
}

function mapStateToProps(state,ownProps) {
  return {
    userId: state.auth.userId,
    events: state.events.eventlist,
    listFilter: state.events.eventListFilter,
    organizationId: state.auth.currentOrganization,
    params: ownProps.params,
    archiveDialogOpen: state.events.archiveDialogOpen,
    isArchiving: state.events.isArchiving,
    archiveErrorMsg: state.events.archiveErrorMsg,
    archiveMsg: state.events.archiveMsg
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(EventListContainer));