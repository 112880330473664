
/**
 * FirebaseDbService
 * Handles any CRUD between client and FB instance
 * 
 * we expect firebase to already be initialized on the main page
 *  ie. on the window :: const firebase = window.firebase
 * 
 */
export default class FirebaseDbService {
    constructor() {
        if(typeof firebase == 'undefined' || !firebase) {
            //This error will throw if a user is not online : Should only happen in DEV
            throw new Error('Firebase must be initialized on the page with app keys before creating an instance of this class');
        }
    }
    
   /**
   * Firebase helpers
   */
  getValue(node) {
    var self = this;
    return new Promise(function(resolve,reject) {
        firebase.database().ref(node)
        .once('value')
        .then(function(snapshot) {  
            resolve(snapshot.val());          
        })
        .catch(function(err) {
            reject(err);
        });        
    });
  }

  queryByValue(node,childAttribute,value) {
    var self = this;
    return new Promise(function(resolve,reject) {
        firebase.database().ref(node)
        .orderByChild(childAttribute)
        .equalTo(value)
        //.once('value')
        .on('value',function(snapshot) {  
            resolve(snapshot.val());          
        });
    });      
  }

  subscriberToValue(node,callback) {
      var self = this;
        firebase.database().ref(node)
        .on('value',function(snapshot) {
            callback.call(this,null,snapshot.val());  
        }); 
  }

/**
 * callback should accept (changeType,snapshot)
 */
  subscriberToChildChanges(node,callback) {
        var self = this;
        var ref = firebase.database().ref(node);
        ref.on('child_added',function(snapshot) {
            callback.call(this,null,'ADDED',snapshot.val());  
        }); 
        ref.on('child_removed',function(snapshot) {
            callback.call(this,null,'REMOVED',snapshot.val());  
        }); 
        ref.on('child_changed',function(snapshot) {
            callback.call(this,null,'CHANGED',snapshot.val());  
        }); 
  }

  subscriberToChildAdded(node,callback) {
      var self = this;
        firebase.database().ref(node)
        .on('child_added',function(snapshot) {
            callback.call(this,null,snapshot.val());  
        }); 
  }

   subscribeToChildChanged(node,callback) {
      var self = this;
        firebase.database().ref(node)
        .on('child_changed',function(snapshot) {
            callback.call(this,null,snapshot.val());  
        }); 
  }
  
  detatchListeners(node) {
      firebase.database().ref(node).off();
  }

  set(node,val) {
    var self = this;
    return new Promise(function(resolve,reject) {
        firebase.database().ref(node)
        .set(val)
        .then(function(result) {
            console.log('Set Function',result);  
            resolve(result);          
        })
        .catch(function(err) {
            reject(err);
        });        
    });      
  }
  

  /**
   * Auth Helpers
   */
   userToken() {
        return new Promise(function(resolve,reject) {
            firebase.auth().currentUser.getToken()
            .then(function(token) {
                resolve(token);
            })
            .catch(function(err) {
                reject(err);      
            });          
        });
   }
   
   currentUser() {
       return (firebase.auth().currentUser) ? firebase.auth().currentUser : null;
   }

   getUserState() {
       var self = this;
       return new Promise(function(resolve, reject) {
            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    resolve(user);
                } else {
                    reject('User Not Logged In');
                }
            });
       });           
   }    
   
   signIn(email,password) {
       var self = this;
        return new Promise(function(resolve,reject) {
            firebase.auth().signInWithEmailAndPassword(email, password)
            .then(function(user) {
                resolve(user);    
            })
            .catch(function(err) {
                reject(err);                
            });       
        });    
   }
   
   signOut() {
        return new Promise(function(resolve,reject) {
            firebase.auth().signOut()
            .then(function() {
                resolve();
            })
            .catch(function(err) {
                reject(err);      
            });          
        });       
   }

/**
 * Return a list of orgs that a user belongs to
 * user: User object returned from FB
 */
  getUserOrgList(user) {
    var self = this;
    return new Promise(function(resolve,reject) {
      if(user.uid) {
        var node = `/users/${user.uid}`;
        self.getValue(node)
        .then(function(snapshot) {
          if(snapshot && snapshot.hasOwnProperty('organizations')) {
             resolve(snapshot.organizations);     
          } else {
            reject('No User Object Returned');
          }
        })
        .catch(function(err) {
            reject(err);
        });      
      } else {
        reject();
      }      
    });
  }

  getFullUserRecord(user) {
    var self = this;
    return new Promise(function(resolve,reject) {
      if(user.uid) {
        var node = `/users/${user.uid}`;
        self.getValue(node)
        .then(function(snapshot) {
          if(snapshot) {
             resolve(snapshot);     
          } else {
            reject('No User Object Returned');
          }
        })
        .catch(function(err) {
            reject(err);
        });      
      } else {
        reject();
      }      
    });
  }

}