import React, { PropTypes } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';

import FlatButton from 'material-ui/FlatButton';
import ActionDelete from 'material-ui/svg-icons/action/delete';

import NavigationMoreVert from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';

import DeleteConfirmModal from '../common/deleteConfirmModal';




const tableStyle = {
  width: '100%'
};

const circularProgress = (
  <div>
    <h6>Retrieving Files</h6>
    <CircularProgress size={40} thickness={3} />
  </div>
);


/**
 * FileList Class
 */
export default class FileList extends React.Component {
  constructor(props) {
    super(props);

    this.handleRowCheck = this.handleRowCheck.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleConfirmFileDelete = this.handleConfirmFileDelete.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);

    this.state = {
      selectedFiles: [],
      deleteConfirmOpen: false
    }
    this.rowFileMap = [];
  }

  handleDeleteCancel() {    
    this.setState({deleteConfirmOpen: false});
  }

  handleConfirmFileDelete() {
    // Call the Delete Prop function witht he list of Ids to Delete    
    var selectedFiles = this.state.selectedFiles;
    if(this.props.handleFilesDelete) {
      this.props.handleFilesDelete(selectedFiles);
      
      // Close out modal : All Messages
      this.setState({deleteConfirmOpen: false, selectedFiles: []});      
      
    }
  }

  handleDeleteButtonClick() {
    this.setState({deleteConfirmOpen: true});    
  }

  handleRowCheck(e, isInputChecked) {
    e.stopPropagation(); // Don't propagate to the underlying row
    var selItems = this.state.selectedFiles;
    var fileId = e.target.value;
    if(isInputChecked) {
      selItems.push(fileId);
    } else {
      var pos = selItems.indexOf(fileId);
      if(pos > -1) {
        selItems.splice(pos,1);
      }
    }
    this.setState({
        selectedFiles: selItems        
      });    
  }

  handleRowSelect(rows) { 
      console.log('Selecged Row', rows);                 
      if(this.props.handleFileClick) {
        var idx = rows[0];        
        this.props.handleFileClick(this.rowFileMap[idx]);      
      }      
  }

  render() {    
      this.rowFileMap = [];
      let displayedComponent = (this.props.fetchingFiles) ? circularProgress : this.fileList ;
      return displayedComponent;   
  }

  get fileListEmptyState() {
    
      return(
        <div className="row">
        <div className="col-sm-12">
            <div className="alert alert-info alert-white">
                No files have been uploaded.
            </div>
        </div>
        </div>              
      );    
  }

  get fileDeleteError() {
    let error = this.props.fileDeleteError;
    if(error) {
      return(
        <div className="alert alert-danger">
          <strong>Error Deleting Files </strong> {error}
        </div>
      );
    }   
    return null; 
  }

  get fileList() {
    var files = this.props.files;
    var rowItems = [];
    
    for(var fileId in files) {
        var file = files[fileId];        
        // Ignore Salesforce Files
        if(file.providerType == 1) { continue; }

        let checkbox = <Checkbox 
                  value={file.fileId}
                  onCheck={this.handleRowCheck}
                />;
        let checkboxWidth = (this.hideCheckBox) ? '0px' : '25px';
        var rowItem = <TableRow key={file.fileId} >       
              <TableRowColumn style={{width:checkboxWidth, paddingLeft:'0px'}} >
                  {(this.props.hideCheckBox) ? null : checkbox}
              </TableRowColumn>
              <TableRowColumn> {file.originalFileName} </TableRowColumn>
              <TableRowColumn style={{width:'220px'}}>{file.processDate}</TableRowColumn>
              <TableRowColumn style={{textAlign:'right',width:'60px'}}> 

                <IconMenu
                  iconButtonElement={<IconButton><NavigationMoreVert /> </IconButton>}
                  anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                  targetOrigin={{horizontal: 'right', vertical: 'top'}}
                >
                  <MenuItem primaryText="View Details" />
                  <Divider />
                  

                </IconMenu>

                
              </TableRowColumn>
            </TableRow>;        
        this.rowFileMap.push(file);
        rowItems.push(rowItem);    
    }

    var deleteButtonDisplay = (this.state.selectedFiles.length > 0) ? '' : 'none';
    var deleteButtonText = (this.state.selectedFiles.length > 1) ? 'Delete Files' : 'Delete File';
    var headerDisplay = (this.props.hideHeader) ? 'none' : '';

    var fileList = 
            <div className="container">
              <div className="row">
                <div className="col-sm-12" style={{display:headerDisplay}}>                    
                    <h4 style={{display:'inline-block'}}>File List</h4>

                    <div className="pull-right">
                      <FlatButton
                        label={deleteButtonText}
                        labelPosition="before"
                        primary={false}        
                        style={{display:deleteButtonDisplay}}                
                        icon={<ActionDelete />}
                        onClick={this.handleDeleteButtonClick}
                        disabled={this.props.isDeleteingFile}
                      />
                    </div>
                  </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {this.fileDeleteError}
                </div>
              </div>
              <div className="row">
              <Table 
                style={tableStyle}
                fixedHeader={true}
                multiSelectable={false}  
                onRowSelection={this.handleRowSelect}   
                selectable={(this.props.handleFileClick) ? true : false}                 
              >
                  <TableHeader
                      style={{display:headerDisplay}}
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}                      
                  >
                    <TableRow>
                      <TableHeaderColumn style={{width:'20px'}}></TableHeaderColumn>
                      <TableHeaderColumn>File Name</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'220px'}}>Upload Date</TableHeaderColumn>
                      <TableHeaderColumn style={{width:'60px'}}></TableHeaderColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody 
                      displayRowCheckbox={false}
                      selectable={(this.props.handleFileClick) ? true : false}
                      deselectOnClickaway={false}
                  >
                    {rowItems}
                  </TableBody>
              </Table>
              </div>

              <div style={{marginTop:'5px'}}>
                  {(rowItems.length == 0) ? this.fileListEmptyState : null}    
              </div>

              <DeleteConfirmModal 
                title={"Delete Selected Files?"}
                message={"If you delete files, you can never get them back. Ever."}
                isOpen={this.state.deleteConfirmOpen}
                handleDelete={this.handleConfirmFileDelete}
                handleCancel={this.handleDeleteCancel}                
              />
            </div>;
    return fileList;
  }
}
FileList.propTypes = {
    files: PropTypes.object,
    file: PropTypes.object,    
    handleFileClick: PropTypes.func,
    fetchingFiles: PropTypes.bool,
    handleFilesDelete: PropTypes.func,
    hideHeader: PropTypes.bool,
    hideCheckBox: PropTypes.bool,

    isDeleteingFile:PropTypes.bool,
    fileDeleteError:PropTypes.string
}
