import React, { PropTypes } from 'react';
import { withRouter, hashHistory } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import PageNavBar from '../components/common/pageNavBar';

import FlatButton from 'material-ui/FlatButton';

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
  }

  handleSignOut() {
    this.props.actions.signOutUser();
  }

  handleNavClick(path) {
    hashHistory.push(path);
  }

  render() {

    return (
      <div>
        <PageNavBar 
          authenticated={this.props.authenticated}
          displayName={this.props.displayName}
          email={this.props.email}
          handleSignOut={this.handleSignOut}
          handleNavClick={this.handleNavClick}
        />
       </div>
    );    
  }
}

//Redux : Set Props of this componet to a reference to the Singleton State object
function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    displayName: state.auth.displayName,
    email:state.auth.email
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

PageHeader.propTypes = {
  user: PropTypes.object
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(PageHeader));


