import React, { PropTypes } from 'react';

import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import RaisedButton from 'material-ui/RaisedButton';
import AttributeTableRow from './attributeTableRow';
import AttributeEmptyTableRow from './attributeEmptyTableRow';


const styles = {
    button: { margin: 12 }
};



export default class AttributeTableList extends React.Component {
  constructor(props) {
    super(props);
       
    // Bind methods to this class so "this" works as expected
    //this.handleInputChange = this.handleInputChange.bind(this);
  }

  render() {
      var l = this.props.attributeList.length;
      var self = this;
      
      let addAttrButton = <div><RaisedButton
                label="Add Attribute"
                primary={true}
                style={styles.button}
                onClick={this.props.onAddItem}
            /></div> ; 
        
      var addAttrDropzone = null;
      if(this.props.includeDropArea) {
          addAttrDropzone = <AttributeEmptyTableRow 
                                onAddMapping={self.props.onAddAttributeWithMapping}
                            />
      }
    return (
    <div>
        <div className="row">
            <div className="col-sm-8"> 
                <h4 style={{display:'inline-block',marginTop:18,marginBottom:0}}>{this.props.title}</h4>
            </div>
            <div className="col-sm-4" style={{textAlign:'right'}}>
                {(this.props.title == 'Attendee Attributes') ? null : addAttrButton}
            </div>                        
        </div>
        <div className="row">
            <div className="col-sm-12">
                {this.props.message}
            </div>
        </div>        
        <div className="row">
            <div className="col-sm-12">
            <Table selectable={false}>
                <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                <TableRow>
                    <TableHeaderColumn style={{width:'30px'}}></TableHeaderColumn>
                    <TableHeaderColumn style={{textAlign:'left'}}>Attribute Name</TableHeaderColumn>
                    <TableHeaderColumn>Field Type</TableHeaderColumn>
                    <TableHeaderColumn>Mapped To</TableHeaderColumn>
                    <TableHeaderColumn style={{width:'70px'}}></TableHeaderColumn>
                </TableRow>
                </TableHeader>
                <TableBody>            
                {
                    Object.keys(this.props.attributeList).map(function (key) {
                        return <AttributeTableRow 
                        key={this.props.attributeList[key].attributeId} 
                        attribute={this.props.attributeList[key]} 
                        onRemove={self.props.onRemoveItem} 
                        onAddMapping={self.props.onAddMapping}
                        onValueChange={self.props.onValueChange}
                        onAttributeTypeChange={self.props.onAttributeTypeChange}  
                        onRemoveMapping={self.props.onRemoveMapping}                      
                        />;
                    },this)
                }             
                {addAttrDropzone}
                </TableBody>
            </Table>
            </div>            
        </div>        
      </div>
    );
  }
}

AttributeTableList.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onAddMapping: PropTypes.func,
    onAddAttributeWithMapping: PropTypes.func,
    onRemoveMapping: PropTypes.func,
    onValueChange: PropTypes.func,
    onAttributeTypeChange: PropTypes.func,
    attributeList: PropTypes.object,
    includeDropArea: PropTypes.bool
}