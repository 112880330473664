/**
 * Event Template related state reducers / handlers
 */

import { 
    REFRESH_EVENT_LIST, 
    LOADING_EVENT,
    REFRESH_EVENT,
    EVENT_RETRIEVAL_ERROR,
    TICKET_ADDED,
    TICKET_BATCH_ADDED,
    TICKET_CHANGED,
    FILTER_ATTRIBUTE_LIST,
    EVENT_DATA_REFRESHING,
    OPEN_ARCHIVE_DIALOG,
    CLOSE_ARCHIVE_DIALOG,
    EVENT_ARCHIVE_REQUEST,
    EVENT_ARCHIVE_COMPLETE,
    EVENT_ARCHIVE_ERROR,
    FILTER_EVENT_LIST,
    UPDATE_EVENT_NAME,
    EVENT_USER_UPDATED    
} from '../constants/actionTypes';


/**
 * statusCounts : {status : count}
 * attributeCounts: { attrName : {status: count }}
 */

/**
 * What has happened is i've ended up building a read model in application state
 * This works alright for a couple thousands tickets, but performance is not great on initial retrieval
 */

const initialState = {
    eventlist: {},
    selectedEvent: null,
    activeEventUsers: {},
    selectedEventName: null,
    eventIsLoading: false,
    eventDataIsUpdating: false,
    eventError: null,
    ticketCount: 0,
    statusCounts: {},
    attributeCounts: {}, // List of distinct attribute values and status counts
    selectedAttributeFilter: null,
    archiveDialogOpen: false,
    isArchiving: false,
    archiveErrorMsg: null,
    archiveMsg: null,
    eventListFilter: "New"
};

/**
 * ticketStatusHashMap 
 * Contains the last seen values for attributes and status of each ticket
 * If this gets too intensive, we should offload this to a server where we can 
 * better implement queries and aggregations
 * 
 * ticketStatusHashMap[id] = {
 *      status: 'SOME_STATUS',
 *      attributes: {
 *          'ATTRID':'SOME_VALUE'
 *      }
 * }
 * 
 */
var ticketStatusHashMap = {};



export default function eventReducer(state = initialState, action) {
  switch (action.type) {

    case LOADING_EVENT:
        return mergeState(state,{
           eventIsLoading: true
    });      

    // Publish Actions
    case REFRESH_EVENT_LIST:
        ticketStatusHashMap = {};
        return mergeState(state,{
           eventlist: action.payload,
           selectedEvent: null,
           activeEventUsers: {},
           selectedEventName: null,
           eventIsLoading: false,
           statusCounts: {},
           attributeCounts: {},
           ticketCount: 0,
           selectedAttributeFilter: null
    });    

    case FILTER_EVENT_LIST:
        return mergeState(state,{
           eventListFilter: action.payload
    });    

    // This Payload is returning Device Level Details
    /*
        AE43E427-B258-47FA-9EB9-56FA64A5D30A
        :
        connectionTime
        :
        "2017-10-14T20:50:00.795Z"
        deviceId
        :
        "AE43E427-B258-47FA-9EB9-56FA64A5D30A"
        deviceName
        :
        "iPhone Simulator"
        email
        :
        "james@tappedinevents.com"
        isOnline
        :
        false
        lastOnline
        :
        1508017523094
        userId
        :
        "33bebf20-2782-
    */
    case EVENT_USER_UPDATED:
        // Event User List - Should be Device Id - User Id Pair
        var eventUserList = {}
        var eventUsers = state.activeEventUsers;
        for(var key in eventUsers) {
            eventUserList[key] = eventUsers[key];
        }
        var device = action.payload;
        for(var deviceId in device) {
            var connection = device[deviceId];            
            var uniqueKey = `${deviceId}-${connection.userId}`;
            eventUserList[uniqueKey] = connection;
        }
        return mergeState(state,{
           activeEventUsers: eventUserList
    });
    
    case REFRESH_EVENT:
        //Not sure the best way to handle this?
        ticketStatusHashMap = {};
        var eventName = (action.payload !== null) ?  action.payload.name : null;
        return mergeState(state,{
           selectedEvent: action.payload,
           selectedEventName: eventName,
           activeEventUsers: {},
           eventIsLoading: false,
           statusCounts: {},
           attributeCounts: {},
           ticketCount: 0,
           selectedAttributeFilter: null
    });

    case EVENT_DATA_REFRESHING:
        return mergeState(state,{
           eventDataIsUpdating: true
    });         

    case EVENT_RETRIEVAL_ERROR:
        return mergeState(state,{
           selectedEvent: null,
           selectedEventName: null,
           eventError: action.payload,
           eventIsLoading: false,
           statusCounts: {},
           attributeCounts: {},
           ticketCount: 0 ,
           selectedAttributeFilter: null          
    });

    case TICKET_ADDED:
        var updatedState = handleTicketAdded(action.payload,state.statusCounts,state.attributeCounts);
        var statusCounts = mergeState({},updatedState.status);
        var attributes = mergeState({},updatedState.attributes);
        var ticketCount = state.ticketCount + 1;
        return mergeState(state,{
           statusCounts: statusCounts,
           ticketCount: ticketCount,
           attributeCounts: attributes
        });

    //Same as TICKET_ADDED
    case TICKET_CHANGED:

        var updatedState = handleTicketChanged(action.payload,state.statusCounts,state.attributeCounts);
        var statusCounts = mergeState({},updatedState.status);
        var attributes = mergeState({},updatedState.attributes);

        return mergeState(state,{
           statusCounts: statusCounts,
           attributeCounts: attributes
        });


    case FILTER_ATTRIBUTE_LIST:
        return mergeState(state,{
           selectedAttributeFilter: action.payload
        });    


    /**
     * Experimental Reducers
     */
    case TICKET_BATCH_ADDED:
        var tickets = action.payload;
        console.log('Batch Tickets:',new Date(), tickets);
        var currentTicketCount = state.ticketCount;
        var updatedState = handleBatchTicketsAdded(tickets,state.statusCounts,state.attributeCounts);
        var statusCounts = mergeState({},updatedState.status);
        var attributes = mergeState({},updatedState.attributes);
        return mergeState(state,{
           statusCounts: statusCounts,
           attributeCounts: attributes,
           ticketCount: (updatedState.ticketCount + currentTicketCount),
           eventDataIsUpdating: false
        });


    /**
     * Archive Events
     */
    case EVENT_ARCHIVE_REQUEST:
         return mergeState(state,{
           isArchiving: true
        });   
    case EVENT_ARCHIVE_COMPLETE:
         return mergeState(state,{
           archiveDialogOpen: true,
           isArchiving: true,
           archiveErrorMsg: null, 
           archiveMsg: 'Event Successfully Archived'
        });  

    case EVENT_ARCHIVE_ERROR:
         return mergeState(state,{
           archiveDialogOpen: true,
           isArchiving: false,
           archiveErrorMsg: action.payload,
           archiveMsg: null
        });   
    case OPEN_ARCHIVE_DIALOG:
         return mergeState(state,{
           archiveDialogOpen: true,
           isArchiving: false,
           archiveErrorMsg: null, 
           archiveMsg: null
        });  
    case CLOSE_ARCHIVE_DIALOG:
         return mergeState(state,{
           archiveDialogOpen: false,
           isArchiving: false,
           archiveErrorMsg: null, 
           archiveMsg: null           
        });
    case UPDATE_EVENT_NAME:
         return mergeState(state, { 
            selectedEventName: action.payload
        });
    default:        
      return state;
  }
}


/**
 * Experimental Reducer : Batch 
 */
function handleBatchTicketsAdded(tickets,statusCountsState,attributeCountState) {
    var ticket,ticketSummary;
    var ticketCount = 0;
    for(var ticketId in tickets) {
        if(ticketStatusHashMap[ticketId]) {
            console.log('Ticket added is already in hashmap');
        } else {
            ticketCount += 1;
            ticket = tickets[ticketId];
            ticketSummary = buildTicketAttributes(ticket);
            ticketStatusHashMap[ticketId] = ticketSummary;

            // Update Summary Status Counts
            if(!statusCountsState.hasOwnProperty(ticket.ticketStatus)) { 
                statusCountsState[ticket.ticketStatus] = 1; 
            } else {
                statusCountsState[ticket.ticketStatus] += 1; 
            }

            // Upadte the Attribute Counts
            for(var id in ticket.attributes) {
                var attr = ticket.attributes[id];
                var nme = attr.attributeName;
                var val = (attr.hasOwnProperty('value')) ? attr['value'] : null;
                
                if(!attributeCountState.hasOwnProperty(nme)) { attributeCountState[nme] = {}; }
                if(!attributeCountState[nme].hasOwnProperty(val)) { attributeCountState[nme][val] = {}; } 
                if(!attributeCountState[nme][val].hasOwnProperty(ticket.ticketStatus)) { attributeCountState[nme][val][ticket.ticketStatus] = 0;  }
                attributeCountState[nme][val][ticket.ticketStatus] = attributeCountState[nme][val][ticket.ticketStatus] + 1;
            }
        }
    }    
    console.log('All Tickets Added to Map',new Date(), statusCountsState, attributeCountState);
    return {
        ticketCount: ticketCount,
        status: statusCountsState,
        attributes: attributeCountState
    };
}

/**
 * Ticket Added
 * This method should not be called anymore. We should be handling Ticket Adds using the debounced method
 */
function handleTicketAdded(ticket,statusCounts,attributeCounts) {
    
    //If we have already seen this ticket : Error and return existing state
    if(ticketStatusHashMap[ticket.id]) {
        console.log('Error : Ticket added is already in hashmap');
        return {
            status: statusCounts,
            attributes: attributeCounts
        };
    }

    //Add new ticket to ticket map
    var ticketSummary = buildTicketAttributes(ticket);
    ticketStatusHashMap[ticket.id] = ticketSummary;

    //Update summary status
    if(!statusCounts.hasOwnProperty(ticket.ticketStatus)) { 
        statusCounts[ticket.ticketStatus] = 1; 
    } else {
        statusCounts[ticket.ticketStatus] += 1; 
    }

    //Update attribute stats
    var newAttributeCounts = updateAttributeCounts(ticket,attributeCounts);

    return {
        status: statusCounts,
        attributes: newAttributeCounts
    };
}

/**
 * handleTicketChanged
 */
function handleTicketChanged(ticket,statusCountsState,attributeCountState) {

    if(ticketStatusHashMap.hasOwnProperty(ticket.id)) {
        var oldTicket = ticketStatusHashMap[ticket.id];
 
        // Back out the ticket status and attribute status 
        if(statusCountsState.hasOwnProperty(oldTicket.status)) {
            statusCountsState[oldTicket.status] -= 1;
        } else {
            console.log('Error: changing a ticket from a status we have not seen before');
        }
        

        // Back out the exisitng Attributes
        var oldStatus = oldTicket.status;
        for(var attributeName in oldTicket.attributes) {
            var val = (oldTicket.attributes.hasOwnProperty(attributeName)) ? oldTicket.attributes[attributeName] : null;
            if(attributeCountState.hasOwnProperty(attributeName) && attributeCountState[attributeName].hasOwnProperty(val)) { 
                attributeCountState[attributeName][val][oldStatus] -= 1;
            } else {
                console.log('Error: Backing out status that was not in the summary');
            }
        }      
        // Values are now backed out 
        var tickets = {};
        tickets[ticket.id] = ticket;
        delete ticketStatusHashMap[ticket.id];
        return handleBatchTicketsAdded(tickets,statusCountsState,attributeCountState);
    } else {
        //Out of sequence update : Have not yet received the Ticket Added eventAttribute
        console.log('Out of sequence change event : No Ticket Added');
    }   
}

/**
 * attributeCounts
 * attributeCounts: { attrName : {val: {status:count}}
 */
function updateAttributeCounts(ticket,attributeCounts) {
    var ticketStatus = ticket.ticketStatus;
    for(var id in ticket.attributes) {
        var attr = ticket.attributes[id];
        var nme = attr.attributeName;
        var val = (attr.hasOwnProperty('value')) ? attr['value'] : null;
        
        if(!attributeCounts.hasOwnProperty(nme)) { attributeCounts[nme] = {}; }
        if(!attributeCounts[nme].hasOwnProperty(val)) { attributeCounts[nme][val] = {}; } 
        if(!attributeCounts[nme][val].hasOwnProperty(ticketStatus)) { attributeCounts[nme][val][ticketStatus] = 0;  }
        attributeCounts[nme][val][ticketStatus] = attributeCounts[nme][val][ticketStatus] + 1;
    }
    return attributeCounts;     
}

/*
 * ticketStatusHashMap[id] = {
 *      status: 'SOME_STATUS',
 *      attributes: {
 *          'ATTRID':'SOME_VALUE'
 *      }
 *  }
 */
function buildTicketAttributes(ticket) {
    var r = {
        status: ticket.ticketStatus,
        attributes: {}
    };
    for(var id in ticket.attributes) {
        var nme = ticket.attributes[id].attributeName;
        r.attributes[nme] = (ticket.attributes[id].hasOwnProperty('value')) ? ticket.attributes[id]['value'] : null;
    }
    return r;
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}