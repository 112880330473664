import React, { PropTypes } from 'react';

import moment from 'moment';

import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import FlatButton from 'material-ui/FlatButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import IconButton from 'material-ui/IconButton';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import { grey300, grey400, black, green500 } from 'material-ui/styles/colors';


import FontIcon from 'material-ui/FontIcon';
import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import DropDownMenu from 'material-ui/DropDownMenu';
import RaisedButton from 'material-ui/RaisedButton';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from 'material-ui/Toolbar';



const styles = {
  upload: {
    width: '100%',
    border: 'none',
    height: '100%'
  }
}

/**
 * Icon Button Element and Menu
 * Display Actions on each event that is displayed
 */
const iconButtonElement = (
  <IconButton
    touch={true}
    tooltip="Actions"
    tooltipPosition="top-left"
  >
    <MoreVertIcon color={grey400} />
  </IconButton>
);



/**
 * EventList Class
 */
export default class EventList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
      return this.buildConfigList(this.props.list);
  }

  get eventToolbar() {


    return (
      <Toolbar>
        <ToolbarGroup firstChild={true}>
          <DropDownMenu 
              value={this.props.filterStatusValue} 
              onChange={this.props.onEventListFilter}
              iconStyle={{'color':'#000000'}}
              underlineStyle={{}}
          >
            <MenuItem value="New" primaryText="Active Events" />
            <MenuItem value="Archived" primaryText="Archived Events" />
          </DropDownMenu>
        </ToolbarGroup>
        <ToolbarGroup>
          <RaisedButton label="Create Event" primary={true} onClick={this.props.onAddEventClick} />
        </ToolbarGroup>
      </Toolbar>
    );   
  }

  buildMenuItems(listItem) {
    //if(listItem.isActive == false) { return null; }
    return (
      <IconMenu 
        onItemTouchTap={(items,item) => { this.props.onMenuItemClick(item,listItem) }}
        iconButtonElement={iconButtonElement}
        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        targetOrigin={{horizontal: 'right', vertical: 'top'}}
      >        
        <MenuItem>Archive</MenuItem>
      </IconMenu>
    );
  }

  buildConfigList(list) {
    var listItems = [], currItem,countOfAttrs=0,secondaryText;    
    for(var listId in list) {
        currItem = list[listId];  
        var createDate = new Date(currItem.createdDate);
        var formatDate = moment(currItem.createdDate).fromNow();
        var menu = this.buildMenuItems(currItem);

        secondaryText = `Created ${formatDate}  (${currItem.eventStatus})`;    
        var listItem = <ListItem
            key={listId}
            primaryText={currItem.name}
            secondaryText={secondaryText}
            onTouchTap={this.props.onItemClick.bind(this,listId)}
            rightIconButton={menu}
        />;

        listItems.push(listItem);    
    }
    
    var configList =             
            <div>              
              {this.eventToolbar}

              <List>
                  {listItems}
              </List>
            </div>;
    return configList;
  }
  
}

EventList.propTypes = {
    list: PropTypes.object,
    onItemClick: PropTypes.func,
    onAddEventClick: PropTypes.func,
    onMenuItemClick: PropTypes.func,
    onEventListFilter: PropTypes.func,
    filterStatusValue: PropTypes.string
}