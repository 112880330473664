import React, {PropTypes} from 'react';
import { Router } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';
import LoginContainer from './loginContainer';

import PageHeader  from './PageHeader';

class PageContainer extends React.Component {

  get loginContainer() {
    return <LoginContainer />
  }

  render() {
    var render = this.props.children;
    return (
        <div>
        <PageHeader user={this.props.auth} />
            {render}
        </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps,mapDispatchToProps)(PageContainer);