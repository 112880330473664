import React, { PropTypes } from 'react';
import Dialog from 'material-ui/Dialog';
import LinearProgress from 'material-ui/LinearProgress';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import HardwarePhoneIPhone from 'material-ui/svg-icons/hardware/phone-iphone';
import {blue500, red500, green500} from 'material-ui/styles/colors';

import UploadMenu from './uploadMenu';
import DropTarget from './dropTarget';


const customContentStyle = {
  width: '100%',
  paddingLeft:'0px',
  maxWidth: 'none',
};

// File Details if File Is Selected
// Upload if Upload is Selected 
export default class FileUploadDialog extends React.Component {

    getContent(menuItem) {
        switch(menuItem) {
            case "upload":
                return this.uploadFileTarget();
            break;
            case "file":
                return this.fileList();
            break;            
            case "salesforce":
                return "<div></div>";
            break;              
        }
    }

    // Content for Upload File : upload
    uploadFileTarget() {
        return(
            <DropTarget 
                handleFileUpload={this.props.handleFileUpload}
                isUploadingFile={this.props.isUploadingFile}
                fileUploadError={this.props.fileUploadError}
            />
        );
    };

    // Content for File List : filelist
    fileList() {
        return(
            <div>file list</div>                          
        );
    };



  render() {   

      let contentComponent = this.getContent(this.props.menuSelectedItem);

      return ( 
        <Dialog
            title="File Management"            
            modal={true}
            open={this.props.open}
            autoScrollBodyContent={true}
            bodyClassName="dialog-no-padding"            
        >

            <div className="container" style={{paddingLeft:0}}>
                <div className="row">
                    <div className="col-sm-3">
                        <UploadMenu 
                            menuSelectedItem={this.props.menuSelectedItem}
                            handleMenuSelect={this.props.handleMenuSelect}
                        />
                    </div>
                    <div className="col-sm-9">                                            
                        {contentComponent}
                    </div>                    
                </div>                
            </div>
        </Dialog>     
      );
    }    
}

FileUploadDialog.propTypes = {
    open: PropTypes.bool,    
    event: PropTypes.object,
    handleFileUpload: PropTypes.func,
    isUploadingFile: PropTypes.bool,
    fileUploadError: PropTypes.string,
    handleMenuSelect: PropTypes.func,
    menuSelectedItem: PropTypes.string
}