/**
 * Export all action type constants
 * Used by Actions and Reducers
 */

/**
 * Auth
 */
export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CHANGE_ACTIVE_ORG = 'CHANGE_ACTIVE_ORG';

export const SALESFORCE_AUTH_UPDATED = 'SALESFORCE_AUTH_UPDATED';
export const SALESFORCE_AUTH_SAVED = 'SALESFORCE_AUTH_SAVED';
export const SALESFORCE_AUTH_SAVED_ERROR = 'SALESFORCE_AUTH_SAVED_ERROR';
export const SALESFORCE_AUTH_REQUESTED = 'SALESFORCE_AUTH_REQUESTED';

/**
 * Salesforce Specific Actions
 */

export const SFDC_FILE_REQUEST = 'SFDC_FILE_REQUEST';
export const SFDC_FILE_ERROR = 'SFDC_FILE_ERROR';

export const SFDC_CAMPAIGN_LIST_REQUEST = 'SFDC_CAMPAIGN_LIST_REQUEST';
export const SFDC_CAMPAIGN_LIST_RESPONSE = 'SFDC_CAMPAIGN_LIST_RESPONSE';
export const SFDC_CAMPAIGN_LIST_ERROR = 'SFDC_CAMPAIGN_LIST_ERROR';
export const SALESFORCE_TOKEN_RETURNED = 'SALESFORCE_TOKEN_RETURNED';

/**
 * Event Template CRUD
 */
export const REFRESH_EVENT_TEMPLATES = 'REFRESH_EVENT_TEMPLATES';
export const LOADING_EVENT_TEMPLATE = 'LOADING_EVENT_TEMPLATE';
export const CREATE_EVENT_TEMPLATE = 'CREATE_EVENT_TEMPLATE';
export const LOAD_EVENT_TEMPLATE = 'LOAD_EVENT_TEMPLATE';
export const TEMPLATE_RETRIEVAL_ERROR = 'TEMPLATE_RETRIEVAL_ERROR';
export const UPDATE_EVENT_TEMPLATE = 'UPDATE_EVENT_TEMPLATE';
export const UPDATE_EVENT_TEMPLATE_ATTRIBUTES = 'UPDATE_EVENT_TEMPLATE_ATTRIBUTES';
export const SAVE_EVENT_TEMPLATE = 'SAVE_EVENT_TEMPLATE';
export const SAVE_EVENT_ERROR = 'SAVE_EVENT_ERROR';
export const SAVE_EVENT_VALIDATION_ERROR = 'SAVE_EVENT_VALIDATION_ERROR';

export const FILE_ADDED_TO_EVENT_TEMPLATE = 'FILE_ADDED_TO_EVENT_TEMPLATE';
export const FILE_REMOVED_FROM_EVENT_TEMPLATE = 'FILE_REMOVED_FROM_EVENT_TEMPLATE';
export const CLOSE_FILE_DETAILS_DRAWER = 'CLOSE_FILE_DETAILS_DRAWER';
export const OPEN_FILE_DETAILS_DRAWER = 'OPEN_FILE_DETAILS_DRAWER';

export const EVENT_COLUMN_MAPPING_ADDED = 'EVENT_COLUMN_MAPPING_ADDED';
export const EVENT_COLUMN_MAPPING_REMOVED = 'EVENT_COLUMN_MAPPING_REMOVED';
export const EVENT_COLUMN_MAPPING_ERROR = 'EVENT_COLUMN_MAPPING_ERROR';
export const EVENT_COLUMN_MAPPING_ERROR_CONFIMED = 'EVENT_COLUMN_MAPPING_ERROR_CONFIMED';
export const SAVE_EVENT_REQUEST_SENT = 'SAVE_EVENT_REQUEST_SENT';

export const PUBLISH_EVENT_REQUEST = 'PUBLISH_EVENT_REQUEST'; 
export const PUBLISH_EVENT_COMPLETE = 'PUBLISH_EVENT_COMPLETE';
export const PUBLISH_EVENT_LOG_UPDATED = 'PUBLISH_EVENT_LOG_UPDATED'; 
export const PUBLISH_EVENT_ERROR = 'PUBLISH_EVENT_ERROR'; 

export const OPEN_PUBLISH_DIALOG = 'OPEN_PUBLISH_DIALOG'; 
export const CLOSE_PUBLISH_DIALOG = 'CLOSE_PUBLISH_DIALOG'; 


export const OPEN_FILE_SELECTION_DIALOG = 'OPEN_FILE_SELECTION_DIALOG';
export const CLOSE_FILE_SELECTION_DIALOG = 'CLOSE_FILE_SELECTION_DIALOG';


/**
 * User Files CRUD
 */
export const FILE_LIST_REQUEST = 'FILE_LIST_REQUEST';
export const FILE_LIST_RESPONSE = 'FILE_LIST_RESPONSE';
export const FILE_UPLOAD_INPROGRESS = 'FILE_UPLOAD_INPROGRESS';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const USER_FILE_SELECTED = 'USER_FILE_SELECTED';
export const USER_FILE_CLOSED = 'USER_FILE_CLOSED';
export const USER_FILE_UPLOAD_SERVICE_DOWN = 'USER_FILE_UPLOAD_SERVICE_DOWN';
export const USER_FILE_UPLOAD_SERVICE_AVAILABLE = 'USER_FILE_UPLOAD_SERVICE_AVAILABLE';


export const FILE_DELETE_INPROGRESS = 'FILE_DELETE_INPROGRESS';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_ERROR = 'FILE_DELETE_ERROR';


/**
 * Event CRUD
 */
export const REFRESH_EVENT_LIST = 'REFRESH_EVENT_LIST';
export const FILTER_EVENT_LIST = 'FILTER_EVENT_LIST';
export const LOADING_EVENT = 'LOADING_EVENT';
export const EVENT_DATA_REFRESHING = 'EVENT_DATA_REFRESHING';
export const REFRESH_EVENT = 'REFRESH_EVENT';
export const EVENT_RETRIEVAL_ERROR = 'EVENT_RETRIEVAL_ERROR';

export const EVENT_USER_UPDATED = 'EVENT_USER_UPDATED';
export const LOADING_USER_DEVICES = 'LOADING_USER_DEVICES';

export const TICKET_ADDED = 'TICKET_ADDED';
export const TICKET_BATCH_ADDED = 'TICKET_BATCH_ADDED';
export const TICKET_CHANGED = 'TICKET_CHANGED';
export const FILTER_ATTRIBUTE_LIST = 'FILTER_ATTRIBUTE_LIST';


export const EVENT_ARCHIVE_REQUEST = 'EVENT_ARCHIVE_REQUEST';
export const EVENT_ARCHIVE_COMPLETE = 'EVENT_ARCHIVE_COMPLETE';
export const EVENT_ARCHIVE_ERROR = 'EVENT_ARCHIVE_ERROR';
export const OPEN_ARCHIVE_DIALOG = 'OPEN_ARCHIVE_DIALOG';
export const CLOSE_ARCHIVE_DIALOG = 'CLOSE_ARCHIVE_DIALOG';

export const UPDATE_EVENT_NAME = 'UPDATE_EVENT_NAME';

/**
 * User Reports
 */
export const REPORT_REQUEST = 'REPORT_REQUEST';
export const REPORT_ERROR = 'REPORT_ERROR';
export const REPORT_COMPLETE = 'REPORT_COMPLETE';
export const REPORT_CLEAR_ERRORS = 'REPORT_CLEAR_ERRORS';
export const REPORT_LIST_REQUEST = 'REPORT_LIST_REQUEST';
export const REPORT_LIST_RESPONSE = 'REPORT_LIST_RESPONSE';
export const REPORT_LIST_ERROR = 'REPORT_LIST_ERROR';

export const REPORT_DOWNLOAD_REQUEST = 'REPORT_DOWNLOAD_REQUEST';
export const REPORT_DOWNLOAD_DETAILS = 'REPORT_DOWNLOAD_RESPONSE';
export const REPORT_DOWNLOAD_REQUEST_ERROR = 'REPORT_DOWNLOAD_ERROR';

export const OPEN_DELETE_REPORT_DIALOG = 'OPEN_DELETE_REPORT_DIALOG';
export const CLOSE_DELETE_REPORT_DIALOG = 'CLOSE_DELETE_REPORT_DIALOG';
export const REPORT_DELETE_REQUEST = 'REPORT_DELETE_REQUEST';
export const REPORT_DELETE_RESPONSE = 'REPORT_DELETE_RESPONSE';
export const REPORT_DELETE_ERROR = 'REPORT_DELETE_ERROR';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_RESPONSE = 'USER_LIST_RESPONSE';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';

// New Org 
export const ADD_ORG_REQUEST = 'ADD_ORG_REQUEST';
export const ADD_ORG_SUCCESS = 'ADD_ORG_SUCCESS';
export const ADD_ORG_ERROR = 'ADD_ORG_ERROR';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';
export const ADD_USER_OPEN_DIALOG = 'ADD_USER_OPEN_DIALOG';
export const ADD_USER_CLOSE_DIALOG = 'ADD_USER_CLOSE_DIALOG';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const DELETE_USER_OPEN_DIALOG = 'DELETE_USER_OPEN_DIALOG';
export const DELETE_USER_CLOSE_DIALOG = 'DELETE_USER_CLOSE_DIALOG';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_SENT = 'RESET_PASSWORD_REQUEST_SENT';
export const RESET_PASSWORD_REQUEST_ERROR = 'RESET_PASSWORD_REQUEST_ERROR';
export const USER_PASSWORD_UPDATED = 'USER_PASSWORD_UPDATED';



