import React, { PropTypes } from 'react';
import { DragSource } from 'react-dnd';
import { createDragPreview } from 'react-dnd-text-dragpreview'
import {ListItem} from 'material-ui/List';

const ItemTypes = {
    ROW : 'ROW'
};

/**
 * Drag Properties
 */
var dragPreviewStyle = {
  backgroundColor: 'rgb(68, 67, 67)',
  borderColor: '#F96816',
  color: 'white',
  fontSize: 15,
  paddingTop: 4,
  paddingRight: 7,
  paddingBottom: 6,
  paddingLeft: 7
};

const rowSource = {
  beginDrag(props) {  
    if (typeof props.onDragRow === 'function') {
      props.onDragRow(props.column.columnId);
    }
    return {
      columnId: props.column.columnId,
      fileId: props.fileId,
      columnName: props.column.columnName
    };
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}


/**
 * ColumnRow class
 */
class ColumnRow extends React.Component {
  constructor(props) {
    super(props);

    // TODO : Fix this drag preview
    this.dragPreview = createDragPreview(this.props.column.columnName, dragPreviewStyle);
    this.props.connectDragPreview(this.dragPreview);
  }

  render() {
        var sampleData = (this.props.column.hasOwnProperty('sampleData')) ? this.props.column.sampleData.filter( this.onlyUnique ) : []; // returns ['a', 1, 2, '1']
        var sampleDataDisplay = (sampleData.length > 0) ? 'ie. ' + sampleData.join(', ') : '';
        
        return this.props.connectDragSource(<div>
            <ListItem
                key={this.props.column.columnId}
                primaryText={this.props.column.columnName}
                secondaryText={sampleDataDisplay}
                secondaryTextLines={1}
            />
        </div>);
  }
  
  onlyUnique(value, index, self) {     
    return (self.indexOf(value) === index && value.length > 0);
  }
}

ColumnRow.propTypes = {
    column: PropTypes.object,
    fileId: PropTypes.string
}

export default  DragSource(ItemTypes.ROW, rowSource, collect)(ColumnRow);