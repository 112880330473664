import React, { PropTypes } from 'react';
import { Link } from 'react-router';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';


const styles = {
  container: {
    textAlign: 'center',
    paddingTop: 200,
  },
  button: {
    margin: 12
  },
  errorMessage: {
    color:'red'
  }
};


export default class RegisterForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '', 
      password: '',
      orgName: ''
    };
    
    // Bind methods to this class so "this" works as expected
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleOrgNameChange = this.handleOrgNameChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  
  /**
   * Event Handlers
   */
  handleEmailChange(e) {
    this.setState({email: e.target.value});  
  }
  
  handlePasswordChange(e) {
    this.setState({password: e.target.value});
  }

  handleOrgNameChange(e) {      
    this.setState({orgName: e.target.value});
  }  

  handleKeyPress(target) {
    if(target.charCode==13) {        
        this.props.onSubmit();
    }
  }   
  
  render() {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="wrapper-page">

                            <div className="m-t-40">

                                <div className="account-content">
                                    <form className="form-horizontal" action="#">

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                  <h4 className="header-title">Sign Up</h4>
                                            </div>
                                        </div>

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                  { this.props.errorMessage !== null ? <div style={styles.errorMessage}>{this.props.errorMessage}</div> : null }
                                            </div>
                                        </div>

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">                                                
                                                <label htmlFor="orgName">Organization Name</label>
                                                <input onChange={this.handleOrgNameChange} className="form-control" type="text" required="" id="orgName" placeholder="Organization Name" />
                                                <div className="text-muted">The name of your company or just a random name for your account</div>
                                            </div>
                                        </div>       

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                <label htmlFor="emailaddress">Email address</label>
                                                <input onChange={this.handleEmailChange} onKeyPress={this.handleKeyPress} className="form-control" type="email" id="emailaddress" required="" placeholder="me@tappedinevents.com" />
                                            </div>
                                        </div>

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                <label htmlFor="password">Password</label>
                                                <input onChange={this.handlePasswordChange} onKeyPress={this.handleKeyPress} className="form-control" type="password" required="" id="password" placeholder="Enter your password" />
                                            </div>
                                        </div>
                        
                                        <div className="form-group account-btn text-center m-t-10">
                                            <div className="col-xs-12">
                                                <button type="button" onClick={this.props.onSubmit} disabled={this.props.isDisabled} className="btn btn-lg btn-primary btn-block">Sign Up</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="clearfix"></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }

}

RegisterForm.propTypes = {
    onSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    isDisabled: PropTypes.bool
}