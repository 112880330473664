import React, { PropTypes } from 'react';
import { Link } from 'react-router';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';


const styles = {
  container: {
    textAlign: 'center',
    paddingTop: 200,
  },
  button: {
    margin: 12
  },
  errorMessage: {
    color:'red'
  }
};


export default class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    
    // Bind methods to this class so "this" works as expected
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  
  }
  
  /**
   * Event Handlers
   */
  handleEmailChange(e) {
    this.setState({email: e.target.value});  
  }
  handlePasswordChange(e) {
    this.setState({password: e.target.value});  
  } 

  get formBody() {
      if(this.props.userId) {
        return(
            <div className="form-group m-b-20">
                <div className="col-xs-12">
                    <label htmlFor="password">New Password</label>
                    <input onChange={this.handlePasswordChange} className="form-control" type="password" id="password" required="" placeholder="Something Secret" />
                </div>
            </div>
        );
      }
      return(
        <div className="form-group m-b-20">
            <div className="col-xs-12">
                <label htmlFor="emailaddress">Email Address</label>
                <input onChange={this.handleEmailChange} className="form-control" type="email" id="emailaddress" required="" placeholder="me@tappedinevents.com" />
            </div>
        </div>
      );
  }

  
  get successMessage() {
      if(this.props.requestSent) {
        return(
            <div className="form-group m-b-20">
                <div className="col-xs-12">
                    <div className="alert alert-success alert-white">
                        An email has been sent to this account with a link to reset your password. <Link to={`/login`}>Log In</Link>
                    </div>
                </div>
            </div>
        );
      } else if(this.props.passwordUpdated) {
        return(
            <div className="form-group m-b-20">
                <div className="col-xs-12">
                    <div className="alert alert-success">
                        Password Updated Successfully. <Link to={`/login`}>Log In</Link>
                    </div>
                </div>
            </div>
        );          
      }

      return null;
  }

  render() {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">

                        <div className="wrapper-page">

                            <div className="m-t-40">

                                <div className="account-content">
                                    <form className="form-horizontal" action="#">


                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                  <h4>Reset Password</h4>
                                            </div>
                                        </div>

                                        <div className="form-group m-b-20">
                                            <div className="col-xs-12">
                                                  { this.props.errorMessage !== null ? <div style={styles.errorMessage}>{this.props.errorMessage}</div> : null }
                                            </div>
                                        </div>

                                        {this.formBody}

                                        <div className="form-group account-btn text-center m-t-10">
                                            <div className="col-xs-12">
                                                <button type="button" onClick={this.props.onSubmit} disabled={this.props.isDisabled} className="btn btn-lg btn-primary btn-block">Reset Password</button>
                                            </div>
                                        </div>


                                        {this.successMessage}


                                    </form>

                                    <div className="clearfix"></div>

                                </div>
                            </div>
                            <div className="row m-t-50">
                                <div className="col-sm-12 text-center">
                                    <p className="text-muted">Don't have an account? <Link to={`/signup`}>Sign Up</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }

}

ResetPasswordForm.propTypes = {
    onSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    isDisabled: PropTypes.bool,
    requestSent: PropTypes.bool,
    userId: PropTypes.string, 
    token: PropTypes.string,
    passwordUpdated: PropTypes.bool
}