import React, { PropTypes } from 'react';

import TextInput from './textInput';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import Divider from 'material-ui/Divider';
import Paper from 'material-ui/Paper';

import MenuItem from 'material-ui/MenuItem';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import {grey400, grey800 } from 'material-ui/styles/colors';


import { EVENT_ATTRIBUTE_TYPES } from '../../constants/eventTypes';

var DropTarget = require('react-dnd').DropTarget;



const styles = {
  input: {
      marginRight:5
  },
  select: {
    width: 120
  },
  paperContainer: {
    display: 'block',
    maxWidth:380
  },
  paper : {
    paddingLeft: 10,
    paddingBottom: 10,
    margin: 10,
    display: 'inline-block'    
  },
  mappingLabel: {
    fontWeight:'bold'
  },
  mappedIcon: {
    width:16,
    height:16
  },
  attrMappingContainer: {
    padding:2
  }
};

const ItemTypes = {
    ROW : 'ROW'
};

var attributeTarget = {
  drop: function (props, monitor) {
    var column = monitor.getItem();
    if(props.onAddMapping) {
       props.onAddMapping(column,props.attribute);
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class Attribute extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Select DropDown for Attribute Type
   */
  get eventAttributeType() {
    var menuItems = [];
    for(var attributeType in EVENT_ATTRIBUTE_TYPES) {
      menuItems.push(
        <MenuItem key={attributeType} value={attributeType} primaryText={EVENT_ATTRIBUTE_TYPES[attributeType]}/>
      );
    }
    return (
        <SelectField 
            floatingLabelText="Attribute Type"
            floatingLabelFixed={true}
            hintText="Select an Attribute Type"
            value={this.props.attribute.attributeType}
            onChange={(sel,idx,val)=> { this.props.onAttributeTypeChange(this.props.attribute,val) }}
          >
          {menuItems}
        </SelectField>
    );
  }
  
  render() {
    let attributeId = this.props.attribute.attributeId;        
    let attributeName = this.props.attribute.attributeName;
    let attributeType = this.props.attribute.attributeType; 
    let attributeTypeSelect = this.eventAttributeType;
    
    let removeIcon = <IconButton 
                  tooltip="Remove Attribute" 
                  tooltipPosition="top-right" 
                  onClick={() => this.props.onRemove(this.props.attribute)}>
                  <NavigationClose 
                      color={grey400} 
                      hoverColor={grey800}
                  />
              </IconButton>;   
              
    let mappingContainer = <div className="attribute-mapping" style={styles.attrMappingContainer}>
              <span>Mapped to</span> <span style={styles.mappingLabel}>{(this.props.attribute.mappedColumn) ? this.props.attribute.mappedColumn.fieldName : ''}</span>
            </div>;

    // Return the Component               
    return this.props.connectDropTarget(
      
      <div style={styles.paperContainer} >
      <Paper style={styles.paper} zDepth={1} rounded={false} >
      <div>
        <TextInput
            style={styles.input}
            labelText="Attribute Name"
            disabled={this.props.attribute.isSystemAttribute}
            underlineShow={this.props.attribute.isSystemAttribute ? false : true }
            value={this.props.attribute.attributeName}
            onChange={(e) => this.props.onValueChange(e,this.props.attribute) } 
        />
       
          { this.props.onRemove ? removeIcon : null }

          </div>
          <div>
            {(this.props.attribute.isSystemAttribute) ? null : attributeTypeSelect}
          </div>

          { (this.props.attribute.mappedColumn) || (this.props.isOver) ? mappingContainer : null }

          </Paper>  
          </div> 
    );
  }
}

Attribute.propTypes = {
    attribute: PropTypes.object,
    onRemove: PropTypes.func,
    isOver: PropTypes.bool,
    onAddMapping: PropTypes.func,
    onValueChange: PropTypes.func,
    onAttributeTypeChange: PropTypes.func
}

module.exports = DropTarget(ItemTypes.ROW, attributeTarget, collect)(Attribute);