import React, { PropTypes } from 'react';
import Dropzone  from 'react-dropzone';

import Paper from 'material-ui/Paper';
import CloudUpload from 'material-ui/svg-icons/file/cloud-upload';
import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import CircularProgress from 'material-ui/CircularProgress';


const iconStyles = {
  width: '60px',
  height:'60px'
};

const paperStyle = {
  height: '90%',
  width: '90%',  
  textAlign: 'center',
  display: 'inline-block'
};



const circularProgress = (
  <div>
    <h5>Uploading</h5>
    <CircularProgress size={80} thickness={5} />
  </div>
);

export default class DropTarget extends React.Component {
  constructor(props) {
    super(props);
  }

  dropTarget() {
      return (<div>
        <h4 style={{marignTop:'50px'}}>Upload a File</h4> 
        <div>Upload an Excel File that contains a row for each Guest</div>
        { (this.props.fileUploadError) ? <AlertErrorOutline style={iconStyles} /> : <CloudUpload style={iconStyles}  /> }  
            <div>{this.props.fileUploadError}</div>
            
        </div>
      );
  }
 

  render() {  

    let content = (this.props.isUploadingFile) ? circularProgress : this.dropTarget() ;
    let cursorStyle = (this.props.isUploadingFile) ? {cursor:'default', textAlign:'center'} : {cursor:'pointer', textAlign:'center'};

    const dropTarget = <div>
              <Dropzone 
                    className="dropZoneStyle" 
                    activeClassName="activeDropZoneStyle" 
                    multiple={false} 
                    ref="dropzone" 
                    onDrop={this.props.handleFileUpload} 
                    disableClick={this.props.isUploadingFile} 
                    style={cursorStyle}      
              >
                {content}                    
              </Dropzone>   
        </div>;
    return dropTarget;
  }
}

DropTarget.propTypes = {    
    open: React.PropTypes.bool,
    handleFileUpload: PropTypes.func,
    isUploadingFile: PropTypes.bool,
    fileUploadError: PropTypes.string
}