import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../../actions';

import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';


import UserProfileBlock from '../../components/common/userProfileBlock';
import { USER_ROLE } from '../../constants/eventTypes';

class SalesforceOAuth extends React.Component {

  constructor() {
    super();        
    this.handleCloseWindow = this.handleCloseWindow.bind(this);
  }
  
  handleCloseWindow() {
    window.close();
  }

  componentWillReceiveProps(nextProps)  {
    if((!this.props.auth.userId) && nextProps.auth.userId) {
        // Parse code from query string ?code=1234....
        var code = this.getParameterByName('code');
        var userId = nextProps.auth.userId;
        this.props.actions.requestTokenFromCode(userId, code);
    }
  }

  getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  get closeButton() {
    return(
      <RaisedButton 
        label="Close Window" 
        style={{margin:12}} 
        onTouchTap={this.handleCloseWindow}
      />      
    );
  }

  get errorDialog() {
    if(this.props.auth.salesforceAuthError) {
        var message = this.props.auth.salesforceAuthError.toString();
        return(
          <div>
            <div className="alert alert-danger alert-white" style={{margin:'20px',padding:'20px'}}>
                {message}
            </div>            
            {this.closeButton}
          </div>
        );
    }
    return null;
  }

  get salesforceSuccess() {
    var salesforceDetails = this.props.auth.salesforceAuth;
    if(salesforceDetails) {
        var refreshToken = salesforceDetails.refreshToken;
        var displayToken = `${refreshToken.substring(0,5)}********` ;      
        console.log(displayToken);
        return (
          <div style={{paddingLeft:30}}>  
          <h4>Salesforce Connection Successful</h4>      
            <div><span>Salesforce Url</span> <span style={{color:'#d0d0d0'}}> {salesforceDetails.instanceUrl}</span></div>                       
            <div><span >Refresh Token</span> <span style={{color:'#d0d0d0'}}> {displayToken}</span></div>
            {this.closeButton}
          </div>
        );
    } else {
      return null;
    }
  }



  get salesforceStatus() {
    
    var currentOrg = this.props.auth.currentOrganization;
    var organizationName = '';
    var userRole = 0;
    var userRoleName = '';
    var userName = this.props.auth.email;

    let pending = <CircularProgress />;
    let complete = this.salesforceSuccess;

    let content = (this.props.auth.fetchingSalesforceAuth) ? pending : complete;        

    for(var orgId in this.props.auth.organizations) {                        
        if(orgId == currentOrg) {
            var org = this.props.auth.organizations[orgId];
            organizationName = org.organizationName;
            userRoleName = USER_ROLE[org.userRole];
            break;
        }
    }    
    return (
        <div>
            <UserProfileBlock 
                organizationName={organizationName}
                userRole={userRoleName}
                userName={userName}
            />

            {content}

            {this.errorDialog}
        </div>

    );
  }

  render() {
    return this.salesforceStatus;
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(SalesforceOAuth));