


import { USER_LIST_REQUEST, USER_LIST_RESPONSE, USER_LIST_ERROR, ADD_USER_REQUEST,ADD_USER_SUCCESS, ADD_USER_ERROR,
    ADD_USER_OPEN_DIALOG, ADD_USER_CLOSE_DIALOG,
    DELETE_USER_ERROR, DELETE_USER_SUCCESS, DELETE_USER_CLOSE_DIALOG, DELETE_USER_OPEN_DIALOG, DELETE_USER_REQUEST,
    RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SENT, RESET_PASSWORD_REQUEST_ERROR, USER_PASSWORD_UPDATED,
    ADD_ORG_REQUEST, ADD_ORG_SUCCESS, ADD_ORG_ERROR    
 } from '../constants/actionTypes';


const initialState = {
    fetchingUsers: false, 
    userList: [],
    newOrgReference: null,
    addingOrg: false,
    addingOrgError: null,
    userListError: null,
    addingUser: false, 
    addingUserError: null,
    addingUserResult: null,
    addingUserDialogOpen: false,
    deletingUser: false, 
    deletingUserError: null, 
    deletingUserDialogOpen: false,
    sendingPasswordResetRequest: false, 
    resetPasswordRequestError: null,
    resetPasswordRequestSent: null,
    userPasswordUpdated: false     
};

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {

    case ADD_ORG_REQUEST:
     return mergeState(state, {
        addingOrg: true,
        newOrgReference: null,
        addingOrgError: null
      });      
    case ADD_ORG_SUCCESS:
     return mergeState(state, {
        addingOrg: false,
        newOrgReference: action.payload,
        addingOrgError: null
      });      
    case ADD_ORG_ERROR:
     return mergeState(state, {
        addingOrg: false,
        newOrgReference: null,
        addingOrgError: action.payload
      });  

    case USER_LIST_REQUEST:
     return mergeState(state, {
        fetchingUsers: true,
        userListError: null
      });
    case USER_LIST_ERROR:
     return mergeState(state, {
        fetchingUsers: false,
        userListError: action.payload
      });
    case USER_LIST_RESPONSE:
     return mergeState(state, {
        fetchingUsers: false,
        userListError: null,
        userList: action.payload
      });         
    case ADD_USER_OPEN_DIALOG:
     return mergeState(state, {
        addingUserDialogOpen: true
      });          
    case ADD_USER_CLOSE_DIALOG:
     return mergeState(state, {
        addingUserDialogOpen: false,
        addingUserResult: null,
        addingUser: false
      });                
    case ADD_USER_REQUEST:
     return mergeState(state, {
        addingUser: true,
        addingUserError: null,
        addingUserResult: null
      });
    case ADD_USER_SUCCESS:
     return mergeState(state, {
        addingUser: true,
        addingUserError: null,
        addingUserResult: action.payload
      });      
    case ADD_USER_ERROR:
     return mergeState(state, {
        addingUser: false,
        addingUserError: action.payload,
        addingUserResult: null
      });      

    case DELETE_USER_REQUEST:
     return mergeState(state, {
        deletingUser: true,
        deletingUserError: null
      });  
    case DELETE_USER_SUCCESS:
     return mergeState(state, {
        deletingUser: false,
        deletingUserError: null
      });  
    case DELETE_USER_ERROR:
     return mergeState(state, {
        deletingUser: false,
        deletingUserError: action.payload
      });   
    case DELETE_USER_OPEN_DIALOG:
     return mergeState(state, {
        deletingUserDialogOpen: true
      });   
    case DELETE_USER_CLOSE_DIALOG:
     return mergeState(state, {
        deletingUserDialogOpen: false        
      });   


    case RESET_PASSWORD_REQUEST:
     return mergeState(state, {
        sendingPasswordResetRequest: true, 
        resetPasswordRequestError: null,
        resetPasswordRequestSent: false,
        userPasswordUpdated: false     
      });      
    case RESET_PASSWORD_REQUEST_SENT: // Successfull Sending of reset email
     return mergeState(state, {
        sendingPasswordResetRequest: true, 
        resetPasswordRequestError: null,
        resetPasswordRequestSent: true   ,
        userPasswordUpdated: false     
      });  
    case RESET_PASSWORD_REQUEST_ERROR: 
     return mergeState(state, {
        sendingPasswordResetRequest: false, 
        resetPasswordRequestError: action.payload,
        resetPasswordRequestSent: false,
        userPasswordUpdated: false    
      });  
    case USER_PASSWORD_UPDATED:
     return mergeState(state, {
        sendingPasswordResetRequest: false, 
        resetPasswordRequestError: null,
        resetPasswordRequestSent: false,
        userPasswordUpdated: true    
      });      
    default:
      return state;
  }
}

function mergeState(state, obj) {
      return Object.assign( {}, state, obj);
}
