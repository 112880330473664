import React from 'react';
import { Router, Route, IndexRoute, Redirect} from 'react-router';

import RequireAuth from './containers/RequireAuth';

import PageContainer from './containers/PageContainer';
import LoginContainer from './containers/LoginContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';

import RegisterContainer from './containers/RegisterContainer';
import FileListContainer from './containers/FileListContainer';
import CreateEventContainer from './containers/CreateEventContainer';
import EventTemplateListContainer from './containers/EventTemplateListContainer';
import EventListContainer from './containers/EventListContainer';
import EventContainer from './containers/EventContainer';
import LeftNavigationPageContainer from './containers/LeftNavigationPageContainer';
import UserListContainer from './containers/userListContainer';
import UserDeviceContainer from './containers/userDeviceContainer';
import ReportContainer from './containers/ReportContainer';

import SalesforceOAuth from './containers/oauth/SalesforceOAuth';

import PrivacyPolicy from './components/static/privacyPolicy';


export const makeRoutes = (secure) => {
  return (

  <Route>
        
      <Route component={PageContainer} >

          <IndexRoute component={EventTemplateListContainer} onEnter={secure}/>

          <Route path="/login" component={LoginContainer} />
          <Route path="/resetpassword" component={ResetPasswordContainer} />
          <Route path="/resetpassword/:userId/:token" component={ResetPasswordContainer} />
          <Route path="/signup" component={RegisterContainer} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />

          <Route path="/oauth/sfdc" component={SalesforceOAuth} onEnter={secure} />

          <Route component={LeftNavigationPageContainer} >

              <Route path="/files" component={FileListContainer} onEnter={secure}>
                <Route path=":fileId" component={FileListContainer} onEnter={secure} />
              </Route>

              <Route path="/" component={EventListContainer} onEnter={secure}  />
              <Route path="/events" component={EventListContainer} onEnter={secure} /> 
              <Route path="/event/:eventId" component={EventContainer} onEnter={secure} /> 

              <Route path="/reports" component={ReportContainer} onEnter={secure} />
                              
              <Route path="/templates" component={EventTemplateListContainer} onEnter={secure}/> 

                <Route path="/users" component={UserListContainer} onEnter={secure}/> 
                <Route path="/devices" component={UserDeviceContainer} onEnter={secure}/> 

              <Route path="/template" component={CreateEventContainer} onEnter={secure}> 
                  <Route path=":eventConfigId" component={EventTemplateListContainer} onEnter={secure} /> 
              </Route>
          </Route>

      </Route>
    </Route>
  );
}
export default makeRoutes;