import React, { PropTypes } from 'react';
import ReactDOM from 'react-dom';

import TextInput from './textInput';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import Divider from 'material-ui/Divider';
import Paper from 'material-ui/Paper';

import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';

import Chip from 'material-ui/Chip';

import MenuItem from 'material-ui/MenuItem';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import NavigationCancel from 'material-ui/svg-icons/navigation/cancel';
import ActionSearch from 'material-ui/svg-icons/action/search';
import {grey500} from 'material-ui/styles/colors';
import {grey400, grey800 } from 'material-ui/styles/colors';


import { EVENT_ATTRIBUTE_TYPES } from '../../constants/eventTypes';

var DropTarget = require('react-dnd').DropTarget;

const ItemTypes = {
    ROW : 'ROW'
};

var attributeTarget = {
  drop: function (props, monitor) {
    var column = monitor.getItem();    
    console.log('On Add Mapping', props.onAddMapping);
    if(props.onAddMapping) {
        props.onAddMapping(column,props.attribute);
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class AttributeEmptyTableRow extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return(          
      <TableRow 
        style={(this.props.isOver) ? {backgroundColor:'#f8f8f8'}  : {backgroundColor:'#fafad2'}  }
        selectable={false} 
        ref={instance => this.props.connectDropTarget(ReactDOM.findDOMNode(instance))} 
      >
        <TableRowColumn colSpan="5">
            Drop a field here to add an attribute
        </TableRowColumn>
      </TableRow>
    );
  }
}

AttributeEmptyTableRow.propTypes = {
    isOver: PropTypes.bool,
    onAddMapping: PropTypes.func
}
module.exports = DropTarget(ItemTypes.ROW, attributeTarget, collect)(AttributeEmptyTableRow);