import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import FileList from '../components/fileManagement/fileList';
import ColumnList from '../components/userfiles/columnlist';

export default class FileListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFileClick = this.handleFileClick.bind(this);
    this.closeColumnList = this.closeColumnList.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
  }

  componentDidMount() {
    var orgId = this.props.organizationId;
    var selectedFileId = (this.props.mappedFileId) ? this.props.mappedFileId : null;
    this.props.actions.requestFileList(orgId,selectedFileId);
    if(this.props.uploadServiceChecked === false) {
      this.props.actions.checkUploadServerStatus();
    }
  }

  componentWillReceiveProps() {
    //Set proper selected file based on the fileId in this.props.params
    //console.log(this.props.params);
  }

  handleFileUpload(files) {
    this.props.actions.uploadFile(this.props.organizationId,files);
  }

  // fileIds is an array of FileIds
  handleFileDelete(fileIds) {
    this.props.actions.deleteFile(this.props.organizationId,fileIds);
  }

  handleFileClick(file) {
    if(this.props.customFileSelectHandler) {
      this.props.customFileSelectHandler(file);
    } 
  }

  closeColumnList() {
    this.props.actions.closeUserFile();
  }
  

  get fileList() {
    return (
       <FileList 
            files={this.props.fileList} 
            handleFileUpload={this.handleFileUpload}
            handleFilesDelete={this.handleFileDelete}
            fileUploadError={this.props.fileUploadError}
            fetchingFiles={this.props.fetchingFiles}
            handleFileClick={this.handleFileClick}
            uploadAvailable={this.props.uploadServerAvailable}
            hideHeader={this.props.hideHeader}
            hideCheckBox={this.props.hideCheckBox}
            isDeleteingFile={this.props.isDeleteingFile}
            fileDeleteError={this.props.fileDeleteError}
        />
    );
  }

  get columnList() {
    var file = this.props.selectedFile;
    var columns = this.props.selectedFile.columns;
    return (
      <ColumnList 
        columns={columns}
        fileName={file.originalFileName}
        fileId={file.fileId}
        closeColumnList={this.closeColumnList}
      />
    );
  }

  render() {
      return (this.props.selectedFile === null) ? this.fileList : this.columnList;
  }
}

function mapStateToProps(state) {
  return {
    fetchingFiles: state.userFiles.fetchingFiles, 
    fileList: state.userFiles.fileList,
    selectedFile: state.userFiles.selectedFile,
    organizationId: state.auth.currentOrganization,
    fileUploadError: state.userFiles.fileUploadError,
    uploadServerAvailable: state.userFiles.uploadServerAvailable,
    mappedFileId: state.eventtemplate.mappedFileId,
    uploadServiceChecked: state.userFiles.uploadServiceChecked,
    fileDeleteError: state.userFiles.fileDeleteError, 
    isDeletingFile: state.userFiles.isDeletingFile
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(FileListContainer));

FileListContainer.propTypes = {
    hideCheckBox: PropTypes.bool,
    hideHeader: PropTypes.bool,
    customFileSelectHandler: PropTypes.func
}