
import React, {PropTypes} from 'react';
import { Router, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import LeftNavBar from '../components/common/leftNavBar';

class LeftNavigationPageContainer extends React.Component {

  render() {
    return (
      <div className="page-contentbar">
        <LeftNavBar 
            displayName={this.props.auth.displayName}
            email={this.props.auth.email}
            organizations={this.props.auth.organizations}
            currentOrganization={this.props.auth.currentOrganization}
            history={this.props.history}
        />
        <div id="page-right-content">
            <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    {this.props.children}
                  </div>
                </div>
            </div>
            <div className="footer">
                <div className="pull-right hidden-xs">
                    
                </div>
                <div>
                    <strong>Tappedin Events</strong> - Copyright &copy; 2017
                </div>
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps,mapDispatchToProps)(withRouter(LeftNavigationPageContainer));