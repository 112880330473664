import React, {PropTypes} from 'react';
import { Router } from 'react-router';
import { connect } from 'react-redux';

var DragDropContext = require('react-dnd').DragDropContext;
var HTML5Backend = require('react-dnd-html5-backend');

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { deepOrange500 } from 'material-ui/styles/colors';


//Set the Main Material UO theme to use across the app
const muiTheme = getMuiTheme({
  palette: {
    accent1Color: deepOrange500,
  },
});

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  // class getter
  get content() {
    return (<Router
        routes={this.props.routes}
        history={this.props.history} />)
  }

  render() {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div>
          {this.content}
        </div>
      </MuiThemeProvider>
    )
  }
}

App.propTypes = {
    routes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
  };
}

module.exports = connect(mapStateToProps)(DragDropContext(HTML5Backend)(App));