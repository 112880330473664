import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import LoginForm from '../components/LoginForm';

class LoginContainer extends React.Component {

  constructor() {
    super();
    // Bind methods to this class so "this" works as expected
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentWillMount() {
    //console.log('Context Path',this.context.router.getCurrentPathname());
  }

  componentWillReceiveProps(nextProps)  {
    console.log('Login Props: ', nextProps);
    if(nextProps.hasOwnProperty('auth')) {
      if(nextProps.auth.authenticated === true) {
        // Jump to Home
        this.props.router.push('/');
      }
    }
  }

  handleSubmit(e) {  
      var email = this.refs.loginForm.state.email;
      var pass = this.refs.loginForm.state.password;
      this.props.actions.signInUser(email,pass);
  };

  

  get loginForm() {
    return (
      <LoginForm ref="loginForm" 
        onSubmit = {this.handleSubmit} 
        isDisabled = {this.props.auth.fetchingLogin} 
        errorMessage = {this.props.auth.error}
      />
    );
  }

  render() {
    return this.loginForm;
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginContainer));