/**
 * User File Services 
 * Upload Excel Files
 * Retrive a list of files
 * ...
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import request from 'superagent';
import config from '../../../config';

//Should move this to app state so it's accessible from all componenets and we can inject the DB dependency in 1 place
var db = new FirebaseDbService();

export function selectUserFile(file) {
    return {
        type: actionTypes.USER_FILE_SELECTED,
        payload: file
    };
}

export function closeUserFile() {
    return {
        type: actionTypes.USER_FILE_CLOSED
    };
}

export function checkUploadServerStatus() {
    return function(dispatch) {
        sendServerStatusRequest()
        .then(function() {
            dispatch({type: actionTypes.USER_FILE_UPLOAD_SERVICE_AVAILABLE});
        })
        .catch(function() {
            dispatch({type: actionTypes.USER_FILE_UPLOAD_SERVICE_DOWN});
        });
    };    
}

/**
 * requestFileList
 * Returns the full list of file for an organizationId
 * if selectedFileId is passed in, we also set the file 
 */
export function requestFileList(organizationId,selectedFileId) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.FILE_LIST_REQUEST
    });

    var node = `/tappedin/${organizationId}/admin/eventfiles`;
    db.getValue(node)
    .then(function(snapshot) {
        var filteredSnapshot = [];        
        var selectedFile = (selectedFileId) ? getFileById(selectedFileId,snapshot) : null;
        var payload = {
            files: snapshot,
            selectedFile: selectedFile
        };
        dispatch({
            type: actionTypes.FILE_LIST_RESPONSE,
            payload: payload
        });     
    })
    .catch(function(err) {
        console.error(err);
    });
    //Request Files
  };
}

function getFileById(fileId,files) {
    for(var id in files) {
        if(id == fileId) {
            return files[id];
        }
    }
    return null;
}


// fileIds is an array of Files
export function deleteFile(organizationId, fileIds) {
    return function(dispatch) {
        dispatch({
            type: actionTypes.FILE_DELETE_INPROGRESS
        });
        db.userToken()
        .then(function(token) {
            console.log('Sending Delete Request');
            sendDeleteRequest(token, organizationId, fileIds)
            .then(function(result) {     
                console.log('SEND SUCCESS::',result);                
                dispatch({type: actionTypes.FILE_DELETE_SUCCESS});                      
                dispatch(requestFileList(organizationId));
            })
            .catch(function(err) {
                dispatch({
                    type: actionTypes.FILE_DELETE_ERROR,
                    payload: err.toString()
                });
                // Refresh File List on Error
                dispatch(requestFileList(organizationId));     
            });
        })
        .catch(function(err) {
            console.log('Token Error',err);
            dispatch({
                type: actionTypes.FILE_DELETE_ERROR,
                payload: err.toString()
            });
        });        
    }
}

export function uploadFile(organizationId, files, eventTemplate) {
    return function(dispatch) {
        eventTemplate = eventTemplate || null;
        dispatch({
            type: actionTypes.FILE_UPLOAD_INPROGRESS
        });

        if(files && files.length == 0) {
            dispatch({
                type: actionTypes.FILE_UPLOAD_ERROR,
                payload: 'No File Provided from Client'
            });
        } else {
            let file = files[0];             
            db.userToken()
            .then(function(token) {
                sendFileRequest(token, organizationId, file)
                .then(function(fileDescriptor) {                    
                    dispatch({type: actionTypes.FILE_UPLOAD_SUCCESS});
                    if(eventTemplate) {                        
                        eventTemplate.dataSource = fileDescriptor.fileId;
                        // If the template does not yet have a name, pop in the file name 
                        if(!eventTemplate['selectedTemplate'].eventName || eventTemplate['selectedTemplate'].eventName.length == 0) {
                            eventTemplate['selectedTemplate'].eventName = fileDescriptor.originalFileName
                        }
                        var payload = {
                            template: eventTemplate['selectedTemplate'], 
                            file: fileDescriptor
                        };
                        dispatch({
                            type: actionTypes.FILE_ADDED_TO_EVENT_TEMPLATE,
                            payload: payload
                        }); 
                    } else {
                        // Old Event Dispatch
                        dispatch(requestFileList(organizationId));
                    }                    
                })
                .catch(function(err) {
                    dispatch({
                        type: actionTypes.FILE_UPLOAD_ERROR,
                        payload: err.toString()
                    });
                });
            })
            .catch(function(err) {
                dispatch({
                    type: actionTypes.FILE_UPLOAD_ERROR,
                    payload: err.toString()
                });
            });
        }
    };
}





function sendServerStatusRequest() {
    // We don't do this anymore
    return new Promise(function(resolve,reject) {
        resolve(true);
    });
}

function sendFileRequest(token, organizationId, file) {
    var params = {"organizationId": organizationId };
    return new Promise(function(resolve,reject) {
        let request_endpoint = `${config.tappedinApi.host}/upload/event-file`;
        request
        .post(request_endpoint)
        .field('organizationId', organizationId)
        .set('Authorization','Bearer ' + token)
        .attach(file.name, file)
        .end(function(err, res){            
            if(err) {
                reject(err);
            } else {
                resolve(res.body);
            }
        });
    });
}


// FileIds is an array of Ids
function sendDeleteRequest(token, organizationId, fileIds) {        
    var params = {"organizationId": organizationId, "fileIds": fileIds };
    return new Promise(function(resolve,reject) {
        let request_endpoint = `${config.tappedinApi.host}/upload/delete-file`;        
        request
        .post(request_endpoint)
        .send(params)
        .set('Authorization','Bearer ' + token)  
        .set('Accept', 'application/json')      
        .end(function(err, res){            
            if (err || !res.ok) {
                reject(err);
            } else {
                console.log('REQUEST RESDD',res);
                resolve(res.body);
            }
        });
    });    
}
