import React, { PropTypes } from 'react';
import ReactDOM from 'react-dom';

import TextInput from './textInput';
import IconButton from 'material-ui/IconButton';
import SelectField from 'material-ui/SelectField';
import Divider from 'material-ui/Divider';
import Paper from 'material-ui/Paper';

import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';

import Chip from 'material-ui/Chip';

import MenuItem from 'material-ui/MenuItem';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import NavigationCancel from 'material-ui/svg-icons/navigation/cancel';
import ActionSearch from 'material-ui/svg-icons/action/search';
import {grey500} from 'material-ui/styles/colors';
import {grey400, grey800 } from 'material-ui/styles/colors';


import { EVENT_ATTRIBUTE_TYPES } from '../../constants/eventTypes';

var DropTarget = require('react-dnd').DropTarget;

const styles = {
  input: {
      marginRight:5
  },
  select: {
    width: 120
  },
  paperContainer: {
    display: 'block',
    maxWidth:380
  },
  paper : {
    paddingLeft: 10,
    paddingBottom: 10,
    margin: 10,
    display: 'inline-block'    
  },
  mappingLabel: {
    fontWeight:'bold'
  },
  mappedIcon: {
    width:16,
    height:16
  },
  attrMappingContainer: {
    padding:2
  }
};

const ItemTypes = {
    ROW : 'ROW'
};

var attributeTarget = {
  drop: function (props, monitor) {
    var column = monitor.getItem();    
    if(props.onAddMapping) {
        props.onAddMapping(column,props.attribute);
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class AttributeTableRow extends React.Component {
  constructor(props) {
    super(props);
  }


  /**
   * Select DropDown for Attribute Type
   */
  get eventAttributeType() {
    var menuItems = [];
    for(var attributeType in EVENT_ATTRIBUTE_TYPES) {
      menuItems.push(
        <MenuItem key={attributeType} value={attributeType} primaryText={EVENT_ATTRIBUTE_TYPES[attributeType]}/>
      );
    }
    return (
        <SelectField 
            style={{margin:0, height:'45px',display:'block',width:'90%'}}
            floatingLabelText={null}            
            floatingLabelFixed={false}
            hintText="Select an Attribute Type"
            value={this.props.attribute.attributeType}
            disabled={this.props.attribute.isSystemAttribute ? true : false}
            underlineShow={this.props.attribute.isSystemAttribute ? false : true}
            onChange={(sel,idx,val)=> { this.props.onAttributeTypeChange(this.props.attribute,val) }}
          >
          {menuItems}
        </SelectField>
    );
  }
  
  render() {
    let attributeId = this.props.attribute.attributeId;        
    let attributeName = this.props.attribute.attributeName;
    let attributeType = this.props.attribute.attributeType; 
    let attributeTypeSelect = this.eventAttributeType;
    
    let removeIcon = <IconButton  
                  onClick={() => this.props.onRemove(this.props.attribute)}>
                  <NavigationClose 
                      color={grey400} 
                      hoverColor={grey800}
                  />
              </IconButton>;   
                      
    let mappedFieldName = (this.props.attribute.mappedColumn) ? <Chip
          onRequestDelete={() => this.props.onRemoveMapping(this.props.attribute)}>          
          {this.props.attribute.mappedColumn.fieldName}          
        </Chip> : null;

    // Return the Component      
    let isSearchable = (this.props.attribute.isSystemAttribute === false && this.props.attribute.order < 3) ? <ActionSearch color={grey500}/> : null;

    return(          
      <TableRow 
        style={(this.props.isOver) ? {backgroundColor:'#f8f8f8'}  : null  }
        selectable={false} 
        ref={instance => this.props.connectDropTarget(ReactDOM.findDOMNode(instance))} 
      >
        <TableRowColumn style={{width:'30px'}}>
          {isSearchable}          
        </TableRowColumn>
        <TableRowColumn>
          <TextInput
              labelText="Attribute Name"
              disabled={this.props.attribute.isSystemAttribute}
              underlineShow={this.props.attribute.isSystemAttribute ? false : true }
              value={this.props.attribute.attributeName}
              onChange={(e) => this.props.onValueChange(e,this.props.attribute) } 
          />        
        </TableRowColumn>
        <TableRowColumn>
            {(this.props.attribute.isSystemAttribute) ? attributeTypeSelect : attributeTypeSelect}
        </TableRowColumn>
        <TableRowColumn>
          {mappedFieldName}
        </TableRowColumn>
        <TableRowColumn style={{width:'70px'}}>
          {(this.props.attribute.isSystemAttribute) ? null : removeIcon}
        </TableRowColumn>
      </TableRow>
    );
  }
}

AttributeTableRow.propTypes = {
    attribute: PropTypes.object,
    onRemove: PropTypes.func,
    isOver: PropTypes.bool,
    onAddMapping: PropTypes.func,
    onRemoveMapping: PropTypes.func,
    onValueChange: PropTypes.func,
    onAttributeTypeChange: PropTypes.func
}

module.exports = DropTarget(ItemTypes.ROW, attributeTarget, collect)(AttributeTableRow);