import React, { PropTypes } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions';

import FlatButton from 'material-ui/FlatButton';
import UserList from '../components/userManagement/userList';
import SocialPersonAdd from 'material-ui/svg-icons/social/person-add';
import Snackbar from 'material-ui/Snackbar';

import AddUserModal from '../components/userManagement/addUserModal';

import DeleteConfirmModal from '../components/common/deleteConfirmModal';

export default class UserListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleOpenAddUser = this.handleOpenAddUser.bind(this);
    this.handleCancelAddUser = this.handleCancelAddUser.bind(this);
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleAccountDeleteCancel = this.handleAccountDeleteCancel.bind(this);
    this.handleAccountDelete = this.handleAccountDelete.bind(this);

    this.accountToDeleteId = null;
    this.state = {
      resetPasswordMessageOpen: false
    }
  }

  componentDidMount() {
    var orgId = this.props.organizationId;    
    this.props.actions.requestUserList(orgId);    
  }

  componentWillReceiveProps() {
      
  }

  handleMenuSelect(event, child) {   
      var selectedItem = child.props.primaryText;
      switch(selectedItem) {
          case "View Details":

          break;
          case "Delete Account":
            this.accountToDeleteId = child.props.value;
            this.props.actions.openDeleteUserDialog(true);                
          break;
          case "Reset Password":
            console.log('Reset Password');
            var userEmail = child.props.value;            
            this.props.actions.resetUserPassword(userEmail);
            this.setState({resetPasswordMessageOpen: true});


          break;
          default:
      }      
  }
  // Delete 

  handleAccountDelete() {
      var id = this.accountToDeleteId 
      this.props.actions.removeUserFromOrg(id, this.props.organizationId);
      this.handleAccountDeleteCancel();
  }

  handleAccountDeleteCancel() {
      this.accountToDeleteId = null;
      this.props.actions.openDeleteUserDialog(false);      
  }


  handleOpenAddUser() {
    this.props.actions.openUserDialog(true);    
  }

  handleCancelAddUser() {
    this.props.actions.openUserDialog(false);          
  }

  handleAddUser(email, password, userRoleId) {      
      console.log('Adding User',email,password);
      this.props.actions.addUserToOrg(email, password, userRoleId, this.props.organizationId);
  }

  get userListFetchError() {
    if(this.props.userListError != null) {
      return(<div className="row">
              <div className="col-sm-12">      
                  <div className="alert alert-danger alert-white">
                    {this.props.userListError}    
                  </div>                              
              </div>
            </div>
      );
    }
    return null;
  }

  get userAvailableActions() {      
    var guestListIcon = <SocialPersonAdd />;
    var guestListLabel = "Add User";    
      return(
                <div className="pull-right">
                    <div className="pull-right" >                    
                      <FlatButton
                        label={guestListLabel}
                        labelPosition="before"
                        primary={true}   
                        icon={guestListIcon}   
                        onTouchTap={this.handleOpenAddUser}                      
                      />                      
                    </div>
                </div>
      );
  }
 
  get userList() {
    return (
        <div>
          <div className="row">
            <div className="col-sm-12">                    
                <h4 style={{display:'inline-block'}}>Users</h4>   
                {this.userAvailableActions}                 
              </div>
          </div>        
          
          {this.userListFetchError}

          <div className="row">
            <div className="col-sm-12">                              
                <UserList 
                        fetchingUsers={this.props.fetchingUsers}
                        users={this.props.userList}
                        userListError={this.props.userListError}
                        handleMenuSelect={this.handleMenuSelect}
                        currentUserId={this.props.userId}
                />
            </div>
          </div>
            <AddUserModal 
                addingUser={this.props.addingUser}
                addingUserError={this.props.addingUserError}
                handleCancel={this.handleCancelAddUser}
                handleAddUser={this.handleAddUser}
                open={this.props.addingUserDialogOpen}
                disabled={this.props.addingUser}
                addingUserResult={this.props.addingUserResult}
            />

            <DeleteConfirmModal 
                title="Remove Account from Organization"
                message="Removing an account will revoke all access to this organizations' events."
                isOpen={this.props.deletingUserDialogOpen}
                handleDelete={this.handleAccountDelete}
                handleCancel={this.handleAccountDeleteCancel}
            />

          <Snackbar
            open={this.state.resetPasswordMessageOpen}
            message="Reset Password Email Sent"
            autoHideDuration={4000}
          />

        </div>
    );
  }

  render() {
      return this.userList;
  }
}



function mapStateToProps(state) {
  return {
    fetchingUsers: state.userManagement.fetchingUsers, 
    userList: state.userManagement.userList,
    userListError: state.userManagement.userListError,
    organizationId: state.auth.currentOrganization,
    userId: state.auth.userId,
    addingUser: state.userManagement.addingUser, 
    addingUserError: state.userManagement.addingUserError,
    addingUserResult: state.userManagement.addingUserResult,
    addingUserDialogOpen: state.userManagement.addingUserDialogOpen,
    deletingUser: state.userManagement.deletingUser, 
    deletingUserError: state.userManagement.deletingUserError, 
    deletingUserDialogOpen: state.userManagement.deletingUserDialogOpen
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}
module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(UserListContainer));

