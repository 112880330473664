import React, { PropTypes } from 'react';
import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';

import ActionInfo from 'material-ui/svg-icons/action/info';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import Avatar from 'material-ui/Avatar';
import ActionEvent from 'material-ui/svg-icons/action/event';

import ColumnRow from './columnRow';
import { blue500 } from 'material-ui/styles/colors';

const styles = {
  closeIcon: {
    cursor: 'pointer'
  }
}

export default class ColumnList extends React.Component {
  constructor(props) {
    super(props);
  }
  
 
  render() {
    
    var listItems = [], currItem, sampleData;
    for(var columnId in this.props.columns) {
        currItem = this.props.columns[columnId];
        var listItem = <ColumnRow
            key={currItem.columnId}
            column={currItem}
            fileId={this.props.fileId}
        />;
        listItems.push(listItem);
    } 
    const colList = 
            <List>
              <ListItem
                key="test-123"
                disabled={true}
                leftAvatar={<Avatar icon={<ActionEvent />} backgroundColor={blue500} />}
                rightIcon={<NavigationClose onClick={this.props.closeColumnList} style={styles.closeIcon} />}
                primaryText={this.props.fileName}
                />
                <Divider />
                {listItems}
            </List>;  
    return colList;
  }
  
  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }
}

ColumnList.propTypes = {
    columns: PropTypes.object,
    fileName: PropTypes.string,
    fileId: PropTypes.string,
    closeColumnList: PropTypes.func
}