/**
 * Event Template CRUD Actions
 */
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import { hashHistory } from 'react-router';

/**
 * Re-use server side classes for creating events and mappings
 */
import { UserEventConfig, EventConfigAttribute } from '../../../../tappedin-api/dist/es6/event-file-manager/userEventConfig';
import { DataSourceFieldMapping } from '../../../../tappedin-api/dist/es6/event-file-manager/datasource/dataSource';

// Create a new instance to ref the Firebase DB
var db = new FirebaseDbService();

export function refreshEventTemplates(orgId) {
  var self = this;
  return function(dispatch) {
    var node = `/tappedin/${orgId}/admin/eventconfigs`;
    db.getValue(node)
    .then(function(snapshot) {
        dispatch({
          type: actionTypes.REFRESH_EVENT_TEMPLATES,
          payload: snapshot
        });        
    })
    .catch(function(err) {
        console.log('Error',err);
    });
  };    
}

export function createEventTemplate(orgId) {
  var eventConfig = new UserEventConfig();
  eventConfig = initNewEventConfig(eventConfig);
  eventConfig.organizationId = orgId;
  eventConfig.organizationName = 'Org Name Placeholder';
  return {
    type: actionTypes.CREATE_EVENT_TEMPLATE,
    payload: eventConfig
  }     
}

/**
 * Query for an Event Template based on OrgID and Template ID
 */
export function selectEventTemplate(orgId, eventConfigId) {
  var self = this;   
  return function(dispatch) {
     dispatch({
       type: actionTypes.LOADING_EVENT_TEMPLATE
     });
      var node = `/tappedin/${orgId}/admin/eventconfigs/${eventConfigId}`;
      console.log('Fetching Config Node :: ', node);
      db.getValue(node)
      .then(function(result) {
        if(result) {

          var eventConfig = eventConfigFromJson(result);
          dispatch({
            type: actionTypes.LOAD_EVENT_TEMPLATE,
            payload: eventConfig
          });

          var selectedTemplateWrapper = {selectedTemplate: eventConfig};
          if(eventConfig.dataSource) {
            // Query for the file 
            var node = `/tappedin/${orgId}/admin/eventfiles/${eventConfig.dataSource}`;
            db.getValue(node)
            .then(function(file) {
               dispatch(addFiletoTemplate(file, selectedTemplateWrapper));
            })
            .catch(function(err) {
              // Failed to load file
            });                        
          }          
        } else {
          dispatch({
            type: actionTypes.TEMPLATE_RETRIEVAL_ERROR,
            payload: `No Event Template found for Id ${eventConfigId}`
          });  
        }
      })
      .catch(function(err) {
        dispatch({
          type: actionTypes.TEMPLATE_RETRIEVAL_ERROR,
          payload: err
        });  
      }); 
  };      
}


/**
 * Save the Event Template to Firebase AND open the Publish Dialog
 */
export function saveEventTemplate(orgId, eventTemplate) {
  var self = this;
  return function(dispatch) {
    //Dispatch a SAVE_EVENT_REQUEST_SENT
    dispatch({
      type: actionTypes.SAVE_EVENT_REQUEST_SENT
    });

    if( !eventTemplate.eventName || eventTemplate.eventName.length == 0) {
      dispatch({
      type: actionTypes.SAVE_EVENT_VALIDATION_ERROR,
        payload: "Event Name Required"
      });     
      return; 
    }

    var configId = eventTemplate.id;
    var savePath = `/tappedin/${orgId}/admin/eventconfigs/${configId}`;
    
    db.set(savePath,eventTemplate)
    .then(function(result) {
      dispatch({
        type: actionTypes.SAVE_EVENT_TEMPLATE,
        payload: eventTemplate
      }); 
    })
    .catch(function(err) {
      //Throw some error
      console.log('Save Template Error',err); 
      dispatch({
        type: actionTypes.SAVE_EVENT_ERROR,
        payload: err
      }); 
    });  
  };
}

export function updateTemplateEventName(value,eventTemplate) {
  var payload = {
    template: eventTemplate, 
    value: value
  };
  return {
    type: actionTypes.UPDATE_TEMPLATE_EVENT_NAME,
    payload: payload
  };
}

export function updateEventTemplate(eventTemplate) {
  return {
    type: actionTypes.UPDATE_EVENT_TEMPLATE,
    payload: eventTemplate
  };
}

/**
 * Add Attribute to Event Template
 */
export function addAttributeToEventTemplate(eventTemplate) {
      var attr = new EventConfigAttribute('');
      console.log('Adding this attr',attr);
      eventTemplate.addEventAttribute(attr);
      return {
        type: actionTypes.UPDATE_EVENT_TEMPLATE,
        payload: eventTemplate
      };
}

/**
 * Add Field Mapping to Attribute
 */
 export function addColumnMapping(column,attribute,eventTemplate) {
   //Confirm that this isn't a mapping across files
   var mappedColumn = new DataSourceFieldMapping(column.fileId,column.columnId,column.columnName);
      if(eventTemplate.dataSource && eventTemplate.dataSource !== column.fileId) {
       return {
          type: actionTypes.EVENT_COLUMN_MAPPING_ERROR,
          payload: "You can't map fields from multiple files...yet :("
        }; 
      } else {
        attribute.mappedColumn = mappedColumn;
        //Helper : Set the Attr Name to the Col Name if it is empty
        if(attribute.hasOwnProperty('attributeName') && attribute.attributeName.length == 0) {
          attribute.attributeName = column.columnName;
        }
        eventTemplate.dataSource = column.fileId;
        return {
          type: actionTypes.UPDATE_EVENT_TEMPLATE,
          payload: eventTemplate
        }; 
      }     
  }

export function addAttributeWithColumnMapping(column, eventTemplate) {
  if(eventTemplate.dataSource && eventTemplate.dataSource !== column.fileId) {
    return {
      type: actionTypes.EVENT_COLUMN_MAPPING_ERROR,
      payload: "You can't map fields from multiple files...yet :("
    }; 
  } else {
    var attr = new EventConfigAttribute(column.columnName);  
    var mappedColumn = new DataSourceFieldMapping(column.fileId,column.columnId,column.columnName);  
    attr.mappedColumn = mappedColumn;
    eventTemplate.dataSource = column.fileId;    
    eventTemplate.addEventAttribute(attr);
    return {
      type: actionTypes.UPDATE_EVENT_TEMPLATE,
      payload: eventTemplate
    };  
  }  
}

/**
 * Remove Field Mapping from Attribute
 */

// EVENT_COLUMN_MAPPING_REMOVED
  export function removeColumnMapping(column,attribute) {

  }

  // Remove all Column Mappings from person and event attributes
  export function removeAllColumnMappings(eventTemplate) {      
      eventTemplate.removeDataSource();      
      var payload = {
        template: eventTemplate
      };
      return {
        type: actionTypes.FILE_REMOVED_FROM_EVENT_TEMPLATE,
        payload: payload
      }; 
  }

  export function clearMappingErrors() {
    console.log('Clearning Mapping Errors');
    return {
      type: actionTypes.EVENT_COLUMN_MAPPING_ERROR_CONFIMED
    }
  }

  export function addFiletoTemplate(file, eventTemplate) {
    console.log('adding file to template:',file);
        eventTemplate.dataSource = file.fileId;
        // If the template does not yet have a name, pop in the file name 
        if(!eventTemplate['selectedTemplate'].eventName || eventTemplate['selectedTemplate'].eventName.length == 0) {
            eventTemplate['selectedTemplate'].eventName = file.originalFileName
        }
        var payload = {
          template: eventTemplate['selectedTemplate'], 
          file: file
        };
        return {
          type: actionTypes.FILE_ADDED_TO_EVENT_TEMPLATE,
          payload: payload
        }; 
  }

  export function openFileDetailsDrawer() {
        return {
          type: actionTypes.OPEN_FILE_DETAILS_DRAWER          
        };     
  }
  export function closeFileDetailsDrawer() {
        return {
          type: actionTypes.CLOSE_FILE_DETAILS_DRAWER          
        };     
  }
  export function openFileSelectionDialog() {
        return {
          type: actionTypes.OPEN_FILE_SELECTION_DIALOG          
        };     
  }
  export function closeFileSelectionDialog() {
        return {
          type: actionTypes.CLOSE_FILE_SELECTION_DIALOG          
        };     
  }

/**
 * Helper Functions for dealing with config/templates
 */
  function eventConfigFromJson(eventConfig) {
      var self = this;
      var ec = UserEventConfig.fromData(eventConfig);
      return ec;
  }

  function eventAttributeFromJson(attrs) {
    var eventAttrs = [];
    for(var attr of attrs) {
        var eventAttr = new EventConfigAttribute(attr.attributeName);
        eventAttr.attributeId = attr.attributeId;
        eventAttr.attributeType = attr.attributeType;
        eventAttr.isSystemAttribute = attr.isSystemAttribute;
        eventAttr.order = attr.order;
        eventAttr.mappedColumn = (attr.mappedColumn) ? attr.mappedColumn : null;
        eventAttrs.push(eventAttr);
    }
    return eventAttrs;
  }

  function initNewEventConfig(eventConfig) {
    var fn = new EventConfigAttribute('firstName');
        fn.isSystemAttribute = true;
        eventConfig.addPersonAttribute(fn);
    var ln = new EventConfigAttribute('lastName');
        ln.isSystemAttribute = true;
        eventConfig.addPersonAttribute(ln);
    var ssk = new EventConfigAttribute('sourceSystemKey');
        ssk.isSystemAttribute = true;
        eventConfig.addPersonAttribute(ssk);     
    return eventConfig; 
  }  




