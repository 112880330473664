/**
 * Live Event Actions
 * ie.  List Live Events
 *      Update some attributes
 *      Query Event Stats for Admin
 * 
 *  Action Types
 * 
 *  REFRESH_EVENT_LIST
 *  LOADING_EVENT
 *  REFRESH_EVENT
 *  EVENT_RETRIEVAL_ERROR
 * 
 *  TICKET_ADDED
 *  TICKET_REMOVED
 *  TICKET_CHECKIN
 *  TICKET_CHECKOUT
 *  
 * 
 */

import request from 'superagent';
import * as actionTypes from '../constants/actionTypes';
import FirebaseDbService from '../services/firebaseDbService';
import config from '../../../config';


// Create a new instance to ref the Firebase DB
var db = new FirebaseDbService();


/**
 * Return a list of Events for an Organization
 */
export function refreshEventList(orgId,status) {
  var self = this;    
  return function(dispatch) {
    var node = `/tappedin/${orgId}/eventlist`;
    db.queryByValue(node,"eventStatus", status)
    .then(function(snapshot) {
        console.log('Snapshot:: ', node, snapshot);
        dispatch({
          type: actionTypes.REFRESH_EVENT_LIST,
          payload: snapshot
        });        
    })
    .catch(function(err) {
        console.log('Error Fetching Event List',err);
    });
  };    
}

export function updateSelectedEventName(eventName) {
    console.log('Updating event name to ', eventName);
    return {
        type: actionTypes.UPDATE_EVENT_NAME,
        payload: eventName
    };       
}

export function saveSelectedEventName(orgId, eventId, eventName) {
    var node = `/tappedin/${orgId}/eventlist/${eventId}/name`;
    return function(dispatch) {    
        db.set(node, eventName)
        .then(function(snapshot) {
            console.log('Snapshot:: ', node, snapshot);
            dispatch({
            type: actionTypes.UPDATE_EVENT_NAME,
            payload: eventName
            });
        })
        .catch(function(err) {
            console.log('Error Fetching Event List',err);
        });    
    }
}

export function filterEventList(filterValue) {
    return {
        type: actionTypes.FILTER_EVENT_LIST,
        payload: filterValue
    };       
}


/**
 * Query for an Event based on OrgID and Event ID
 */
export function selectEvent(orgId,eventId) {  
  var self = this;   
  return function(dispatch) {
     dispatch({
       type: actionTypes.LOADING_EVENT
     });

      var node = `/tappedin/${orgId}/eventlist/${eventId}`;
      db.getValue(node)
      .then(function(result) {

        // We now have the Event Summary   
        console.log('Retrieved Event Summary from Server', new Date());

        // Dispatch a REFRESH_EVENT which will update the Event Details screen
        dispatch({
          type: actionTypes.REFRESH_EVENT,
          payload: result
        });

        //Subscriber to all things that I care about
        dispatch(subscribeToTicketStatusChanges(orgId,eventId));        
      })
      .catch(function(err) {
        console.log('Fetch Error',err);
        dispatch({
          type: actionTypes.EVENT_RETRIEVAL_ERROR,
          payload: err
        });  
      }); 
  };      
}

export function filterAttributeList(attributeName) {
    return {
        type: actionTypes.FILTER_ATTRIBUTE_LIST,
        payload: attributeName
    };
}

export function archiveEvent(organizationId, eventId, eventListFilter) {
    var self = this;   
    return function(dispatch) {
        dispatch({type: actionTypes.EVENT_ARCHIVE_REQUEST});
        db.userToken()
        .then(function(token) {
            sendArchiveRequest(token, organizationId, eventId)
            .then(function(result) {
                dispatch({
                    type: actionTypes.EVENT_ARCHIVE_COMPLETE,
                    payload: result
                });  
                // Refresh the Event List
                dispatch(refreshEventList(organizationId, eventListFilter));

            })
            .catch(function(err) {
                var errorMessage = (err) ? err.toString() : 'Archive Event Failed';
                dispatch({
                    type: actionTypes.EVENT_ARCHIVE_ERROR,
                    payload: errorMessage
                });            
            });
        })
        .catch(function(err) {
            var errorMessage = (err) ? err.toString() : 'Could not get User Authorization Token';
            dispatch({
                type: actionTypes.EVENT_ARCHIVE_ERROR,
                payload: err
            });            
        });                      
    };      
}

export function openArchiveDialog() {
    return {
        type: actionTypes.OPEN_ARCHIVE_DIALOG
    };
}

export function closeArchiveDialog() {
    return {
        type: actionTypes.CLOSE_ARCHIVE_DIALOG
    };
}


// tappedin/{orgId}/event/{eventId}/
// tappedin/{orgId}/event/{eventId}/ticket/{ticketId}/ticketStatus
function subscribeToTicketStatusChanges(organizationId,eventId) {
    
    console.log('Subscribing to Event Details', new Date());

    var node = `/tappedin/${organizationId}/event/${eventId}/tickets`;
    return function(dispatch) {
        //Subscribe to Child Added
        var batchTickets = {};
        var dispatchDebounced = debounce(function() { 
                console.log('Dispatching Ticket Batch Added',batchTickets);
                dispatch({
                    type: actionTypes.TICKET_BATCH_ADDED,
                    payload: batchTickets            
                });
                batchTickets = {};
        },100);

        db.subscriberToChildAdded(node,function(err,ticket) {
            if(err) {
                console.log('Error in fetching ticket added',err);              
            } else {
                dispatch({
                    type: actionTypes.EVENT_DATA_REFRESHING,
                    payload: true
                });

                batchTickets[ticket.id] = ticket;
                dispatchDebounced();
            }
        });

        // Subscribe to Child Changed
        db.subscribeToChildChanged(node,function(err,ticket) {
            if(err) {
                console.log('Error in fetching ticket changed',err);              
            } else {
                console.log('Dispatching a Ticket Change');
                dispatch({
                    type: actionTypes.TICKET_CHANGED,
                    payload: ticket
                });
            }
        });
    };    
}


function sendArchiveRequest(token, orgId, eventId) {
    return new Promise(function(resolve,reject) {
        let endpoint = `admin/organizations/${orgId}/events/${eventId}/archive`;
        let request_endpoint = `${config.tappedinApi.host}/${endpoint}`;
        request
        .post(request_endpoint)
        .set('Authorization','Bearer ' + token)   
        .end(function(err, res){            
            if(err) {
                if(res && res['body'] && res['body'].message) {
                    reject(res['body'].message);    
                } else {
                    reject(err);
                }                                
            } else {
                resolve(res.body);
            }
        });
    });
}


/**
 * Helper Functions : Should move these to a utils module
 */

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
};